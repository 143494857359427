<template>
  <div class="next-footer">
    <div class="footer fixed bg-white">
      <div class="container">
        <!-- <a href="your-gender.html" class="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl">Next</a> -->
        
        <a :href="next" class="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl">Next</a>
        
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NextFooter extends Vue {
  @Prop() private next!: string;
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
