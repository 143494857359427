<template>
  <div class="bg-white">
<div class="page-wrapper">
	<!-- Preloader -->
	<!-- <div id="preloader">
		<div class="loader">
			<div class="load-circle"><div></div><div></div></div>
		</div>
	</div> -->
    <!-- Preloader end-->
    <PreLoader></PreLoader>

    <!-- Page Content -->
    <div class="page-content">
		<div class="container">
			<div class="account-area">
        <BackButton></BackButton>
				<!-- <a href="javascript:void(0);" class="back-btn dz-flex-box">
					<i class="icon feather icon-chevron-left"></i>
				</a> -->
				<div class="section-head ps-0">
					<h3>What's your gender ?</h3>
				</div>
				<div class="radio style-2">
					<label class="radio-label">
						<input type="radio" v-model="gender" value="Female" name="radio2">
						<span class="checkmark">						
							<span class="text">Female</span>
							<span class="check"></span>							
						</span>
					</label>
					<label class="radio-label">
						<input type="radio" v-model="gender" value="Male" name="radio2">
						<span class="checkmark">
							<span class="text">Male</span>
							<span class="check"></span>							
						</span>
					</label>
					<label class="radio-label">
						<input type="radio" v-model="gender" value="Other" name="radio2">
						<span class="checkmark">
							<span class="text">Other</span>	
							<span class="check"></span>							
						</span>
					</label>
				</div>
			</div>
        </div>
    </div>
    <!-- Page Content End -->
    <ButtonFooter :onClick="handleNextClick" label="Next"></ButtonFooter>
  </div>
</div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';
import ButtonFooter from './ButtonFooter.vue';
import BackButton from './BackButton.vue';

@Component({
components: {
  ButtonFooter,
  PreLoader,
  BackButton
},
})
export default class Gender extends Vue {
  private gender: string = '';
  
  private handleNextClick() {

      

      this.$store.commit('setUserData', { gender: this.gender });
      this.$router.push('/dating-interest');
  }

  data() {
    const user_userData = this.$store.state.userData;
    return {
      gender : user_userData?.gender ?? 'Female'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
