<template>
  <div class="bg-white header-large">
    <div class="page-wrapper">
        
      <!-- Preloader -->
      <PreLoader></PreLoader>
        <!-- Preloader end-->
      <div v-if="loading">
        <PreLoader></PreLoader> 
      </div>
      <div v-else>
        <!-- Header -->
      
          <header class="header header-fixed bg-white">
            <div class="container">
              <div class="header-content">
                <div class="left-content me-3">
                  <BackButton></BackButton>
                  <!-- <a href="javascript:void(0);" class="back-btn"> -->
                  <!-- <a href="javascript:void(0);" class="back-btn" @click="routeChatlist" :class="'dz-flex-box'">
                    <i class="icon feather icon-chevron-left"></i>
                  </a> -->
                  <!-- <Button @click="routeChatlist" class="back-btn" :class="'dz-flex-box'" >
                    <i class="icon feather icon-chevron-left"></i>
                  </Button> -->
                  <!-- </a> -->
                </div>
                <div class="mid-content d-flex align-items-center text-start">
                  <a href="javascript:void(0);" class="media media-40 rounded-circle me-3">
                    <!-- <img src="assets/images/user/pic9.jpg" alt="/"> -->
                    <img :src="conversation_icon" alt="/">
                  </a>
                  <div>
                    <h6 class="title">{{conversation_name}}, {{ age }}</h6>
                    <span>{{elapsedTime}}</span>
                  </div>	
                </div>
                <!-- <div class="right-content d-flex align-items-center">
                  <a href="javascript:void(0);" class="dz-icon btn btn-primary light">
                    <i class="fa-solid fa-phone"></i>
                  </a>
                  <a href="javascript:void(0);" class="dz-icon me-0 btn btn-primary light">
                    <i class="fa-solid fa-video"></i>
                  </a>
                </div> -->
              </div>
            </div>
          </header>
        <!-- Header -->
        
        <!-- Page Content Start -->
        <div class="page-content space-top p-b60 message-content">
          <div class="container"> 
            <!-- <div class="chat-box-area"> 
              <div class="chat-content">
                <div class="message-item">
                  <div class="bubble">Hi Abel , thanks for adding me</div>    
                  <div class="message-time">08:35</div>    
                </div>
              </div>
              <div class="chat-content user">
                <div class="message-item">
                  <div class="bubble">Hi Mikaela , you're welcome , nice to meet you too</div>    
                  <div class="message-time">08:40</div>    
                </div>
              </div>
              <div class="chat-content">
                <div class="message-item">
                  <div class="bubble">I look you're singer, can you sing for me</div>    
                  <div class="message-time">9:44 AM</div>    
                </div>
              </div>
              <div class="chat-content user">
                <div class="message-item">
                  <div class="bubble">Sure</div>    
                  <div class="message-time">9.30 AM</div>    
                </div>
              </div>
              <div class="chat-content">
                <div class="message-item">
                  <div class="bubble">Really!</div>    
                  <div class="message-time">10:44 AM</div>    
                </div>
              </div>
              <div class="chat-content user">
                <div class="message-item">
                  <div class="bubble">Why not</div>    
                  <div class="message-time">10:44 AM</div>    
                </div>
              </div>
            </div>
            <hr> -->
            <div class="chat-box-area" ref="chatBox"> 
              <div v-for="(message, index) in chatMessagesSent" :key="index" :class="{ 'chat-content': true, 'user': message.sender_id === user_id }" style='padding-bottom:20px;'>
                <div class="message-item">
                  <div class="bubble">{{ message.content }}</div>    
                  <div class="message-time">{{ formatMessageTime(message.timestamp) }}</div>    
                </div>
              </div>
            </div>

          </div> 
        </div>
        <!-- Page Content End -->
        <footer class="footer border-top fixed bg-white">
            <div class="container p-2">
                <div class="chat-footer">
                    <form @submit.prevent="sendMessage">
                        <div class="form-group">
                            <div class="input-wrapper message-area">
                                <div class="append-media"></div>
                                <input v-model="messageInput" type="text" class="form-control" placeholder="Send message...">
                                <!-- <a href="javascript:void(0);" class="btn-chat">
                                    <i class="icon feather icon-send"></i>
                                </a> -->
                                <button type="submit" class="btn-chat">
                                  <i class="icon feather icon-send"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>    
            </div>
        </footer>
      </div>  
    </div>

  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import NextFooter from '@/components/NextFooter.vue';
import TitledHeader from '@/components/TitledHeader.vue';
import PreLoader from '@/components/PreLoader.vue';
import BackButton from '@/components/BackButton.vue';
import { getChatMessagesSent, getUserConversation } from '@/api';
import io from 'socket.io-client';


@Component({
components: {
  NextFooter,
  TitledHeader,
  PreLoader,
  BackButton
},
})
export default class Chat extends Vue {
  private userData: object = this.$store.state.userData;
  private user_id: number = 0;
  private chatMessagesSent: object[] = [];
  private conversation: object = {};
  private conversation_icon: string = '';
  private conversation_name: string = '';
  private conversation_slug: string = '';
  private message_time_elpased: string = '';
  private user_online_time_elapsed: string = '';
  private apiHost: string = '';
  private age: number = 0;
  private activeTimeThreshold: number = 10;
  private elapsedTime: string = 'Online 24m ago';
  private socketHost: string = '';
  private chatSocket: any = null;
  private messageInput: string = '';

  private loading: boolean = true;
  private currentPage: number = 1;
  private totalPages: number = 1;
  private socketConnectionString: string = '';
  private chatMate_id: number = 0;


  data(){
    return {
      conversation_slug: this.$route.params.slug,
      apiHost: process.env.VUE_APP_API_HOST,
      // socketHost: process.env.VUE_APP_SOCKET_HOST,
      socketHost: this.$store.state.chatSocketHost,
      socketConnectionString: process.env.VUE_APP_NEW_SOCKET_CONNECTION_STRING,
      chatSocket: null,
      messageInput: '',

      currentPage: 1,
      totalPages: 1,
      loading: true,
      chatMate_id: 0,
    }
  }

  mounted() {
    const slug = this.$route.params.slug;
    this.fetchChatMessagesSent(slug);
    this.fetchUserConversation(slug);
    this.conversation_slug = slug;
    this.user_id = parseInt((this.userData as any).id);
    
    this.initChatSocket().then(() => {    
      this.connectMessage();
    });
    // window.addEventListener('scroll', () => {
    //   this.handleScroll; 
    //   // Your code to handle scroll event
    // });
    // const chatBox = document.querySelector('.chat-box-area');
    this.$nextTick(() => {
      const chatBox = this.$refs.chatBox as HTMLElement;
      chatBox?.addEventListener('scroll', this.handleScroll.bind(this));

      // chatBox?.addEventListener('scroll', this.handleScroll);

      window.addEventListener('scroll',
        this.handleScroll
        // Your code to handle scroll event
      );

    });
    
  }

  initChatSocket() {
    return new Promise((resolve, reject) => {
      this.chatSocket = new WebSocket(this.socketHost);
      
      this.chatSocket.onopen = () => {
        console.log("[open] Connection established");
        resolve(undefined);
      }
      
      this.chatSocket.onmessage = (event: any) => {
      // console.log(`[message] Data received from server: ${event.data}`);
        console.log(`[message] Data received from server: ${event.data}`);
        const newMessageSent = JSON.parse(event.data);
        if (newMessageSent.data){
          this.chatMessagesSent.push(newMessageSent.data);
    
        }
        this.$nextTick(() => {
          window.scrollTo(0, document.body.scrollHeight);
        });

      };

      this.chatSocket.onerror = function(error: any) {
        console.log(`[error] ${JSON.stringify(error)}`);
        reject(error);
      };

      this.chatSocket.onclose = () => {
        console.log('WebSocket connection closed');

      };
    });
  }


  initChatSocket_orig() {

    // alert(1);
    // // Create a WebSocket client instance
    // this.chatSocket = io('ws://localhost:8080/');
    // alert(2);
    // // Connect to the WebSocket server
    // this.chatSocket.on('connect', (socket: any) => {
    //   socket.on('connection', (client: any) => {
    //     client.emit('Access-Control-Allow-Origin', this.socketHost);
    //   });
    //   alert(3);
    //   console.log('Connected to WebSocket server');
    // });
    // alert(4);

    // this.chatSocket = new WebSocket("wss://echo.websocket.org");
    this.chatSocket = new WebSocket("ws://127.0.0.1:8080/");

    // this.chatSocket.addEventListener('open', function () { 
    //   console.log("TEST SOCKET OPEN");
    // }); 

    // this.chatSocket.addEventListener('message', function (event: any) { 
    //   console.log('TEST Message from server ', event.data); 
    // });

    // this.chatSocket.addEventListener('close', function () { 
    //   console.log('TEST The connection has been closed'); 
    // });



    this.chatSocket.onopen = () => {
      console.log("[open] Connection established");

      
      // this.connectMessage();


      // this.chatSocket.send('*********** Hello, server!');
      // console.log(this.chatSocket.readyState);
      // console.log('awwws');
      // console.log(this.chatSocket);
      // setTimeout(() => {
      //   console.log('readyState: ', this.chatSocket.readyState);
      //   console.log('hahay');
      // }, 5000); // Wait for 1 second
      // console.log(`1readyState: ${this.chatSocket.readyState}`);


    };

    this.chatSocket.onmessage = (event: any) => {
      // console.log(`[message] Data received from server: ${event.data}`);
      console.log(`[message] Data received from server: ${event.data}`);
      const newMessageSent = JSON.parse(event.data);
      if (newMessageSent.data){
        this.chatMessagesSent.push(newMessageSent.data);
        console.log(this.chatMessagesSent);
  
      }
      this.$nextTick(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });

    };

    this.chatSocket.onerror = function(error: any) {
      console.log(`[error] ${JSON.stringify(error)}`);
    };

    this.chatSocket.onclose = () => {
      console.log('WebSocket connection closed');
      console.log('readyState: ', this.chatSocket.readyState);

    };

    console.log('wew');
    console.log(this.chatSocket);
    console.log(this.chatSocket.readyState);

  }

  async fetchUserConversation(slug: string) {
    getUserConversation(slug)
      .then(response => {
        
        let apiHost = `${this.apiHost}/api/get-image?location=`;

        this.conversation = response.data.items[0];
        // alert(JSON.stringify(this.conversation));
        const chatMate = ((this.conversation as any).users).find(
          (user:any) => user.id != (this.userData as any).id
        );
        this.chatMate_id = chatMate.id;
        this.conversation_icon = (this.conversation as any).conversation_icon ? `${apiHost}${(this.conversation as any).conversation_icon}` : `${apiHost}${(chatMate).userProfile.profile_pic}`;
        this.conversation_name = (this.conversation as any).title != 'Conversation Title' ? (this.conversation as any).title : chatMate.userProfile.name;
        this.age = this.calculateAge(chatMate.userProfile.birthdate);
        if (this.isConversationActive((this.conversation as any).users)){
          this.elapsedTime = '';
        }
        else {
          console.log('inactive');
          this.elapsedTime = 'Online ' + this.getConversationMessageTimeElapsed(chatMate.last_online);
        }

        this.loading = false;

      })
      .catch(error => {
        console.error('Error fetching user conversation: ', error);
      })
  }

  fetchChatMessagesSent(slug: string) {
    getChatMessagesSent(slug)
      .then(response => {
        this.chatMessagesSent = response.data.items;
        this.chatMessagesSent.sort((a: any, b: any) => {
          const dateA = new Date(a.timestamp);
          const dateB = new Date(b.timestamp);

          return (dateA as any) - (dateB as any);
        })

        this.totalPages = response.data._meta.totalCount;
        this.currentPage = response.data._meta.currentPage;
        
        window.scrollTo(0, document.body.scrollHeight);
      })
      .finally(() => {
        window.scrollTo(0, document.body.scrollHeight);
      })
      .catch(error => {
        console.error('Error fetching chat messages: ', error);
      })
  }

  calculateAge(birthdate: string) {
    const today = new Date();
      const birthDate = new Date(birthdate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }


      return age; 
  }

  isConversationActive(users: object[]) {
    const currentTime = new Date();
    for (let i = 0; i < users.length; i++) {
      if (users[i] != (this.userData as any).id){
        const lastOnline = new Date((users[i] as any).last_online);
        const timeDifference = ((currentTime as any)  - (lastOnline as any)) / (1000 * 60); // Difference in minutes
        if (timeDifference <= this.activeTimeThreshold) {
          return true;
        }
      }
    }
    return false;
  }

  getConversationMessageTimeElapsed(timestamp?: Date) {
    const currentTime : any = new Date();
    const messageTime : any = new Date();
    if(timestamp){
      const messageTime : any = new Date(timestamp);
    }
    const timeDifference = (currentTime - messageTime) / 1000; // Difference in seconds

    if (timeDifference < 60) {
      return 'just now';
    } else if (timeDifference < 3600) {
      const minutes = Math.floor(timeDifference / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (timeDifference < 86400) {
      const hours = Math.floor(timeDifference / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (timeDifference < 86400) {
      const hours = Math.floor(timeDifference / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (timeDifference < 2592000) {
      const days = Math.floor(timeDifference / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (timeDifference < 31536000) {
      const months = Math.floor(timeDifference / 2592000);
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else {
      const years = Math.floor(timeDifference / 31536000);
      return `${years} year${years > 1 ? 's' : ''} ago`;
    }
  }

  formatMessageTime(timestamp: Date ) {
    const currentTime : any = new Date();
    const messageTime : any = new Date(timestamp);
    const diffTime = currentTime - messageTime;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const options = { hour: 'numeric', minute: 'numeric' };
    
    if (diffDays === 0) {
      return messageTime.toLocaleTimeString('en-US', options);
    } else if (diffDays < 7) {
      return messageTime.toLocaleString('en-US', { weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true });
    } else {
      return messageTime.toLocaleString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
    }
  }

  connectMessage() {
    const socketRequestData : any = {
      event: "event_request",
      socket_type: "update_chat_messages",
      data: {
        conversation_slug: this.conversation_slug,
        sender_id: this.user_id,
        content: this.socketConnectionString
      }
    };

    this.chatSocket.send(JSON.stringify(socketRequestData));
  }

  sendUpdateChatListRequest() {
    const socketRequestData : any = {
      event: "event_request",
      socket_type: "update_conversation_list",
      data: {
        user_id: this.chatMate_id
      }
    };
    this.chatSocket.send(JSON.stringify(socketRequestData));
  }

  sendMessage() {
    if (this.messageInput.trim() !== '') {
        // Send message through WebSocket
        // alert(this.messageInput);
        const socketRequestData : any = {
          event: "event_request",
          socket_type: "update_chat_messages",
          data: {
            conversation_slug: this.conversation_slug,
            sender_id: this.user_id,
            content: this.messageInput
          }
        };
        this.chatSocket.send(JSON.stringify(socketRequestData));

        this.sendUpdateChatListRequest();

        var chatMessageHtml = '<div class="chat-content user">' +
          '<div class="message-item">' +
          '<div class="bubble">' + this.messageInput + '</div>' +
          '<div class="message-time">' + this.formatMessageTime(new Date()) + '</div>' +
          '</div>' +
          '</div>';

        // Append message to chat box area
        $('.chat-box-area').append(chatMessageHtml);

        // Clear input field and emoji area
        this.messageInput = '';
        $('.append-media').empty();

        // Scroll to bottom of chat
        window.scrollTo(0, document.body.scrollHeight);


        // Clear message input field after sending
        this.messageInput = '';
        
      }
  }

  fetchNextPage() {
    console.log(`loading: ${this.loading}`);
    console.log(`currentPage: ${this.currentPage}`);
    console.log(`totalPages: ${this.totalPages}`);
      // Prevent multiple simultaneous requests

    let nextChatMessagesSent : object[] = [];
    if (this.loading || this.currentPage >= this.totalPages) return;
    
    this.loading = true;
    getChatMessagesSent(this.conversation_slug, this.currentPage + 1)
      .then(response => {
        nextChatMessagesSent = response.data.items;
        
        let meta = response.data._meta;

        nextChatMessagesSent.sort((a: any, b: any) => {
          const dateA = new Date(a.timestamp);
          const dateB = new Date(b.timestamp);

          return (dateA as any) - (dateB as any);
        })

        this.chatMessagesSent.unshift(...nextChatMessagesSent);

        // Update current page and total pages
        this.currentPage = meta.currentPage;
        this.totalPages = meta.pageCount;

      })
      .catch(error => {
        console.error('Error fetching chat messages: ', error);
      })
      .finally(() => {
        this.loading = false;
      })


    // // Make API request to fetch the next page of messages
    // // Replace 'your-api-endpoint' with your actual API endpoint
    // fetch(`your-api-endpoint&page=${this.currentPage + 1}`)
    //   .then(response => response.json())
    //   .then(data => {
    //     // Append the new messages to the existing messages
    //     this.chatMessagesSent.unshift(...data.items);

    //     // Update current page and total pages
    //     this.currentPage = data._meta.currentPage;
    //     this.totalPages = data._meta.pageCount;
    //   })
    //   .catch(error => {
    //     console.error('Error fetching next page:', error);
    //   })
    //   .finally(() => {
    //     this.loading = false;
    //   });
  }
  
  handleScroll(event: any) {
    // alert('scroll');
    const chatBox = event.currentTarget;
    // alert(JSON.stringify(event));
    // if (chatBox.scrollTop <= 10) {
    if(window.scrollY <= 0){
      // User has scrolled to the top, fetch the next page
      this.fetchNextPage();
    }
  }

  beforeDestroy() {
    // Clean up WebSocket connection before component is destroyed
    if (this.chatSocket) {
      console.log('chat socket close');
      this.chatSocket.close();
    }
  }

  private routeChatlist()
  {
    // alert(event);
     // Prevent default action and stop propagation if an event is passed
    // if (event) {
    //     event.preventDefault();
    //     event.stopPropagation();
    // }

    // this.$router.push('/chatlist');
    // Navigate to '/chatlist'
    this.$router.push('/chatlist');
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
