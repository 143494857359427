<template>
  <div class="phonenumber">
    <PhoneNumber></PhoneNumber>
  </div>
</template>


<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import PhoneNumber from '@/components/PhoneNumber.vue';

@Component({
components: {
  PhoneNumber,
},
})
export default class PhoneNumberView extends Vue {
  
}
</script>
