<template>
  <div class="error">
    <Error></Error>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Error from '@/components/Error.vue';

@Component({
components: {
  Error,
},
})
export default class ErrorView extends Vue {}
</script>
