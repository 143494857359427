<template>
  <div class="sidebar">
    	<!-- Sidebar -->
      <div class="dark-overlay" :class="{ active: isVisible }" @click="toggleVisibility"></div>
	<div class="sidebar" :class="{ show: isVisible }">
		<div class="inner-sidebar">
			<a :href="'/profile'" class="author-box">
				<div class="dz-media">
					<!-- <img src="/assets/images/user/pic1.jpg" alt="author-image"> -->
          <img :src="userData.userProfile && userData.userProfile.profile_pic ?  apiHost + '/api/get-image?location=' + userData.userProfile.profile_pic : '/assets/images/user/def-pics/pic1.jpg'" alt="author-image">
				</div>
				<div class="dz-info">
					<h5 class="name">{{userData.userProfile ? userData.userProfile.name : 'user'}}</h5>
					<span>{{ userData ? userData.email : 'user@email.com' }}</span>
				</div>
			</a>
			<ul class="nav navbar-nav">	
				<li>
					<a class="nav-link active" :href="'/feed'">
						<span class="dz-icon">
							<i class="icon feather icon-home"></i>
						</span>
						<span>Home</span>
					</a>
				</li>
				<!-- <li>
					<a class="nav-link" href="../package.html">
						<span class="dz-icon">
							<i class="icon feather icon-heart"></i>
						</span>
						<span>W3Dating Package</span>
					</a>
				</li> -->
				<!-- <li>
					<a class="nav-link" href="../package-list.html">
						<span class="dz-icon">
							<i class="icon feather icon-list"></i>
						</span>
						<span>Package List</span>
					</a>
				</li> -->
				<li>
					<a class="nav-link" :href="'/'">
						<span class="dz-icon">
							<i class="icon feather icon-wind"></i>
						</span>
						<span>Welcome</span>
					</a>
				</li>
				<!-- <li>
					<a class="nav-link" href="../components/components.html">
						<span class="dz-icon">
							<i class="icon feather icon-grid"></i>
						</span>
						<span>Components</span>
					</a>
				</li> -->
				<!-- <li>
					<a class="nav-link" :href="'/settings'">
						<span class="dz-icon">
							<i class="icon feather icon-settings"></i>
						</span>
						<span>Settings</span>
					</a>
				</li> -->
				<li>
					<a class="nav-link" :href="'/profile'">
						<span class="dz-icon">
							<i class="icon feather icon-user"></i>
						</span>
						<span>Profile</span>
					</a>
				</li>
				<li>
					<!-- <a class="nav-link" href="onboarding.html"> -->
          <a class="nav-link" @click="logout">
						<span class="dz-icon">
							<i class="icon feather icon-log-out"></i>
						</span>
						<span>Logout</span>
					</a>
				</li>
			</ul>
			<div class="sidebar-bottom">
				<ul class="app-setting">
					<li>
						<div class="mode">
							<span class="dz-icon">                        
								<i class="icon feather icon-moon"></i>
							</span>					
							<span>Dark Mode</span>
							<div class="custom-switch">
								<input type="checkbox" class="switch-input theme-btn" ref="toggleDarkMenu" id="toggle-dark-menu" @click="toggleDarkMode">
								<label class="custom-switch-label" for="toggle-dark-menu"></label>
							</div>					
						</div>
					</li>
				</ul>
				<div class="app-info">
					<h6 class="name">Shay Buna - Dating App</h6>
					<!-- <span class="ver-info">App Version 1.0</span> -->
				</div>
			</div>
		</div>
	</div>
    <!-- Sidebar End -->
    
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Sidebar extends Vue {
  @Prop() private var!: string;
  private userData: object = this.$store.state.userData;
  private isVisible: boolean = false;
  private darkMode: boolean = false;

  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      isVisible: false,
      darkMode: false
    }
  }

  toggleDarkMode() {
    // Toggle the 'theme-dark' class on the body
    document.body.classList.toggle('theme-dark');
    // Toggle the 'active' class on the button
    (this.$refs.toggleDarkMenu as any).classList.toggle('active');
    // Get the current logo source
    // let logoSrc = (this.$refs.appLogo as any).src;
    // Update the logo source based on the theme
    if (document.body.classList.contains('theme-dark')) {
      this.setCookie('themeVersion_value', 'theme-dark');
      // (this.$refs.appLogo as any).src = logoSrc.replace('light', 'dark');
    } else {
      this.setCookie('themeVersion_value', '');
      // (this.$refs.appLogo as any).src = logoSrc.replace('dark', 'light');
    }
  }

  setCookie(name: string, value: string) {
    // Set expiry date to 1 year from now
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);
    
    // Format the cookie string
    const cookieString = `${name}=${value};expires=${expiryDate.toUTCString()};path=/`;

    // Set the cookie
    document.cookie = cookieString;
  }

  toggleVisibility() {
      this.isVisible = !this.isVisible;
    }
  
  logout() {
    // Call the Vuex action to clear user data
    // this.$store.dispatch('clearUserData');
    // alert('logout');
    this.$store.commit('clearState');

    // Redirect to /onboarding
    this.$router.push('/onboarding');
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
