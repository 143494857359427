<template>
  <div class="header-large">
<div class="page-wrapper">
    
	<!-- Preloader -->
	<PreLoader></PreLoader>
  <div v-if="isLoading" class="spinner-overlay">
    
    <div class="preloader">
      <!-- Preloader -->
        <div id="preloader" ref="preloader">
          <div class="loader">
            <div class="load-circle"><div></div><div></div></div>
          </div>
        </div>
      <!-- Preloader end-->
    </div>

  </div>
    <!-- Preloader end-->
	
	<!-- Header -->
		<header class="header header-fixed">
			<div class="container">
				<div class="header-content">
					<div class="left-content">
            <BackButton></BackButton>
						<!-- <a href="javascript:void(0);" class="back-btn">
							<i class="icon feather icon-chevron-left"></i>
						</a> -->
						<h6 class="title">Super Liked you</h6>
					</div>
					<div class="mid-content header-logo">
					</div>
					<div class="right-content dz-meta">
						<a href="/filters" class="filter-icon">
							<i class="flaticon flaticon-settings-sliders"></i>
						</a>
					</div>
				</div>
			</div>
		</header>
	<!-- Header -->
	
	<!-- Page Content Start -->
	<!-- <div class="page-content space-top p-b65">
		<div class="container">
			<div class="row g-2">
				<div class="col-6">
					<div class="dz-media-card">
						<a href="/profile-detail">
							<div class="dz-media">
								<img src="assets/images/liked/pic1.png" alt="">
							</div>
							<div class="dz-content">
								<h6 class="title">Aida</h6>	
								<span class="about">Harvard University</span>	
							</div>
						</a>
					</div>
				</div>
				<div class="col-6">
					<div class="dz-media-card">
						<a href="/profile-detail">
							<div class="dz-media">
								<img src="assets/images/liked/pic2.png" alt="">
							</div>
							<div class="dz-content">
								<h6 class="title">Almaz</h6>	
								<span class="about">Chapman University</span>	
							</div>
						</a>
					</div>
				</div>
				<div class="col-6">
					<div class="dz-media-card">
						<a href="/profile-detail">
							<div class="dz-media">
								<img src="assets/images/liked/pic3.png" alt="">
							</div>
							<div class="dz-content">
								<h6 class="title">Birtukan</h6>	
								<span class="about">Law student at Stanford</span>	
							</div>
						</a>
					</div>
				</div>
				<div class="col-6">
					<div class="dz-media-card">
						<a href="/profile-detail">
							<div class="dz-media">
								<img src="assets/images/liked/pic4.png" alt="">
							</div>
							<div class="dz-content">
								<h6 class="title">Chaltu</h6>	
								<span class="about">Harvard University</span>	
							</div>
						</a>
					</div>
				</div>
				<div class="col-6">
					<div class="dz-media-card">
						<a href="/profile-detail">
							<div class="dz-media">
								<img src="assets/images/liked/pic5.png" alt="">
							</div>
							<div class="dz-content">
								<h6 class="title">Richard</h6>	
								<span class="about">Law student at Stanford</span>	
							</div>
						</a>
					</div>
				</div>
				<div class="col-6">
					<div class="dz-media-card">
						<a href="/profile-detail">
							<div class="dz-media">
								<img src="assets/images/liked/pic6.png" alt="">
							</div>
							<div class="dz-content">
								<h6 class="title">Eden</h6>	
								<span class="about">Harvard University</span>	
							</div>
						</a>
					</div>
				</div>
				<div class="col-6">
					<div class="dz-media-card">
						<a href="/profile-detail">
							<div class="dz-media">
								<img src="assets/images/liked/pic7.png" alt="">
							</div>
							<div class="dz-content">
								<h6 class="title">Fana</h6>	
								<span class="about">Chapman University</span>	
							</div>
						</a>
					</div>
				</div>
				<div class="col-6">
					<div class="dz-media-card">
						<a href="/profile-detail">
							<div class="dz-media">
								<img src="assets/images/liked/pic8.png" alt="">
							</div>
							<div class="dz-content">
								<h6 class="title">Gelila</h6>	
								<span class="about">Harvard University</span>	
							</div>
						</a>
					</div>
				</div>
				<div class="col-6">
					<div class="dz-media-card">
						<a href="/profile-detail">
							<div class="dz-media">
								<img src="assets/images/liked/pic9.png" alt="">
							</div>
							<div class="dz-content">
								<h6 class="title">Veronica</h6>	
								<span class="about">Harvard University</span>	
							</div>
						</a>
					</div>
				</div>
				<div class="col-6">
					<div class="dz-media-card">
						<a href="/profile-detail">
							<div class="dz-media">
								<img src="assets/images/liked/pic10.png" alt="">
							</div>
							<div class="dz-content">
								<h6 class="title">Hana</h6>	
								<span class="about">Law student at Stanford</span>	
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div> -->
  <div class="page-content space-top p-b65">
		<div class="container">
			<div v-if="superLikersList.length > 0" class="row g-2">
				<div class="col-6" v-for="liker in superLikersList" :key="liker.swiper_user_id">


          <div v-if="isLoading" class="spinner-overlay">
                
            <div class="preloader">
              <!-- Preloader -->
                <div id="preloader" ref="preloader">
                  <div class="loader">
                    <div class="load-circle"><div></div><div></div></div>
                  </div>
                </div>
              <!-- Preloader end-->
            </div>

          </div>


					<div class="dz-media-card">
						<a :href="`/profile-detail/${liker.swiper_user_id}`">
							<div class="dz-media">
								<img :src="getProfilePic(liker)" alt=""  @load="imageLoaded" @error="imageLoaded">
							</div>
							<div class="dz-content">
								<h6 class="title">{{ liker.swiperUser.userProfile.name }}</h6>	
								<!-- <span class="about">Harvard University</span>	 -->
							</div>
						</a>
					</div>
				</div>
			</div>
      
      <div v-else>
        <span> Stay Tuned! No Super Likes Yet, but Someone Special Might Be Just Around the Corner!  </span>
      </div>
		</div>
	</div>
	<!-- Page Content End -->
	<!-- Menubar -->
	<MenuBarFooter></MenuBarFooter>
	<!-- Menubar -->
</div>  
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import MenuBarFooter from '@/components/MenuBarFooter.vue';
import PreLoader from '@/components/PreLoader.vue';
import BackButton from '@/components/BackButton.vue';
import { getSuperLikers } from '@/api';

@Component({
components: {
  MenuBarFooter,
  PreLoader,
  BackButton
},
})
export default class WishList extends Vue {
  private user_id: number = 0;
  private superLikersList: object [] = [];
  private apiHost: string = '';

  private imagesLoaded: number = 0;
  private totalImages: number = 0;
  private isLoading: boolean = true;

  imageLoaded() {
    // Check if all images are loaded
    // const allImagesLoaded = this.userList.every(profile => (profile as any).userProfile.profile_pic);
    // if (allImagesLoaded) {
    //   this.loading = false; // Set loading state to false when all images are loaded
    // }

    this.imagesLoaded += 1;
    console.log(`Total: ${this.totalImages} and images loaded: ${this.imagesLoaded}`);
    // alert(`images loaded: ${this.imagesLoaded} >= totalImages: ${this.totalImages}`);
    if (this.imagesLoaded >= this.totalImages) {
      this.isLoading = false;
    }
  }


  data() {
    const user_userData : any | undefined = this.$store.state.userData;

    return {
      user_id: user_userData?.id,
      apiHost: process.env.VUE_APP_API_HOST
    }
  }

  mounted() {
    this.fetchSuperLikers();
  }


  fetchSuperLikers() {
    getSuperLikers(this.user_id)
      .then(response => {
        this.superLikersList = response.data.items;
        this.totalImages = this.superLikersList.length;

        if (this.superLikersList.length < 1) {
          this.isLoading = false;
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  getProfilePic(liker: object) {
    const superLikerProfile = (liker as any).swiperUser.userProfile;
    return superLikerProfile && superLikerProfile.profile_pic 
      ?  this.apiHost + '/api/get-image?location=' + superLikerProfile.profile_pic 
      : '/assets/images/slider/def-pics/pic1.png';
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
