<template>
  <div class="dating-interest">
    <DatingInterest></DatingInterest>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import DatingInterest from '@/components/DatingInterest.vue';

@Component({
components: {
  DatingInterest,
},
})
export default class DatingInterestView extends Vue {}
</script>
