<template>
  <div class="bg-white">
<div class="page-wrapper">
	<!-- Preloader -->
	<!-- <div id="preloader">
		<div class="loader">
			<div class="load-circle"><div></div><div></div></div>
		</div>
	</div> -->
    <!-- Preloader end-->
    <Loader v-if="loading"></Loader>
    <div v-else>
      
    <PreLoader></PreLoader>

    <!-- Page Content -->
    <div class="page-content">
		<div class="container">
			<div class="account-area">
        <BackButton></BackButton>
				<!-- <a href="javascript:void(0);" class="back-btn dz-flex-box">
					<i class="icon feather icon-chevron-left"></i>
				</a> -->
				<div class="section-head ps-0">
					<h3>What are you looking for right now ?</h3>
				</div>
				<div class="radio style-2">
					<!-- <label class="radio-label">
						<input type="radio" checked="checked" v-model="relationshipGoal" value="1" name="radio2">
						<span class="checkmark">						
							<span class="text">Long-term partner</span>
							<span class="check"></span>							
						</span>
					</label>
					<label class="radio-label">
						<input type="radio" v-model="relationshipGoal" value="2" name="radio2">
						<span class="checkmark">
							<span class="text">Long-term, open to short</span>
							<span class="check"></span>							
						</span>
					</label>
					<label class="radio-label">
						<input type="radio" v-model="relationshipGoal" value="3" name="radio2">
						<span class="checkmark">
							<span class="text">Short-term, open to long</span>	
							<span class="check"></span>							
						</span>
					</label>
					<label class="radio-label">
						<input type="radio" v-model="relationshipGoal" value="4" name="radio2">
						<span class="checkmark">
							<span class="text">Short-term fun</span>	
							<span class="check"></span>							
						</span>
					</label>
					<label class="radio-label">
						<input type="radio" v-model="relationshipGoal" value="5" name="radio2">
						<span class="checkmark">
							<span class="text">New friends</span>	
							<span class="check"></span>							
						</span>
					</label>
					<label class="radio-label">
						<input type="radio" v-model="relationshipGoal" value="6" name="radio2">
						<span class="checkmark">
							<span class="text">Still figuring it out</span>	
							<span class="check"></span>							
						</span>
					</label> -->
          <!-- <div v-if="loading"> -->
            <!-- <PreLoader></PreLoader> -->
          <!-- </div> -->
          <!-- <div v-if="!loading"> -->
            <label class="radio-label" v-for="goal in relationshipGoals" :key="goal.id">
              <input type="radio" v-model="relationshipGoal" :value="goal.id" :name="radio2">
              <span class="checkmark">
                <span class="text">{{ goal.name }}</span>
                <span class="check"></span>
              </span>
            </label>
          <!-- </div> -->
				</div>	
			</div>
        </div>
    </div>
    <!-- Page Content End -->

    <ButtonFooter :onClick="userProfilePost" label="Next"></ButtonFooter>
    
    </div>
  </div>
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import axios from 'axios';
import PreLoader from './PreLoader.vue';
import ButtonFooter from './ButtonFooter.vue';
import Loader from './Loader.vue';
import BackButton from './BackButton.vue';

@Component({
components: {
  ButtonFooter,
  PreLoader,
  Loader,
  BackButton
},
})
export default class RelationshipGoal extends Vue {
  private relationshipGoal: string = '';
  private relationshipGoals: object[] = [];
  private loading: boolean = true;
  data() {
    return {
      relationshipGoal: '1', // This will hold the selected value
      relationshipGoals: [],
      radio2: 'relationshipGoalsGroup',
      loading: true,
    }
  }


  mounted() {
    this.fetchRelationshipGoals();
  }

  fetchRelationshipGoals() {
      // Make an Axios GET request to your API endpoint
      this.loading=true;

      axios.get(process.env.VUE_APP_API_HOST + '/relationship-goals')
        .then(response => {
          // Update the 'relationshipGoals' array with the response data
          this.$store.commit('setRelationshipGoals', response.data.items)
          this.relationshipGoals = response.data.items;
          console.log(JSON.stringify(response.data));
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  
  private userProfilePost() {

      this.loading=true;
      this.$store.commit('setUserData', { relationship_goals_id: this.relationshipGoal });
      const userProfileData = this.$store.state.userData;

      axios.post(process.env.VUE_APP_API_HOST + '/users', userProfileData)
        .then(response => {
          // Handle the success response
          console.log(response.data);
          if (response.data) {
            this.$store.commit('setUserData', response.data)

            console.log(JSON.stringify(response.data));
          } 

          this.$router.push('/add-pics');
        })
        .catch(error => {
          // Handle the error
          console.error(error);
        });

      // this.$router.push('/add-pics');
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
