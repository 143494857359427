<template>
  <div class="chat">
    <Chat></Chat>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Chat from '@/components/Chat.vue';

@Component({
components: {
  Chat,
},
})
export default class ChatView extends Vue {}
</script>
