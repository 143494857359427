<template>
  <div class="wishlist">
    <WishList></WishList>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import WishList from '@/components/WishList.vue'; // @ is an alias to /src

@Component({
  components: {
    WishList,
  },
})
export default class WishListView extends Vue {}
</script>
