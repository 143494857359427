<template>
  <div class="back-button">
    <a href="javascript:void(0);" @click="handleGoBack" class="back-btn " :class=" 'dz-flex-box'" >
      <i class="icon feather icon-chevron-left"></i>
    </a>
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

export default class BackButton extends Vue {
  @Prop() private title!: string;

  data() {
    return {
      title: 'awts'
    }
  }
  
  handleGoBack() {
    window.history.go(-1);
    return false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
