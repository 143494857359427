<template>
  <div class="bg-white">
<div class="page-wrapper">
	<!-- Preloader -->
	<!-- <div id="preloader">
		<div class="loader">
			<div class="load-circle"><div></div><div></div></div>
		</div>
	</div> -->
    <!-- Preloader end-->
    <PreLoader></PreLoader>

    <!-- Page Content -->
    <div class="page-content p-b70">
      <div class="container">
        <div class="account-area">
          <BackButton></BackButton>
          <!-- <a href="javascript:void(0);" class="back-btn dz-flex-box">
            <i class="icon feather icon-chevron-left"></i>
          </a> -->
          <div class="section-head ps-0">
            <h3>Profile Photo</h3>
          </div>

          <div class="col-4">
            <div class="dz-drop-box">
              <img src="/assets/images/recent-pic/drop-bx.png" alt="">
              <div class="drop-bx">
                <div class="imagePreview" :style="{ backgroundImage: 'url(' + uploads[0].imageUrl + ')' }">
                  <div @click="removeImage(0)" class="remove-img   remove-btn" :class="{ 'd-none': !uploads[0].showRemove }">
                    <i class="icon feather icon-x"></i>
                  </div>
                  <input type='file' class="form-control d-none imageUpload" @change="handleImageUpload($event, 0)" ref="imageUpload0" :id="'imageUpload0'" accept=".png, .jpg, .jpeg, .jfif">
                  <label for="imageUpload0"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Content End -->

    <ButtonFooter :onClick="submitForm" label="Next"></ButtonFooter>

  </div>
</div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';
import ButtonFooter from './ButtonFooter.vue';
import axios from 'axios';
import BackButton from './BackButton.vue';

interface Upload {
  imageUrl: string;
  showRemove: boolean;
}

@Component({
components: {
  ButtonFooter,
  PreLoader,
  BackButton
},
})
export default class Pics extends Vue {
  private pictures: any [] = [];
  private user_id: string = '';

  private imageUrl: string = '';
  private showRemoveButton: boolean = false;
  private uploads: Upload[] = [];

  data() {
    return {
      pictures: [],
      user_id: this.$store.state.userData.id, // Replace with your user_id

      imageUrl: '/assets/images/recent-pic/drop-bx.png',
      showRemoveButton: false,
      uploads: [
        { imageUrl: '/assets/images/recent-pic/drop-bx.png', showRemove: false } as Upload,
      ]
    };
  }

  handleFileChange(event: Event) {
    const filess = event.target as any;
    const filesz = filess.files;
    // alert(JSON.stringify(filesz));



    const fileInput = this.$refs.picUpload as any | undefined;
    // Access the selected files from the input
    const files = fileInput?.files;
    if (files)
      // alert(files.length);
      // Now 'files' is a FileList, you can iterate through it
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // alert(file.name); // Example: Log the name of each file
      }
  }

  handleImageUpload(event : any, index: number) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];
    const reader = new FileReader();
    const upload = this.uploads[index];
    const fileInput = this.$refs['imageUpload' + index] as any | undefined;

    if (file) {
      upload.showRemove = true;
      reader.onload = function(e) {
        upload.imageUrl = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    } else {
      upload.showRemove = false;
      upload.imageUrl = '/assets/images/recent-pic/drop-bx.png';
    }

    if(fileInput){
      const file = fileInput?.files ? fileInput.files[0] : null;
      if (file) {
        const caption = `Caption ${index}`;
        this.pictures.push({ file, caption });
      }
    }
  }

  removeImage(index: number) {
    const upload = this.uploads[index];
    upload.showRemove = false;
    upload.imageUrl = '/assets/images/recent-pic/drop-bx.png';
    const input = this.$refs['imageUpload' + index];
    (input as any).value = ''; // Clear the file input

  }

  handleImageUpload1(index: number) {
    // alert(index);
    const fileInput = this.$refs['imageUpload' + index] as any | undefined;
    if(fileInput){
      // alert('fileINpit');
      // alert(JSON.stringify(fileInput));
      const file = fileInput?.files ? fileInput.files[0] : null;

      // alert(file.name);
      
      if (file) {
        // const reader = new FileReader();
        // reader.onload = (e) => {
        //   // alert('naay file');
        //   this.imageUrl = e.target?.result as string;
        //   this.showRemoveButton = true;

        //   // alert(this.imageUrl);

        //   // Add the file details to your data or perform any other actions
        //   // For example, you can emit an event to parent component or directly manipulate the data
        //   this.$emit('file-uploaded', { file, caption: `Caption ${index}` });
        // };
        // reader.readAsDataURL(file);


        const caption = `Caption ${index}`;
        
        // Add the file details to the pictures array
        this.pictures.push({ file, caption });

        // alert(JSON.stringify((this.pictures)));
      }
    }

  }

  private submitForm() {

    // alert(this.user_id);

    // Prepare the payload
    // const payload = {
    //   user_id: this.user_id,
    //   pictures: this.pictures,
    // };
    const payload = new FormData();

    payload.append('user_id', this.user_id);

    // Iterate through each picture and append its file to FormData
    this.pictures.forEach((picture, index) => {
      if (picture.file) {
        payload.append(`pictures[${index}][file]`, picture.file);
        payload.append(`pictures[${index}][caption]`, picture.caption);
      }
    });


    // alert(JSON.stringify((payload.pictures[0].file.name)));

    // alert(JSON.stringify(payload))
    // alert(JSON.stringify(payload));

    // Make the POST request using Axios
    axios.post(process.env.VUE_APP_API_HOST + '/api/profile-pic', payload)
      .then(response => {
        // Handle the response
        console.log(response.data);
        if (response.data) {
          this.$store.commit('setUserProfile', response.data);
        }
        this.$router.push('/feed');
      })
      .catch(error => {
        // Handle errors
        console.error('Error uploading images:', error);
      });
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
