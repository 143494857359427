<template>
  <div class="explore">
    <Explore></Explore>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Explore from '@/components/Explore.vue';

@Component({
components: {
  Explore,
},
})
export default class ExploreView extends Vue {}
</script>
