<template>
  <div class="page-wrapper">
    
    <!-- Preloader -->
    <PreLoader></PreLoader>
    <div v-if="is_loading" class="spinner-overlay">
                  
      <div class="preloader">
        <!-- Preloader -->
          <div id="preloader" ref="preloader">
            <div class="loader">
              <div class="load-circle"><div></div><div></div></div>
            </div>
          </div>
        <!-- Preloader end-->
      </div>

    </div>
      <!-- Preloader end-->
    
    <!-- Header -->
      <TitledHeader title="Edit Profile"></TitledHeader>
    <!-- Header -->
    
    <!-- Page Content Start -->
    <div class="page-content space-top">
      <div class="container"> 
        <!-- <div class="row g-3 mb-3"  data-masonry='{"percentPosition": true }'>
          <div class="col-8">
            <div class="dz-drop-box style-2">
              <div class="drop-bx bx-lg">
                <div class="imagePreview" style="background-image: url(assets/images/recent-pic/drop-bx.png);">
                  <div  class="remove-img remove-btn d-none"><i class="icon feather icon-x"></i></div>
                  <input type='file' class="form-control d-none imageUpload"  id="imageUpload" accept=".png, .jpg, .jpeg">
                  <label for="imageUpload"></label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row g-3">
              <div class="col-12">
                <div class="dz-drop-box style-2">
                  <img src="assets/images/recent-pic/drop-bx.png" alt="">
                  <div class="drop-bx">
                    <div class="imagePreview" style="background-image: url(assets/images/recent-pic/drop-bx.png);">
                      <div  class="remove-img remove-btn d-none"><i class="icon feather icon-x"></i></div>
                      <input type='file' class="form-control d-none imageUpload"  id="imageUpload2" accept=".png, .jpg, .jpeg">
                      <label for="imageUpload2"></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="dz-drop-box style-2">
                  <img src="assets/images/recent-pic/drop-bx.png" alt="">
                  <div class="drop-bx">
                    <div class="imagePreview" style="background-image: url(assets/images/recent-pic/drop-bx.png);">
                      <div  class="remove-img remove-btn d-none"><i class="icon feather icon-x"></i></div>
                      <input type='file' class="form-control d-none imageUpload"  id="imageUpload3" accept=".png, .jpg, .jpeg">
                      <label for="imageUpload3"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="dz-drop-box style-2">
              <img src="assets/images/recent-pic/drop-bx.png" alt="">
              <div class="drop-bx">
                <div class="imagePreview" style="background-image: url(assets/images/recent-pic/drop-bx.png);">
                  <div  class="remove-img remove-btn d-none"><i class="icon feather icon-x"></i></div>
                  <input type='file' class="form-control d-none imageUpload"  id="imageUpload4" accept=".png, .jpg, .jpeg">
                  <label for="imageUpload4"></label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="dz-drop-box style-2">
              <img src="assets/images/recent-pic/drop-bx.png" alt="">
              <div class="drop-bx">
                <div class="imagePreview" style="background-image: url(assets/images/recent-pic/drop-bx.png);">
                  <div  class="remove-img remove-btn d-none"><i class="icon feather icon-x"></i></div>
                  <input type='file' class="form-control d-none imageUpload"  id="imageUpload5" accept=".png, .jpg, .jpeg">
                  <label for="imageUpload5"></label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="dz-drop-box style-2">
              <img src="assets/images/recent-pic/drop-bx.png" alt="">
              <div class="drop-bx">
                <div class="imagePreview" style="background-image: url(assets/images/recent-pic/drop-bx.png);">
                  <div  class="remove-img remove-btn d-none"><i class="icon feather icon-x"></i></div>
                  <input type='file' class="form-control d-none imageUpload"  id="imageUpload6" accept=".png, .jpg, .jpeg">
                  <label for="imageUpload6"></label>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        
        <div class="card style-3">
          <div class="card-header">
            <h6 class="title mb-0 font-14 font-w500">Profile Description</h6>
          </div>
          <div class="card-body">
            <a href="javascript:void(0);" class="setting-input" data-bs-toggle="offcanvas" data-bs-target="#offcanvasProfileDescription" aria-controls="offcanvasBottom3">
              <span> {{ profileDescription }} </span>
            </a>
          </div>
        </div>


        <div class="card style-3">
          <div class="card-header">
            <h6 class="title mb-0 font-14 font-w500">Interests</h6>
          </div>
          <div class="card-body">
            <a href="javascript:void(0);" class="setting-input" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom1" aria-controls="offcanvasBottom3">
              <!-- <span>Photography, Tea, Travel</span> -->
              <span> {{ profileInterestsNames }} </span>
            </a>
          </div>
        </div>
        
        <!-- <div class="card style-3">
          <div class="card-header">
            <h6 class="title mb-0 font-14 font-w500">Relationship goals</h6>
          </div>
          <div class="card-body">
            <a href="javascript:void(0);" class="setting-input" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom2" aria-controls="offcanvasBottom3">
              <span>Long-term partner</span>
              <i class="icon feather dz-flex-box icon-chevron-right ms-auto me-0"></i>
            </a>
          </div>
        </div> -->
        
        <div class="card style-3">
          <div class="card-header">
            <h6 class="title mb-0 font-14 font-w500">Languages I know</h6>
          </div>
          <div class="card-body">
            <a href="javascript:void(0);" class="setting-input" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLang" aria-controls="offcanvasLang">
              <span class="select-lang"> {{ profileLanguagesNames }}</span>
            </a>
          </div>
        </div>
        
        <!-- <div class="card style-3">
          <div class="card-header">
            <h6 class="title mb-0 font-14 font-w500">Sexual Orientation</h6>
          </div>
          <div class="card-body">
            <a href="javascript:void(0);" class="setting-input" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom3" aria-controls="offcanvasBottom3">
              <span>Straight</span>
              <i class="icon feather dz-flex-box icon-chevron-right ms-auto me-0"></i>
            </a>
          </div>
        </div> -->
      </div> 
    </div>
    <!-- Page Content End -->

    <!-- Profile Description OffCanvas -->
      <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasProfileDescription">
        <button type="button" class="btn-close drage-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        <div class="offcanvas-header share-style m-0 pb-0">
          <h6 class="title">Profile Description</h6>
        </div>
        <div class="offcanvas-body">
          <form>
            <div class="input-group input-group-icon">
              <!-- <textarea  placeholder="Input Profile Description..." class="form-control"  @input="handleSaveProfileDescription(user_id)">
              </textarea> -->
              <textarea placeholder="Input Profile Description..." class="form-control"  v-model="profileDescription"></textarea>
            </div>
            <a href="javascript:void(0);" @click="handleSaveProfileDescription(user_id)" class="btn btn-gradient w-100 dz-flex-box btn-shadow rounded-xl" data-bs-dismiss="offcanvas" aria-label="Close">Save</a>
          </form>
        </div>
      </div>
    <!-- Profile Description OffCanvas -->
    
    <!--  Interest OffCanvas -->
      <div class="offcanvas offcanvas-bottom intrests" tabindex="-1" id="offcanvasBottom1">
      <div class="offcanvas-header share-style">
        <h6 class="title mb-0">Interest</h6>
        <button type="button" class="btn-close dz-flex-box" data-bs-dismiss="offcanvas" aria-label="Close">
          <i class="icon feather icon-x font-22"></i>
        </button>
      </div>
          <div class="offcanvas-body">
        <div class="input-group input-group-icon search-input">
          <div class="input-group-text">
            <div class="input-icon">
              <i class="icon feather icon-search"></i>
            </div>
          </div>
          <input type="search" class="form-control ms-0" placeholder="Search..." v-model="searchInterestsName" @input="handleSearchInterest">
        </div>

        <ul class="dz-tag-list style-select">
          <!-- <li v-for="{selectedInterest, index} in selectedInterests" :key="index">  -->
          <li v-for="selectedInterest, index in selectedInterests" :key="index"> 
            <div class="dz-tag">
              <!-- <span>{{ selectedInterest.name }}</span> -->
              <i :class="['icon', 'feather', `icon-${selectedInterest.icon}`]"></i>
              <span>{{ selectedInterest.name }}</span>
              <span class="remove-tag" @click="removeSelectedInterest(index)"> x </span>
            </div>
          </li>
        </ul>
        

        <ul class="dz-tag-list style-2">
          <li v-for="interest in listInterests" :key="interest.id"> 
            <div class="dz-tag" @click="addSelectedInterest(interest)">
              <!-- <i class="icon feather icon-instagram"></i> -->
              <i :class="['icon', 'feather', `icon-${interest.icon}`]"></i>
              <span>{{ interest.name }}</span>
            </div>
          </li>
        </ul>
        <!-- <ul class="dz-tag-list style-2">
          <li> 
            <div class="dz-tag">
              <span>Ludo</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Football</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Cricket</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Tea</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Brunch</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Shopping</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Instagram</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Collecting</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Travelling</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>coffee</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Movies</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Dancing</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Bike</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Cars</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Study</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Walking</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Running</span>
            </div>
          </li>
          <li> 
            <div class="dz-tag">
              <span>Manga</span>
            </div>
          </li>
        </ul> -->
          </div>
      </div>
    <!--  Interest OffCanvas -->
    
    <!--  Relationship OffCanvas -->
      <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom2">
      <button type="button" class="btn-close drage-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      <div class="offcanvas-header share-style m-0 pb-0">
        <h6 class="title">Relationship Goals</h6>
      </div>
          <div class="offcanvas-body">
        <div class="radio style-2">
          <label class="radio-label">
            <input type="radio" checked="checked" name="radio2">
            <span class="checkmark">						
              <span class="text">Long-term partner</span>
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio2">
            <span class="checkmark">
              <span class="text">Long-term, open to short</span>
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio2">
            <span class="checkmark">
              <span class="text">Short-term, open to long</span>	
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio2">
            <span class="checkmark">
              <span class="text">Short-term fun</span>	
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio2">
            <span class="checkmark">
              <span class="text">New friends</span>	
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio2">
            <span class="checkmark">
              <span class="text">Still figuring it out</span>	
              <span class="check"></span>							
            </span>
          </label>
        </div>
          </div>
      </div>
    <!--  Relationship OffCanvas -->
    
    <!--  Languages OffCanvas -->
      <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasLang">
        <div class="offcanvas-header share-style">
          <h6 class="title mb-0">Language</h6>
          <button type="button" class="btn-close dz-flex-box" data-bs-dismiss="offcanvas" aria-label="Close">
            <i class="icon feather icon-x font-22"></i>
          </button>
        </div>
        <div class="offcanvas-body">
          <div class="input-group input-group-icon search-input">
            <div class="input-group-text">
              <div class="input-icon">
                <i class="icon feather icon-search"></i>
              </div>
            </div>
            <input type="search" class="form-control ms-0" placeholder="Search..." v-model="searchLanguagesName" @input="handleSearchLanguage">
          </div>

          <ul class="dz-tag-list style-select">
            <li v-for="selectedLanguage, index in selectedLanguages" :key="index"> 
              <div class="dz-tag">
                <span>{{ selectedLanguage.name }}</span>
                <span class="remove-tag" @click="removeSelectedLanguage(index)"> x </span>
              </div>
            </li>
          </ul>

          <ul class="dz-tag-list style-2">
            <li v-for="language in listLanguages" :key="language.id"> 
              <div class="dz-tag" @click="addSelectedLanguage(language)">
                <span>{{ language.name }}</span>
              </div>
            </li>
          </ul>
          <!--
          <div class="dz-list">
            <ul class="mb-2 confirm-lang">
              <li data-lang="Amharic">
                <a href="javascript:void(0);" class="item-content py-2 item-link">
                  <div class="media media-30 me-3">
                    <img src="assets/images/flags/ethiopia.svg" alt="/">
                  </div>
                  <div class="dz-inner">
                    <span class="title">Amharic</span>
                  </div>
                </a>
              </li>
               <li data-lang="Amharic">
                <a href="javascript:void(0);" class="item-content py-2 item-link">
                  <div class="media media-30 me-3">
                    <img src="assets/images/flags/ethiopia.svg" alt="/">
                  </div>
                  <div class="dz-inner">
                    <span class="title">Amharic</span>
                  </div>
                </a>
              </li>
              <li data-lang="Indian">
                <a href="javascript:void(0);" class="item-content py-2 item-link">
                  <div class="media media-30 me-3">
                    <img src="assets/images/flags/india.svg" alt="/">
                  </div>
                  <div class="dz-inner">
                    <span class="title">Indian</span>
                  </div>
                </a>
              </li>
              <li data-lang="English">
                <a href="javascript:void(0);" class="item-content py-2 item-link">
                  <div class="media media-30 me-3">
                    <img src="assets/images/flags/united-states.svg" alt="/">
                  </div>
                  <div class="dz-inner">
                    <span class="title">English</span>
                  </div>
                </a>
              </li>
              <li data-lang="German">
                <a href="javascript:void(0);" class="item-content py-2 item-link">
                  <div class="media media-30 me-3">
                    <img src="assets/images/flags/germany.svg" alt="/">
                  </div>
                  <div class="dz-inner">
                    <span class="title">German</span>
                  </div>
                </a>
              </li>
              <li data-lang="Italian">
                <a href="javascript:void(0);" class="item-content py-2 item-link">
                  <div class="media media-30 me-3">
                    <img src="assets/images/flags/italy.svg" alt="/">
                  </div>
                  <div class="dz-inner">
                    <span class="title">Italian</span>
                  </div>
                </a>
              </li>	
              <li class="border-0" data-lang="Spanish">
                <a href="javascript:void(0);" class="item-content py-2 item-link">
                  <div class="media media-30 me-3">
                    <img src="assets/images/flags/spain.svg" alt="/">
                  </div>
                  <div class="dz-inner">
                    <span class="title">Spanish</span>
                  </div>
                </a>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
       
    <!--  Languages OffCanvas -->
    
    <!--  Orientation OffCanvas -->
      <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom3">
      <button type="button" class="btn-close drage-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      <div class="offcanvas-header share-style m-0 pb-0">
        <h6 class="title">Sexual Orientation</h6>
      </div>
          <div class="offcanvas-body">
        <div class="radio style-2">
          <label class="radio-label">
            <input type="radio" checked="checked" name="radio3">
            <span class="checkmark">						
              <span class="text">Straight</span>
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio3">
            <span class="checkmark">
              <span class="text">Gay</span>
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio3">
            <span class="checkmark">
              <span class="text">Lesbian</span>	
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio3">
            <span class="checkmark">
              <span class="text">Bisexual</span>	
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio3">
            <span class="checkmark">
              <span class="text">Asexual</span>	
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio3">
            <span class="checkmark">
              <span class="text">Queer</span>	
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio3">
            <span class="checkmark">
              <span class="text">Demisexual</span>	
              <span class="check"></span>							
            </span>
          </label>
        </div>
          </div>
      </div>
    <!--  Orientation OffCanvas -->
    
  </div>  

</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PreLoader from '@/components/PreLoader.vue';
import TitledHeader from './TitledHeader.vue';
import { getSearchInterests, getProfileInterests, storeProfileInterests, deleteProfileInterests,
  getSearchLanguages, getProfileLanguages, storeProfileLanguages, deleteProfileLanguages,
  updateUserProfile
} from '@/api';

@Component({
components: {
  PreLoader,
  TitledHeader,
},
})
export default class Explore extends Vue {
  private listLanguages: object[] = [];
  private listInterests: object[] = [];
  private listProfileInterests: object[] = [];
  private searchInterestsName: string = '';
  private searchLanguagesName: string = '';
  private selectedInterests: object[] = [];
  private selectedLanguages: object[] = [];
  private user_id: number = 0;
  private profileInterestsNames: string = 'Add Interests';
  private profileInterestsResponse: object[] = [];
  private profileLanguagesNames: string = 'Add Languages';
  private profileLanguagesResponse: object[] = [];
  private profileDescription: string = 'Add Profile Description';
  private is_loading: boolean = true;
  private is_fetching_profile_languages: boolean = true;
  private is_fetching_profile_interests: boolean = true;
  private is_fetching_languages: boolean = true;
  private is_fetching_interests: boolean = true;
  private done_loading: boolean = true;


  data() {
    return {
      selectedInterests: [],
      selectedLanguages: [],
      user_id: this.$store.state.userData?.id,
      profileInterestsNames: this.profileInterestsNames,
      profileInterestsResponse: this.profileInterestsResponse,
      profileLanguagesNames: this.profileLanguagesNames,
      profileLanguagesResponse: this.profileLanguagesResponse,
      profileDescription: (this.$store.state.userData as any)?.userProfile?.profile_description ?? 'This is my Profile', // this.profileDescription,
      is_loading: true,
      is_fetching_languages: true,
      is_fetching_interests: true,
      is_fetching_profile_languages: true,
      is_fetching_profile_interests: true,
      done_loading: true
    }
  }

  mounted() {
    this.fetchLanguages('');
    this.fetchInterests('');
    this.fetchProfileInterests(this.user_id);
    this.fetchProfileLanguages(this.user_id);
  }

  handleSearchInterest() {
    if (this.searchInterestsName.length >= 3 || !this.searchInterestsName) {
      this.fetchInterests(this.searchInterestsName);
    }
  }

  handleSearchLanguage() {
    if (this.searchLanguagesName.length >= 3 || !this.searchLanguagesName) {
      this.fetchLanguages(this.searchLanguagesName);
    }
  }

  handleSaveProfileDescription(id: number) {
    console.log(this.profileDescription);
    const newData = {
      'profile_description' : this.profileDescription
    }

    this.putUpdateUserProfile(id, newData);

  }


  putUpdateUserProfile(id:number, newData:object) {
    updateUserProfile(id, newData)
      .then(response => {
        if (response.data) {
          this.$store.commit('setUserProfile', response.data.userProfile);
          console.log(this.profileDescription);
        }
      })
      .catch(error => {
        console.error("Error updating changes: ", error);
      });
  }


  fetchLanguages(name: string) {
    getSearchLanguages(name)
      .then(response => {
        this.listLanguages = response.data.items;
        this.listInterests = response.data.items;

        this.is_fetching_languages = false;
        if (!this.is_fetching_interests && !this.is_fetching_languages && !this.is_fetching_profile_interests && !this.is_fetching_profile_languages) {
          this.is_loading = false;
        }
      })
      .catch(error => {
        console.error('Error fetching languages:', error);
      })
  }

  fetchInterests(name: string){
    getSearchInterests(name)
      .then(response => {
        this.listInterests = response.data.items;
        
        this.is_fetching_interests = false;
        if (!this.is_fetching_interests && !this.is_fetching_languages && !this.is_fetching_profile_interests && !this.is_fetching_profile_languages) {
          this.is_loading = false;
        }
      })
      .catch(error => {
        console.error('Error fetching interests:', error);
      })
  }

  fetchProfileInterests(id: number){
    getProfileInterests(id)
      .then(response => {
        const responseData = response.data.items;
        if (responseData.length > 0){
          this.profileInterestsResponse = responseData;
          this.selectedInterests = responseData.map((item:any)  => item.interest);
          this.profileInterestsNames = responseData.map((item:any) => item.interest.name).join(', ');
        }
        else {
          this.profileInterestsNames = 'Add Interests';
        }

        this.is_fetching_profile_interests = false;
        if (!this.is_fetching_interests && !this.is_fetching_languages && !this.is_fetching_profile_interests && !this.is_fetching_profile_languages) {
          this.is_loading = false;
        }

      }) 
      .catch(error => {
        console.error(`Error fetching user's interests: `, error);
      })
  }

  fetchProfileLanguages(id: number){
    getProfileLanguages(id)
      .then(response => {
        const responseData = response.data.items;
        if (responseData.length > 0){
          this.profileLanguagesResponse = responseData;
          this.selectedLanguages = responseData.map((item:any)  => item.language);
          this.profileLanguagesNames = responseData.map((item:any) => item.language.name).join(', ');
        }
        else {
          this.profileLanguagesNames = 'Add Languages';
        }

        this.is_fetching_profile_languages = false;
        if (!this.is_fetching_interests && !this.is_fetching_languages && !this.is_fetching_profile_interests && !this.is_fetching_profile_languages) {
          this.is_loading = false;
        }

      }) 
      .catch(error => {
        console.error(`Error fetching user's languages: `, error);
      })
  }



  async postProfileInterest(newData:object){
    storeProfileInterests(newData)
      .then(response => {
        if (response.data) {
          const responseData = response.data.data;
          this.profileInterestsResponse = responseData;
          this.profileInterestsNames = responseData.map((item:any) => item.interest.name).join(', ');
        }
      })
      .catch(error => {
        console.error("Error saving profile interests: ", error);
      })

  }

  async postProfileLanguages(newData:object){
    storeProfileLanguages(newData)
      .then(response => {
        if (response.data) {
          const responseData = response.data.data;
          this.profileLanguagesResponse = responseData;
          this.profileLanguagesNames = responseData.map((item:any) => item.language.name).join(', ');
        }
      })
      .catch(error => {
        console.error("Error saving profile languages: ", error);
      })
  }

  async addSelectedInterest(interest: object) {

    const interestExists = this.selectedInterests.some(selectedInterest => {
      return JSON.stringify(selectedInterest) === JSON.stringify(interest)
    });

    if (!interestExists){

      const interestID: number[] = [] 
      interestID.push((interest as any).id as number);
      const newData = {
        'user_id': this.user_id,
        'interests': interestID
      }

      this.postProfileInterest(newData)
        .then( response => {
          this.selectedInterests.push(interest); // Add the selected interest to the array
        });

    }
  }

  async addSelectedLanguage(language: object) {

  const languageExists = this.selectedLanguages.some(selectedLanguage => {
    return JSON.stringify(selectedLanguage) === JSON.stringify(language)
  });

  if (!languageExists){

    const languageID: number[] = [] 
    languageID.push((language as any).id as number);
    const newData = {
      'user_id': this.user_id,
      'languages': languageID
    }

    this.postProfileLanguages(newData)
    .then(response => {
      this.selectedLanguages.push(language); // Add the selected language to the array
    });

  }
  }

  async removeSelectedInterest(index: number) {
  try {

    // Find the corresponding item in profileInterestsResponse
    const correspondingItemIndex = this.profileInterestsResponse.findIndex(
      (item: any) => item.interest.id === (this.selectedInterests[index] as any).id
    );

    if (correspondingItemIndex === -1) {
      console.error('Corresponding item not found in profileInterestsResponse');
      return;
    }

    const correspondingItem = this.profileInterestsResponse[correspondingItemIndex];

    // Get the id for deletion
    const id = (correspondingItem as any).id;

    // Call the delete function (assuming it returns a promise)
    await deleteProfileInterests(id)
    .then(response => {

      // Remove the selected interest from selectedInterests array
      this.selectedInterests.splice(index, 1);
      
      // Remove the corresponding item from profileInterestsResponse
      this.profileInterestsResponse.splice(correspondingItemIndex, 1);
  
      // Update profileInterestsNames
      this.profileInterestsNames = this.selectedInterests
        .map((interest: any) => interest.name)
        .join(', ');

    });


  } catch (error) {
    console.error('Error removing selected interest:', error);
  }
}

async removeSelectedLanguage(index: number) {
  try {

    // Find the corresponding item in profileLanguagesResponse
    const correspondingItemIndex = this.profileLanguagesResponse.findIndex(
      (item: any) => item.language.id === (this.selectedLanguages[index] as any).id
    );

    if (correspondingItemIndex === -1) {
      console.error('Corresponding item not found in profileLanguagesResponse');
      return;
    }

    const correspondingItem = this.profileLanguagesResponse[correspondingItemIndex];

    // Get the id for deletion
    const id = (correspondingItem as any).id;

    // Call the delete function (assuming it returns a promise)
    await deleteProfileLanguages(id)
    .then(response => {

      // Remove the selected interest from selectedInterests array
      this.selectedLanguages.splice(index, 1);
      
      // Remove the corresponding item from profileInterestsResponse
      this.profileLanguagesResponse.splice(correspondingItemIndex, 1);
  
      // Update profileInterestsNames
      this.profileLanguagesNames = this.selectedLanguages
        .map((language: any) => language.name)
        .join(', ');

    });


  } catch (error) {
    console.error('Error removing selected language:', error);
  }
}


  old_removeSelectedInterest(index: number) {
    console.log(`index: ${index}`);
    const correspondingItem = this.profileInterestsResponse.find((item:any) => item.interest.id === (this.selectedInterests[index] as any).id);
    console.log(correspondingItem);
    const id = (correspondingItem as any).id;
    console.log(`id: ${id}`);

    deleteProfileInterests(id);
    this.selectedInterests.splice(index, 1); // Remove the selected interest from the array
    this.profileInterestsNames = this.selectedInterests.map((interest: any) => interest.name).join(', ');
  }

  old_removeSelectedLanguage(index: number) {

    const correspondingItem = this.profileLanguagesResponse.find((item:any) => item.language.id === (this.selectedLanguages[index] as any).id);
    
    console.log(correspondingItem);
    const id = (correspondingItem as any).id;
    console.log(`id: ${id}`);


    deleteProfileLanguages(id);
    this.selectedLanguages.splice(index, 1); // Remove the selected language from the array
    this.profileLanguagesNames = this.selectedLanguages.map((language: any) => language.name).join(', ');
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 .remove-tag {
    cursor: pointer;
    color: red  !important;
  }

  .dz-tag-list.style-select .dz-tag {

      background-color: var(--rgba-primary-1) ;
      border: 2px solid var(--primary);
      font-size: 12px; }
</style>
