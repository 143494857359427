<template>
  <div class="preloader">
    <!-- Preloader -->
      <div id="preloader" ref="preloader">
        <div class="loader">
          <div class="load-circle"><div></div><div></div></div>
        </div>
      </div>
    <!-- Preloader end-->
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import jQuery from 'jquery';

@Component
export default class PreLoader extends Vue {
  // showPreloader: boolean = true;

  handlePreloader() {
      // Access the element using $refs
    
    const preloaderElement = this.$refs.preloader;

    // Check if the element exists before manipulating it
    if (preloaderElement) {
      setTimeout(function() {
        jQuery(preloaderElement).fadeOut(300);
      }, 300);
    }
  }

  mounted() {
    this.handlePreloader();
  }   
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
