<template>
  <div class="settings">
    <Settings></Settings>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Settings from '@/components/Settings.vue'; // @ is an alias to /src

@Component({
  components: {
    Settings,
  },
})
export default class SettingsView extends Vue {}
</script>
