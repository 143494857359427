<template>
  <div class="page-wrapper">
    
    <!-- Preloader -->
   <PreLoader></PreLoader>
      <!-- Preloader end-->
    
    <!-- Header -->
      <TitledHeader title="Not Found"></TitledHeader>
    <!-- Header -->
    
    <!-- Page Content Start -->
    <div class="page-content space-top">
      <div class="container">
        <div class="error-page">
          <div class="icon-bx">
            <img src="assets/images/error2.svg" alt="">
          </div>
          <div class="clearfix">
            <h2 class="title text-primary">Sorry</h2>
            <p>Requested content not found.</p>
          </div>
        </div>
        <div class="error-img">
          <img src="assets/images/error.png" alt="">
        </div>
      </div>
    </div>
    <!-- Page Content End -->
  </div>  
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import NextFooter from '@/components/NextFooter.vue';
import PreLoader from './PreLoader.vue';
import TitledHeader from './TitledHeader.vue';

@Component({
components: {
  NextFooter,
  TitledHeader
},
})
export default class Error extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
