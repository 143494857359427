<template>
  <div class="firstname">
    <FirstName></FirstName>
  </div>
</template>


<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import FirstName from '@/components/FirstName.vue';

@Component({
components: {
  FirstName,
},
})
export default class FirstNameView extends Vue {
  
}
</script>
