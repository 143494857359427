import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // sample authenticated userData:
    // userData: {
    // "id": 54,
    // "username": null,
    // "email": "zzzzzzzzdaniel@bahi.com",
    // "phone_no": "+25145678",
    // "status": 10,
    // "created_at": "2024-03-20 12:43:15",
    // "updated_at": "2024-03-28 14:04:45",
    // "deleted_at": null,
    // "user_type_id": 1,
    // "userProfile": {
    // 	"id": 54,
    // 	"profile_pic": "uploads/54/65fada1475b4b.jpg",
    // 	"gender": "Male",
    // 	"location": "882 Guder Street, Bahir Dar",
    // 	"location_city": 9,
    // 	"birthdate": "1999-03-11",
    // 	"sexual_orientation_id": null,
    // 	"name": "Daniel",
    // 	"audio_intro": null,
    // 	"relationship_goals_id": 1,
    // 	"dating_interest": "Women",
    // 	"age_preference": 64,
    // 	"profile_description": "This is my Profile Description"
    // },
    // "profileLanguages": [
    // 	{
    // 		"id": 9,
    // 		"language_id": 1,
    // 		"user_id": 54,
    // 		"language": {
    // 			"id": 1,
    // 			"name": "Amharic"
    // 		}
    // 	},
    // 	{
    // 		"id": 10,
    // 		"language_id": 109,
    // 		"user_id": 54,
    // 		"language": {
    // 			"id": 109,
    // 			"name": "English"
    // 		}
    // 	}
    // ],
    // "profileInterests": [
    // 	{
    // 		"id": 12,
    // 		"user_id": 54,
    // 		"interest_id": 1,
    // 		"interest": {
    // 			"id": 1,
    // 			"name": "Movies",
    // 			"icon": "thumbs-up"
    // 		}
    // 	}
    // ]
    // }


    // SAMPLE signup userData
    // userData: {
    //   "phone_no": "+25110",

    //   "country_code": "+251",    
    //   "phone": "110",

    //   "username": "username1",

    //   "password_hash": "password",
    //   "confirm_password": "password",
    //   "name": "Jane",
    //   "birthdate": "1991-12-10",
    //   "gender": "Female",
    //   "dating_interest": "Men",
    //   "relationship_goals_id": 1
    // }

    userData: JSON.parse(localStorage.getItem('userData')!) || {},

    relationshipGoals: JSON.parse(localStorage.getItem('relationshipGoals')!) || [],
    isOTPVerified: JSON.parse(localStorage.getItem('isOTPVerified')!) || false,
    chatSocketPort: JSON.parse(localStorage.getItem('chatSocketPort')!) || 8080,
    // chatSocketHost: JSON.parse(localStorage.getItem('chatSocketHost')!) || `ws://${process.env.VUE_APP_SOCKET_HOST as any}:${process.env.VUE_APP_SOCKET_PORT as any}`,
    chatSocketHost: JSON.parse(localStorage.getItem('chatSocketHost')!) || `${process.env.VUE_APP_SOCKET_HOST as any}`

  },
  getters: {
  },
  mutations: {
    setUserData(state, payload) {
      state.userData = { ...state.userData, ...payload };
      localStorage.setItem('userData', JSON.stringify(state.userData));
      // alert(JSON.stringify( state.userData));
    },
    setUserProfile(state, payload) {
      state.userData.userProfile = payload;
      localStorage.setItem('userData', JSON.stringify(state.userData));
      // alert(JSON.stringify( state.userData));
    },
    setRelationshipGoals(state, payload) {
      state.relationshipGoals = payload;
      localStorage.setItem('relationshipGoals', JSON.stringify(state.relationshipGoals));
      // alert(JSON.stringify( state.relationshipGoals));
    },
    setIsOTPVerified(state) {
      state.isOTPVerified = true;
      localStorage.setItem('isOTPVerified', 'true');
    },
    clearUserData(state) {
      state.userData = {
      };
      localStorage.removeItem('userData');
       
    },
    clearState(state) {
      state.userData = {
      };
      localStorage.removeItem('userData');

      state.isOTPVerified = false;
      localStorage.removeItem('isOTPVerified');

      // state.chatSocketHost = `ws://${process.env.VUE_APP_SOCKET_HOST as any}:${process.env.VUE_APP_SOCKET_PORT as any}`;
      state.chatSocketHost = `${process.env.VUE_APP_SOCKET_HOST as any}`;
      localStorage.removeItem('chatSocketHost');

      state.chatSocketPort = 8083;
      localStorage.removeItem('chatSocketPort');
    },
    setChatSocketPort(state, payload) {
      state.chatSocketPort = payload;

      localStorage.setItem('chatSocketPort', JSON.stringify(state.chatSocketPort));
    },
    setChatSocketHost(state, payload) {
      state.chatSocketHost = payload;

      localStorage.setItem('chatSocketHost', JSON.stringify(state.chatSocketHost));
    },
    

    
  },
  actions: {
    // updateFirstName({ commit }, payload) {
    //   commit('setUserData', firstName);
    // },
  },
  modules: {
  }
})
