var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"dark-overlay",class:{ active: _vm.isVisible },on:{"click":_vm.toggleVisibility}}),_c('div',{staticClass:"sidebar",class:{ show: _vm.isVisible }},[_c('div',{staticClass:"inner-sidebar"},[_c('a',{staticClass:"author-box",attrs:{"href":'/profile'}},[_c('div',{staticClass:"dz-media"},[_c('img',{attrs:{"src":_vm.userData.userProfile && _vm.userData.userProfile.profile_pic ?  _vm.apiHost + '/api/get-image?location=' + _vm.userData.userProfile.profile_pic : '/assets/images/user/def-pics/pic1.jpg',"alt":"author-image"}})]),_c('div',{staticClass:"dz-info"},[_c('h5',{staticClass:"name"},[_vm._v(_vm._s(_vm.userData.userProfile ? _vm.userData.userProfile.name : 'user'))]),_c('span',[_vm._v(_vm._s(_vm.userData ? _vm.userData.email : 'user@email.com'))])])]),_c('ul',{staticClass:"nav navbar-nav"},[_c('li',[_c('a',{staticClass:"nav-link active",attrs:{"href":'/feed'}},[_vm._m(0),_c('span',[_vm._v("Home")])])]),_c('li',[_c('a',{staticClass:"nav-link",attrs:{"href":'/'}},[_vm._m(1),_c('span',[_vm._v("Welcome")])])]),_c('li',[_c('a',{staticClass:"nav-link",attrs:{"href":'/profile'}},[_vm._m(2),_c('span',[_vm._v("Profile")])])]),_c('li',[_c('a',{staticClass:"nav-link",on:{"click":_vm.logout}},[_vm._m(3),_c('span',[_vm._v("Logout")])])])]),_c('div',{staticClass:"sidebar-bottom"},[_c('ul',{staticClass:"app-setting"},[_c('li',[_c('div',{staticClass:"mode"},[_vm._m(4),_c('span',[_vm._v("Dark Mode")]),_c('div',{staticClass:"custom-switch"},[_c('input',{ref:"toggleDarkMenu",staticClass:"switch-input theme-btn",attrs:{"type":"checkbox","id":"toggle-dark-menu"},on:{"click":_vm.toggleDarkMode}}),_c('label',{staticClass:"custom-switch-label",attrs:{"for":"toggle-dark-menu"}})])])])]),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"dz-icon"},[_c('i',{staticClass:"icon feather icon-home"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"dz-icon"},[_c('i',{staticClass:"icon feather icon-wind"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"dz-icon"},[_c('i',{staticClass:"icon feather icon-user"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"dz-icon"},[_c('i',{staticClass:"icon feather icon-log-out"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"dz-icon"},[_c('i',{staticClass:"icon feather icon-moon"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-info"},[_c('h6',{staticClass:"name"},[_vm._v("Shay Buna - Dating App")])])
}]

export { render, staticRenderFns }