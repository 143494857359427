<template>
  <div class="bg-white">
    <div class="page-wrapper">
      <!-- Preloader -->
      <!-- <div id="preloader">
        <div class="loader">
          <div class="load-circle"><div></div><div></div></div>
        </div>
      </div> -->
        <!-- Preloader end-->
        <PreLoader></PreLoader>

        <!-- Page Content -->
        <div class="page-content">
        <div class="container">
          <div class="account-area">
            <BackButton></BackButton>

            <Toast :message="toastMessage" :visible.sync="isToastMessageVisible" />

            <!-- <a href="javascript:void(0);" class="back-btn dz-flex-box">
              <i class="icon feather icon-chevron-left"></i>
            </a> -->
            <div class="section-head ps-0">
              <h3>Enter your Birth Date?</h3>
            </div>
            <div class="mb-2 input-group input-group-icon input-mini">
              <div class="input-group-text">
                <div class="input-icon">
                  <i class="icon feather icon-calendar"></i>
                </div>
              </div>
              <input type="date" v-model="birthdate" :max="maxDate" class="form-control">								
            </div>
          </div>
            </div>
        </div>
        <!-- Page Content End -->
      <!-- Footer -->
        <!-- <div class="footer fixed bg-white">
        <div class="container">
          <a href="your-gender.html" class="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl">Next</a>
        </div>
      </div> -->
      <!-- Footer -->
      <ButtonFooter :onClick="handleNextClick" next="/gender" label="Next"></ButtonFooter>

    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';
import ButtonFooter from './ButtonFooter.vue';
import BackButton from './BackButton.vue';
import Toast from './Toast.vue';

@Component({
components: {
  ButtonFooter,
  PreLoader,
  BackButton,
  Toast
},
})
export default class BirthDate extends Vue {
  private birthdate: string = '';
  private maxDate: string = '';
  private toastMessage: string = '';
  private isToastMessageVisible: boolean = false;


  private handleNextClick() {

      if(!this.birthdate) {
        this.toastMessage = 'Field cannot be left blank';
        this.isToastMessageVisible = true;
        return;
      }

      const maxDate = new Date(this.maxDate);
      const birthday = new Date(this.birthdate);
      const maxDateString = new Date(this.maxDate).toLocaleDateString(undefined,  { year: 'numeric', month: 'long', day: 'numeric' });

      if (birthday > maxDate){
        this.toastMessage = `Birthdate must be ${maxDateString} or earlier`;
        this.isToastMessageVisible = true;
        return;
      }
    
      this.$store.commit('setUserData', { birthdate: this.birthdate });
      this.$router.push('/gender');
  }

  data() {
    const user_userData = this.$store.state.userData;
    return {
        birthdate: user_userData?.birthdate ?? '',
        maxDate: this.calculateMaxDate()

    };
  }

  mounted() {
    this.maxDate = this.calculateMaxDate();
  }

  calculateMaxDate() {
    const today = new Date();
    const maxDate = new Date(today.setFullYear(today.getFullYear() - 18));
    return maxDate.toISOString().split('T')[0];
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
