<template>
  <div class="feed">
    <Feed></Feed>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Feed from '@/components/Feed.vue';

@Component({
components: {
  Feed,
},
})
export default class FeedView extends Vue {}
</script>
