<template>
  <div class="password">
    <Password></Password>
  </div>
</template>


<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import Password from '@/components/Password.vue';

@Component({
components: {
  Password,
},
})
export default class PasswordView extends Vue {
  
}
</script>
