<template>
  <div class="email">
    <Email></Email>
  </div>
</template>


<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import Email from '@/components/Email.vue';

@Component({
components: {
  Email,
},
})
export default class EmailView extends Vue {
  
}
</script>
