<template>
  <div class="profile-pic">
    <ProfilePic></ProfilePic>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ProfilePic from '@/components/ProfilePic.vue';

@Component({
components: {
  ProfilePic,
},
})
export default class ProfilePicView extends Vue {}
</script>
