<template>
  <div class="bg-white">
<div class="page-wrapper">
	<!-- Preloader -->
	<!-- <div id="preloader">
		<div class="loader">
			<div class="load-circle"><div></div><div></div></div>
		</div>
	</div> -->
    <!-- Preloader end-->
    <!-- <div v-if="!loading"> -->
      <PreLoader></PreLoader>
    <!-- </div> -->

    <!-- <div v-if="loading"> -->
      <!-- Page Content -->
      <div class="page-content">
      <div class="container">
        <div class="account-area">
          <BackButton></BackButton>

          <Toast :message="toastMessage" :visible.sync="isToastMessageVisible" />

          <!-- <a href="javascript:void(0);" class="back-btn dz-flex-box">
            <i class="icon feather icon-chevron-left"></i>
          </a> -->
          <div class="section-head ps-0">
            <h3>Enter your name?</h3>
          </div>
          <div class="mb-2 input-group input-group-icon input-mini">
            <div class="input-group-text">
              <div class="input-icon">
                <i class="icon feather icon-user"></i>
              </div>
            </div>
            <input type="text" class="form-control" v-model="firstName" id ="firstName" placeholder="Enter first name">								
          </div>
        </div>
          </div>
      </div>
      <!-- Page Content End -->
    <!-- Footer -->
      <!-- <div class="footer fixed bg-white">
      <div class="container">
        <a href="enter-birth-date.html" class="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl">Next</a>
      </div>
    </div> -->
    <!-- Footer -->
    <ButtonFooter :onClick="handleNextClick" label="Next"></ButtonFooter>
    <!-- <Button @click="next">Next</Button> -->
    <!-- </div> -->
</div>
</div>

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';
import ButtonFooter from './ButtonFooter.vue';
import BackButton from './BackButton.vue';
import Toast from './Toast.vue';


@Component({
components: {
  ButtonFooter,
  PreLoader,
  BackButton, 
  Toast
},
})

// export default class FirstName extends Vue {
//   @Prop() firstName!: string;
//   // @Prop(String) readonly firstNameField!: string;

//   localFirstNameField: string = 'String';

//   // data() {
//   //   return {
//   //     localFirstNameField: this.firstNameField
//   //   };
//   // }

//   methods = {
//     next(this: Vue): void {
//       this.$store.commit('setUserData', { firstName: this.localFirstNameField });
//       this.$router.push('/birthdate');
//     }
//   };
// };

export default class FirstName extends Vue {

  private firstName: string = '';
  private loading: boolean = true;
  private toastMessage: string = '';
  private isToastMessageVisible: boolean = false;
  private userData: object = {};

  // private updateFirstName() {
  //   this.$store.dispatch('updateFirstName', this.firstName);
  //   // Optionally, you can reset the input field after updating the store
  //   this.firstName = '';
  //   alert(this.$store.state.userData);
  // }

  // private next() {
  //     this.$store.commit('setUserData', { firstName: this.firstName });
  //     this.$router.push('/birthdate');

  //   }

  private handleNextClick() {
    if (!this.firstName) {

      this.toastMessage = 'Field cannot be left blank';
      this.isToastMessageVisible = true;
      return;
    }
      this.$store.commit('setUserData', { name: this.firstName });
      this.$router.push('/email');
  }

  data() {
    this.userData = this.$store.state.userData;
    const user_userData = this.$store.state.userData;
    return {
      loading : true,
      firstName: user_userData?.name ?? ''
    }
  }

  mounted() {
    // If your data is static, you can set loading to false directly in the mounted hook
    this.loading = false;
  }
  

};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
