<template>
  <div class="onboarding">
    <div class="bg-white">
  <div class="page-wrapper">
    <!-- Preloader -->
    <!-- <div id="preloader">
      <div class="loader">
        <div class="load-circle"><div></div><div></div></div>
      </div>
    </div> -->
    <!-- Preloader end-->
    <PreLoader></PreLoader>

      <!-- Welcome Start -->
    <div class="content-body">
      <div class="welcome-area bg-image">
        <div class="welcome-inner">
          <div class="dz-media">
            <img src="assets/images/onboarding/pic1.png" alt="">
          </div>
          <div class="swiper get-started">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="slide-info">
                  <div class="started">
                    <h2 class="title mb-3">Start Your Dating Story</h2>
                    <p>Your companion for meaningful connections</p>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="slide-info">
                  <div class="started">
                    <h2 class="title mb-3">Begin Your Chapter of Love</h2>
                    <p>Embrace the dating world armed with tools and support</p>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="slide-info">
                  <div class="started">
                    <h2 class="title mb-3">Your Journey of Connection</h2>
                    <p>Explore essentials and delights that boost confidence</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-btn">
              <div class="swiper-pagination style-1 flex-1"></div>
            </div>
          </div>
        </div>
        <div class="bottom-btn container">
          <a href="/login" class="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl">Find Someone</a>
        </div>
      </div>
    </div>
      <!-- Welcome End -->
      
      <!-- PWA Offcanvas -->
		<div class="offcanvas offcanvas-bottom pwa-offcanvas">
			<div class="container">
				<div class="offcanvas-body small">
					<img class="logo" src="assets/images/logo.png" alt="">
					<h5 class="title">Shay Buna Dating</h5>
					<p class="pwa-text">Install "Shay Buna Dating" to your home screen for easy access, just like any other app</p>
					<button type="button" class="btn btn-sm btn-primary pwa-btn">Add to Home Screen</button>
					<button type="button" class="btn btn-sm pwa-close btn-info ms-2 text-white">Maybe later</button>
				</div>
			</div>
		</div>
		<div class="offcanvas-backdrop pwa-backdrop"></div>
		<!-- PWA Offcanvas End --> 
      
  </div>
</div>
</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';
import store from '@/store';

@Component({
components: {
  PreLoader
},
})
export default class Onboarding extends Vue {
  
}
</script>
