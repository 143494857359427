<template>
  <div class="location">
    <Location></Location>
  </div>
</template>


<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import Location from '@/components/Location.vue';

@Component({
components: {
  Location,
},
})
export default class LocationView extends Vue {
  
}
</script>
