<template>
  <div  class="overflow-hidden">
<div class="page-wrapper">
    
	<PreLoader></PreLoader>
	
	<TitledHeader title="Date Filter"></TitledHeader>
	
	<!-- Page Content Start -->
	<div class="page-content space-top p-b50">
		<div class="container">
			<!-- <div class="card style-1">
				<div class="card-header">
					<h6 class="title font-w400 font-14 mb-0">Who you want to date</h6>
				</div>
				<div class="card-body">
					<div class="form-check">
						<label class="form-check-label" for="filterCheck">Men</label>
						<input class="form-check-input" type="checkbox" id="filterCheck">
					</div>
					<div class="form-check">
						<label class="form-check-label" for="filterCheck2">Women</label>
						<input class="form-check-input" type="checkbox" id="filterCheck2" checked="checked">
					</div>
					<div class="form-check">
						<label class="form-check-label" for="filterCheck3">Nonbinary people</label>
						<input class="form-check-input" type="checkbox" id="filterCheck3">
					</div>	
				</div>
			</div> -->

      <div class="card style-3">
				<div class="card-header">
					<h6 class="title mb-0 font-14 font-w500">Show Me</h6>
				</div>
				<div class="card-body">
					<a href="javascript:void(0);" class="setting-input" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom4" aria-controls="offcanvasBottom">
						<span>{{ genderName ? genderName : 'Gender' }}</span>
						<i class="icon feather dz-flex-box icon-chevron-right ms-auto me-0"></i>
					</a>
				</div>
			</div>

      <div class="card style-3">
				<div class="card-header">
					<h6 class="title mb-0 font-14 font-w500">Relationship goals</h6>
				</div>
				<div class="card-body">
					<a href="javascript:void(0);" class="setting-input" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom2" aria-controls="offcanvasBottom3">
						<span>{{ relationshipGoalName ? relationshipGoalName : 'Select'}}</span>
						<i class="icon feather dz-flex-box icon-chevron-right ms-auto me-0"></i>
					</a>
				</div>
			</div>


			<div class="card style-1">
				<div class="card-header">
					<h6 class="title font-w400 font-14 mb-0">Age</h6>
				</div>
				<div class="card-body">
					<div class="range-slider style-1">
						<div class="mb-3 title font-w600 font-16">
							<span class="example-val title slider-margin-value-min"> Between 18 and </span>
							<span class="example-val title slider-margin-value-max"> {{ age_preference }} </span>
						</div>
						<div id="slider-tooltips" ref="sliderElement"></div>
					</div>
				</div>
			</div>
			<!-- <div class="card style-1">
				<div class="card-header">
					<h6 class="title font-w400 font-14 mb-0">Distance</h6>
				</div>
				<div class="card-body">
					<div class="range-slider style-1">
						<div class="mb-3 title font-w600 font-16">
							<span class="example-val title slider-margin-value-min"></span>
							<span class="example-val title slider-margin-value-max"></span>
						</div>
						<div id="slider-tooltips2"></div>
					</div>
				</div>
			</div> -->
		</div>
	</div>
  
	<!-- Page Content End -->

  <!--  Relationship OffCanvas -->
  <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom2">
		<button type="button" class="btn-close drage-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		<div class="offcanvas-header share-style m-0 pb-0">
			<h6 class="title">Relationship Goals</h6>
		</div>
        <div class="offcanvas-body">
			<div class="radio style-2">
				
        <label class="radio-label" v-for="goal in relationshipGoals" :key="goal.id">
					<input type="radio" v-model="relationshipGoal" :value="goal.id" :name="radio2"
          @click="getRelationshipGoal(goal.name)">
					<span class="checkmark">						
						<span class="text">{{ goal.name }}</span>
						<span class="check"></span>							
					</span>
				</label>
        <!-- <label class="radio-label">
					<input type="radio" checked="checked" name="radio2">
					<span class="checkmark">						
						<span class="text">Long-term partner</span>
						<span class="check"></span>							
					</span>
				</label>
				<label class="radio-label">
					<input type="radio" name="radio2">
					<span class="checkmark">
						<span class="text">Long-term, open to short</span>
						<span class="check"></span>							
					</span>
				</label>
				<label class="radio-label">
					<input type="radio" name="radio2">
					<span class="checkmark">
						<span class="text">Short-term, open to long</span>	
						<span class="check"></span>							
					</span>
				</label>
				<label class="radio-label">
					<input type="radio" name="radio2">
					<span class="checkmark">
						<span class="text">Short-term fun</span>	
						<span class="check"></span>							
					</span>
				</label>
				<label class="radio-label">
					<input type="radio" name="radio2">
					<span class="checkmark">
						<span class="text">New friends</span>	
						<span class="check"></span>							
					</span>
				</label>
				<label class="radio-label">
					<input type="radio" name="radio2">
					<span class="checkmark">
						<span class="text">Still figuring it out</span>	
						<span class="check"></span>							
					</span>
				</label> -->
			</div>
        </div>
    </div>
	<!--  Relationship OffCanvas -->

  <!-- Dating Interest OffCanvas -->
  <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom4">
		<button type="button" class="btn-close drage-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		<div class="offcanvas-header share-style m-0 pb-0">
			<h6 class="title">Show Me</h6>
		</div>
        <div class="offcanvas-body">
			<div class="radio style-2">
				<label class="radio-label" v-for="(gender, index) in genders" :key="index">
					<input type="radio" v-model="datingInterest" :value="gender" :name="radio1"
          @click="getGender(gender)">
					<span class="checkmark">						
						<span class="text">{{ gender }}</span>
						<span class="check"></span>							
					</span>
				</label>
				<!-- <label class="radio-label">
					<input type="radio" name="radio2">
					<span class="checkmark">
						<span class="text">Men</span>
						<span class="check"></span>							
					</span>
				</label>
				<label class="radio-label">
					<input type="radio" name="radio2">
					<span class="checkmark">
						<span class="text">Other</span>	
						<span class="check"></span>							
					</span>
				</label> -->
			</div>
        </div>
    </div>
  <!-- Dating Interest Off Canvas -->
	
  <!-- Footer -->
	<!-- <ButtonFooter next="/feed" label="Apply"></ButtonFooter> -->
	<ButtonFooter :onClick="handleApplyDatingPreferences" label="Apply" :isFetching="isFetching"></ButtonFooter>
  <!-- <ButtonFooter :onClick="fetchHasUsername" label="Next" :isFetching="isFetching"></ButtonFooter> -->
	<!-- Footer -->
</div>  
</div>

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';
import ButtonFooter from './ButtonFooter.vue';
import TitledHeader from './TitledHeader.vue';
import store from '@/store';
import axios from 'axios';
import noUiSlider from 'nouislider';
import { updateUser, updateUserProfile } from '@/api';
import RelationshipGoal from './RelationshipGoal.vue';

@Component({
components: {
  ButtonFooter,
  PreLoader,
  TitledHeader
},
})
export default class Filters extends Vue {
  private userData: object = store.state.userData.userProfile;
  private relationshipGoals: object[] = store.state.relationshipGoals;
  private relationshipGoalName: string = '';
  private relationshipGoal: number = store.state.userData.userProfile.relationship_goals_id;
  private gender: string = store.state.userData.userProfile.dating_interest;
  private genderName: string = '';
  private datingInterest: string = '';
  private age_preference: number = 0;
  private isFetching: boolean = false;
  private user_id: number = 0;

  


  data() {
    interface RelationshipGoal {
      id: number;
      name: string;
      description: string;
    }
    const relationshipGoals: RelationshipGoal[] = store.state.relationshipGoals;

    // const relationshipGoalName: object | undefined = store.state.relationshipGoals.find((item: object) => item.id === store.state.userData.userProfile.relationship_goals_id);
    const goalWithIdOne: RelationshipGoal | undefined = relationshipGoals.find(goal => goal.id === store.state.userData.userProfile.relationship_goals_id);

    const genders = ['Women', 'Men', 'Other'];
    
    return {
      datingInterest: store.state.userData.userProfile.dating_interest,
      relationshipGoal: store.state.userData.userProfile.relationship_goals_id,
      relationshipGoals: store.state.relationshipGoals,
      genders: genders,
      genderName: store.state.userData.userProfile.dating_interest,
      relationshipGoalName: goalWithIdOne?.name,
      radio1: 'datingInterestGroup',
      radio2: 'relationshipGoalsGroup',
      age_preference: store.state.userData.userProfile.age_preference ?? 50,
      user_id: store.state.userData.id,
      isFetching: false

    }
  }

  

  getRelationshipGoal(goal: string)
  {
    this.relationshipGoalName = goal;
  }

  getGender(gender: string){
    this.genderName = gender;
  }

  mounted() {
    if (this.relationshipGoals.length < 1){
      this.fetchRelationshipGoals();
    }

    let slider = this.$refs.sliderElement as any;

    noUiSlider.create(slider, {
        start: [18, this.age_preference], // Initial slider values
        connect: true,
        range: {
          'min': 18,
          'max': 100
        }
      });

    // Get slider values
    slider.noUiSlider.on('update', (values: number[], handle: number, unencoded: number[], tap: boolean, positions: number[]) => {
      // values is an array containing the slider values
      this.age_preference = Math.round(values[1]);
    });
  }

  fetchRelationshipGoals() {
      // Make an Axios GET request to your API endpoint

      axios.get(process.env.VUE_APP_API_HOST + '/relationship-goals')
        .then(response => {
          // Update the 'relationshipGoals' array with the response data
          this.$store.commit('setRelationshipGoals', response.data.items)
          this.relationshipGoals = response.data.items;
          console.log(JSON.stringify(response.data));
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        })
    }

  handleApplyDatingPreferences(){

    this.isFetching=true;

    const newData = {
      'dating_interest': this.datingInterest,
      'relationship_goals_id': this.relationshipGoal,
      'age_preference': this.age_preference
    }

    this.putUpdateUserProfile(this.user_id, newData);
  }

  putUpdateUserProfile(id:number, newData:object) {
    updateUserProfile(id, newData)
      .then(response => {
        if (response.data) {
          this.$store.commit('setUserData', response.data);
        }
        this.isFetching = false;
      })
      .catch(error => {
      console.error("Error updating changes: ", error);
        this.isFetching = false;
      });
  }
  
}
</script>
