<template>
  <div class="chatlist">
    <ChatList></ChatList>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ChatList from '@/components/ChatList.vue';

@Component({
components: {
  ChatList,
},
})
export default class ChatListView extends Vue {}
</script>
