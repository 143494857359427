<template>
  <div class="add-pics">
    <Pics></Pics>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Pics from '@/components/Pics.vue';

@Component({
components: {
  Pics,
},
})
export default class FirstNameView extends Vue {}
</script>
