<template>
  <div class="page-wrapper">

    <!-- Preloader -->
    <!-- <div id="preloader">
      <div class="loader">
        <div class="load-circle"><div></div><div></div></div>
      </div>
    </div> -->
    <!-- Preloader end-->
    <PreLoader></PreLoader>

    <!-- Page Content Start -->
    <div class="page-content">
      <div class="container">
        <div class="main-banner mb-3">
          <div class="banner-content-2">
            <img class="dz-logo" src="assets/images/logo.png" alt="/">
            <h2 class="title">Shay Buna Dating</h2>
            <p>Where Connections Embrace Culture and Diversity
            </p>
          </div>
          <div class="bg-shape">
            <img class="shape-light" src="assets/images/welcome/bubble.png" alt="">
            <img class="shape-dark" src="assets/images/welcome/bubble-dark.png" alt="">
          </div>
          <img class="heart-image img-1" src="assets/images/heart-shape.png" alt="">
          <img class="heart-image img-2" src="assets/images/heart-shape.png" alt="">
          <img class="heart-image img-3" src="assets/images/heart-shape.png" alt="">
        </div>
        <div class="info-wrapper">
          <!-- <div class="info-box me-2">
            <h3 class="title me-1">3+</h3>
            <p>Pre Build App</p>
          </div> -->
          <div class="info-box">
            <a class="info-box" href="/login">
            <!-- <img src="assets/images/welcome/pwa.svg" alt="/"> -->
            <h3 class="title me-1">Get Started</h3>
            </a>
          </div>
        </div>
<!--         
        <div class="title-bar">
          <div class="d-flex align-items-center gap-2">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.7729 9.80504V6.77304C15.7729 4.68904 14.0839 3.00004 12.0009 3.00004C9.91694 2.99104 8.21994 4.67204 8.21094 6.75604V6.77304V9.80504" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7422 21.5003H7.25778C4.90569 21.5003 3 19.5953 3 17.2453V11.7293C3 9.37933 4.90569 7.47433 7.25778 7.47433H16.7422C19.0943 7.47433 21 9.37933 21 11.7293V17.2453C21 19.5953 19.0943 21.5003 16.7422 21.5003Z" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            <h5 class="title mb-0">Features</h5>
          </div>
        </div>
        <div class="demo-area demo-left demo1">
          <div class="row g-2 mb-4">
            <div class="col-6">
              <div class="package-card">
                <a href="home.html">
                  <img src="assets/images/welcome/media/demo-1/home.png" class="border rounded" alt="/">    
                </a>
                <h6 class="mb-0 font-14 mt-2 p-2">Shay Buna Dating</h6>	
              </div>
            </div>
            <div class="col-6">
              <div class="app-info">
                <h4 class="product-title">Shay Buna Dating</h4>
                <ul class="feature-list mb-3">
                  <li>
                    <i class="fa-solid fa-star"></i>
                    <span>Create Authentic Profiles</span>
                  </li>
                  <li>
                    <i class="fa-solid fa-star"></i>
                    <span>Connect Spontaneously</span>
                  </li>
                  <li>
                    <i class="fa-solid fa-star"></i>
                    <span>Swipe for Compatibility</span>
                  </li>
                  <li>
                    <i class="fa-solid fa-star"></i>
                    <span>Start Conversations Easily</span>
                  </li>
                  <li>
                    <i class="fa-solid fa-star"></i>
                    <span>Audio Calls</span>
                  </li>
                  <li>
                    <i class="fa-solid fa-star"></i>
                    <span>Face-to-Face Conversations</span>
                  </li>
                </ul>
                <a href="home.html" class="btn btn-primary btn-sm">View Demo</a>
              </div>	
            </div>
          </div>
          <div class="swiper-btn-center-lr mt-0">
            <div class="swiper package-swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-1/pic1.png" alt="/">    
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-1/pic2.png" alt="/">    
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-1/pic3.png" alt="/">    
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-1/pic4.png" alt="/">    
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-1/pic5.png" alt="/">    
                  </div>
                </div>
              </div>
            </div>
          </div>	
        </div> -->
        <!--
        <div class="demo-area demo-right demo2">
          <div class="row g-2 mb-4">
            <div class="col-6">
              <div class="app-info">
                <h4 class="product-title">W3Tinder App</h4>
                <ul class="feature-list mb-3">
                  <li>
                    <span>Swiper Card</span>
                    <i class="fa-solid fa-star"></i>
                  </li>
                  <li>
                    <span>Chat Screen</span>
                    <i class="fa-solid fa-star"></i>
                  </li>
                  <li>
                    <span>Profile Screen</span>
                    <i class="fa-solid fa-star"></i>
                  </li>
                  <li>
                    <span>Setting Screen</span>
                    <i class="fa-solid fa-star"></i>
                  </li>
                  <li>
                    <span>Dark Mode</span>
                    <i class="fa-solid fa-star"></i>
                  </li>
                </ul>
                <a href="w3tinder/home.html" class="btn btn-primary btn-sm">View Demo</a>
              </div>	
            </div>
            <div class="col-6">
              <div class="package-card">
                <a href="w3tinder/home.html">
                  <img src="assets/images/welcome/media/demo-2/home.png" class="border rounded" alt="/">    
                </a>
                <h6 class="mb-0 font-14 mt-2 p-2">W3Tinder App</h6>	
              </div>
            </div>
          </div>
          <div class="swiper-btn-center-lr mt-0">
            <div class="swiper package-swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-2/pic1.png" alt="/">    
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-2/pic2.png" alt="/">    
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-2/pic3.png" alt="/">    
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-2/pic4.png" alt="/">    
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-2/pic5.png" alt="/">    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="demo-area demo-left demo3">
          <div class="row g-2 mb-4">
            <div class="col-6">
              <div class="package-card">
                <a href="w3badoo/home.html">
                  <img src="assets/images/welcome/media/demo-3/home.png" class="border rounded" alt="/">    
                </a>
                <h6 class="mb-0 font-14 mt-2 p-2">W3badoo App</h6>	
              </div>
            </div>
            <div class="col-6">
              <div class="app-info">
                <h4 class="product-title">W3badoo App</h4>
                <ul class="feature-list mb-3">
                  <li>
                    <i class="fa-solid fa-star"></i>
                    <span>Swiper card</span>
                  </li>
                  <li>
                    <i class="fa-solid fa-star"></i>
                    <span>Chat Screen</span>
                  </li>
                  <li>
                    <i class="fa-solid fa-star"></i>
                    <span>Profile Screen</span>
                  </li>
                  <li>
                    <i class="fa-solid fa-star"></i>
                    <span>Setting Screen</span>
                  </li>
                  <li>
                    <i class="fa-solid fa-star"></i>
                    <span>Dark Mode</span>
                  </li>
                </ul>
                <a href="w3badoo/home.html" class="btn btn-primary btn-sm">View Demo</a>
              </div>	
            </div>
          </div>
          <div class="swiper-btn-center-lr mt-0">
            <div class="swiper package-swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-3/pic1.png" alt="/">    
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-3/pic2.png" alt="/">    
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-3/pic3.png" alt="/">    
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-3/pic4.png" alt="/">    
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="dz-media">
                    <img src="assets/images/welcome/media/demo-3/pic5.png" alt="/">    
                  </div>
                </div>
              </div>
            </div>
          </div>	
        </div>
        <div class="feature-wrapper m-b20">			
          <div class="title-bar mb-0">
            <div class="left-content">
              <i class="flaticon flaticon-star-1"></i>
              <h5 class="text-white title mb-0">Features</h5>
            </div>
            <h3 class="title mb-0 font-w600 text-primary">50+ <span>Elements</span></h3>
          </div>
          <div class="swiper-btn-center-lr mt-0">
            <div class="swiper tag-group tag-swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <a href="components/ui-accordion.html" class="tag-btn">Accordion</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-alert.html" class="tag-btn">Alerts</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-avatar.html" class="tag-btn">Avatar</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-badge.html" class="tag-btn">Badges</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-breadcrumb.html" class="tag-btn">Breadcrumb</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-button.html" class="tag-btn">buttons</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-button-group.html" class="tag-btn">Button Group</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-card.html" class="tag-btn">Cards</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-inputs.html" class="tag-btn">Inputs</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-dropdown.html" class="tag-btn">Dropdown</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-collapse.html" class="tag-btn">Collapse</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-radio.html" class="tag-btn">radio buttons</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-timeline.html" class="tag-btn">Timeline</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-social.html" class="tag-btn">Social</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-list-group.html" class="tag-btn">List group</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-modal.html" class="tag-btn">Modals</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-tab.html" class="tag-btn">Tabs</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-switch.html" class="tag-btn">Switch</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-divider.html" class="tag-btn">Divider</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-stepper.html" class="tag-btn">steppers</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-offcanvas.html" class="tag-btn">Offcanvas</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-pagination.html" class="tag-btn">Pagination</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-placeholder.html" class="tag-btn">Placeholder</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-progress.html" class="tag-btn">Progressbar</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-popover.html" class="tag-btn">Popovers</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-scrollspy.html" class="tag-btn">Scrollspy</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-spinners.html" class="tag-btn">Spinner</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-toast.html" class="tag-btn">toast</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-typography.html" class="tag-btn">Typography</a>                                    
                </div>
                <div class="swiper-slide">
                  <a href="components/ui-lightgallery.html" class="tag-btn">Lightgallery</a>                                    
                </div>
              </div>
            </div>					
          </div>
        </div>
        -->
        <!-- <h4 class="title-head text-center mb-3">Discover, Connect, Flourish</h4>
        <div class="detail-wrapper">
          <div class="detail-item">
            <i class="flaticon flaticon-magnifying-glass"></i>
            <h6 class="title">Create Your Profile</h6>
          </div>
          <div class="detail-item mid-content">
            <i class="fa-solid fa-plus"></i>
            <h6 class="title">Explore Connections</h6>
          </div>
          <div class="detail-item">
            <i class="fa-solid fa-border-all"></i>
            <h6 class="title">Foster Meaningful Bonds </h6>
          </div>
        </div>
        <div class="client-area" style="background-image: url(assets/images/welcome/client.png);">
          <div class="d-flex align-items-center mb-2">
            <svg class="me-1" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.8877 11.3967C19.2827 11.2007 20.3567 10.0047 20.3597 8.5557C20.3597 7.1277 19.3187 5.9437 17.9537 5.7197" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19.7285 14.7503C21.0795 14.9523 22.0225 15.4253 22.0225 16.4003C22.0225 17.0713 21.5785 17.5073 20.8605 17.7813" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8867 15.1638C8.67273 15.1638 5.92773 15.6508 5.92773 17.5958C5.92773 19.5398 8.65573 20.0408 11.8867 20.0408C15.1007 20.0408 17.8447 19.5588 17.8447 17.6128C17.8447 15.6668 15.1177 15.1638 11.8867 15.1638Z" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8869 12.3879C13.9959 12.3879 15.7059 10.6789 15.7059 8.56889C15.7059 6.45989 13.9959 4.74989 11.8869 4.74989C9.7779 4.74989 8.0679 6.45989 8.0679 8.56889C8.0599 10.6709 9.7569 12.3809 11.8589 12.3879H11.8869Z" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.88484 11.3967C4.48884 11.2007 3.41584 10.0047 3.41284 8.5557C3.41284 7.1277 4.45384 5.9437 5.81884 5.7197" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.044 14.7503C2.693 14.9523 1.75 15.4253 1.75 16.4003C1.75 17.0713 2.194 17.5073 2.912 17.7813" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <h4 class="title-head text-center">Happy Customers</h4>
          </div>
          <p class="sub-title">Over 10,000 people have met their soulmates, and we're always happy to see the positive impact we have had on your love life! Thank you!</p>
          <div class="swiper-btn-center-lr my-0">
            <div class="swiper client-swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="client-review-bx card">
                    <div class="card-body">
                      <div class="media justify-content-start media-40 rounded-circle mb-2">
                        <img class="me-2" src="assets/images/welcome/client/pic1.png" alt="/">
                        <div class="content">
                          <h6 class="mb-0 font-14">Kyunggon</h6>
                          <ul>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>		
                          </ul>
                        </div>	
                      </div>
                      <p class="client-review">
                        I found someone who shares my cultural values! Shay Buna Dating made it easy.
                      </p>	
                    </div>                                 
                  </div>                                 
                </div>
                <div class="swiper-slide">
                  <div class="client-review-bx card">
                    <div class="card-body">
                      <div class="media justify-content-start media-40 rounded-circle mb-2">
                        <img class="me-2" src="assets/images/welcome/client/pic2.png" alt="/">
                        <div class="content">
                          <h6 class="mb-0 font-14">saiareti1234</h6>
                          <ul>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>		
                          </ul>
                        </div>	
                      </div>
                      <p class="client-review">
                        Amazing platform for genuine connections. Highly recommended! 
                      </p>
                    </div>                                 
                  </div>                                 
                </div>
                <div class="swiper-slide">
                  <div class="client-review-bx card">
                    <div class="card-body">
                      <div class="media justify-content-start media-40 rounded-circle mb-2">
                        <img class="me-2" src="assets/images/welcome/client/pic1.png" alt="/">
                        <div class="content">
                          <h6 class="mb-0 font-14">saiareti1234</h6>
                          <ul>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>		
                          </ul>
                        </div>	
                      </div>
                      <p class="client-review">
                        I've met fascinating people from diverse backgrounds. Shay Buna Dating celebrates diversity like no other platform.
                      </p>
                    </div>                                 
                  </div>                                 
                </div>
                <div class="swiper-slide">
                  <div class="client-review-bx card">
                    <div class="card-body">
                      <div class="media justify-content-start media-40 rounded-circle mb-2">
                        <img class="me-2" src="assets/images/welcome/client/pic2.png" alt="/">
                        <div class="content">
                          <h6 class="mb-0 font-14">saiareti1234</h6>
                          <ul>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>
                            <li>
                              <i class="icon feather icon-star-on"></i>													
                            </li>		
                          </ul>
                        </div>	
                      </div>
                      <p class="client-review">
                        Shay Buna Dating helped me find someone who shares my passions. The personalized experience is truly remarkable!
                      </p>
                    </div>                                 
                  </div>                                 
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="card purchase-card text-center">
          <div class="card-body">
            <h5 class="text-white font-20">Get Shay Buna Plus</h5>
            <h6 class="text-primary">Quality and Premium Features for You</h6>
            <p>Get Unlimited Likes, Boosts, Subscriptions and More!</p>
            <a href="javascript:void(0);" class="btn btn-white rounded-md">Buy now - <span class="text-primary">$21</span></a>
          </div>
        </div>
        <div class="contact-area text-center">
          <img class="dz-logo" src="assets/images/logo.png" alt="/">
          <div class="support-box">
            <p>Embark on a journey to find your perfect match with our revolutionary dating app. Join us and let your heart beat to the rhythm of a new beginning.</p>
          </div>
          <ul class="social-icon">
            <li>
              <!-- <a href="https://www.facebook.com/w3itexperts/"> -->
              <a href="#">
                <i class="fa-brands fa-facebook-f"></i>
              </a>	
            </li>
            <li>
              <!-- <a href="https://twitter.com/w3itexpert"> -->
              <a href="#">
                  <i class="fa-brands fa-twitter"></i>
              </a>	
            </li>
            <li>
              <!-- <a href="https://www.youtube.com/channel/UCs7cFherrz5jhcvUJ7s9MQg"> -->
              <a href="#">
                  <i class="fa-brands fa-youtube"></i>
              </a>	
            </li>
            <li>
              <!-- <a href="https://in.linkedin.com/company/w3itexperts"> -->
              <a href="#">
                  <i class="fa-brands fa-linkedin"></i>
              </a>	
            </li>
            <li>
              <!-- <a href="https://www.instagram.com/dexignzone/"> -->
              <a href="#">
                  <i class="fa-brands fa-instagram"></i>
              </a>	
            </li>
          </ul>
        </div>
      </div>
    </div>

    
      <!-- Menubar -->
      <!--
      <div class="menubar-area style-2 footer-fixed">
        <div class="toolbar-inner menubar-nav">
          <a href="components/components.html" class="nav-link">
            <i class="icon feather icon-heart-on"></i>
            <span>Features</span>
          </a>
          <a href="media.html" class="nav-link">
            <i class="fa-solid fa-image"></i>
            <span>Media</span>
          </a>
          <a href="index.html" class="nav-link active">
            <i class="fa-solid fa-house"></i>
            <span>Home</span>
          </a>
          <a href="pages.html" class="nav-link">
            <i class="icon feather icon-grid"></i>
            <span>Pages</span>
          </a>
          <a href="home-setting.html" class="nav-link">
            <i class="fa-solid fa-gear"></i>
            <span>Settings</span>
          </a>
        </div>
      </div>
      -->
      <!-- Menubar -->

      <!-- PWA Offcanvas -->
      <div class="offcanvas offcanvas-bottom pwa-offcanvas">
        <div class="container">
          <div class="offcanvas-body small">
            <img class="logo" src="assets/images/logo.png" alt="">
            <h5 class="title">Shay Buna Dating</h5>
            <p class="pwa-text">Install "Shay Buna Dating" to your home screen for easy access, just like any other app</p>
            <button type="button" class="btn btn-sm btn-primary pwa-btn">Add to Home Screen</button>
            <button type="button" class="btn btn-sm pwa-close btn-info ms-2 text-white">Maybe later</button>
          </div>
        </div>
      </div>
      <div class="offcanvas-backdrop pwa-backdrop"></div>
      <!-- PWA Offcanvas End --> 
    </div>  
  

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';

@Component({
components: {
  PreLoader
},
})
export default class Home extends Vue {
}
</script>

<style>
</style>