<template>
  <!-- <div
    v-if="visible"
    class="position-fixed mb-2 top-3 end-1 toast toast-danger style-1 fade show"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    data-bs-delay="3982"
    data-bs-autohide="false"
  >
    <div class="toast-header">
      <strong class="me-auto ms-2">Message</strong>
      <button
        class="btn btn-close position-relative p-1"
        type="button"
        @click="hideToast"
        aria-label="Close"
      >
        <i class="icon feather icon-x"></i>
      </button>
    </div>
    <div class="toast-body">
      {{ message }}
    </div>
  </div> -->

  <div v-if="visible" class="position-fixed top-1 end-0 toast style-1 fade toast-danger mb-2 show" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3982" data-bs-autohide="false">
    <div class="toast-body">
      <i width="20" height="20" viewBox="0 0 16 16" fill="currentColor" class="fa-solid fa-circle-exclamation me-3"></i>
      <div class="toast-content ms-3 me-2">
        <div class="d-block"> Message: </div>
        <strong>{{ message }}</strong>
      </div>
      <button class="btn btn-close position-absolute p-1" type="button" @click="hideToast" aria-label="Close"><i class="icon feather icon-x"></i></button>                        
    </div>
  </div>
</template>

<!-- <script lang="ts">

export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hideToast() {
       this.$emit('update:visible', false);
     },
  },
};
</script> -->

<script lang="ts">

import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
@Component({
  components: {

  }
})
export default class Toast extends Vue {
  @Prop({ type: String, required: true }) message!: string;
  @Prop({ type: Boolean, required: true }) visible!: boolean;

  @Emit('update:visible')
  hideToast() {
    return false;
  }
}
</script>

<style>
.toast-container {
  z-index: 1050;
}
</style>