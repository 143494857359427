import axios from 'axios';

export const fetchRelationshipGoals = () => {

};

// USERS

export const getUserDataByPhone = (phone_no: string) => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/users?expand=userProfile,profileLanguages,profileLanguages.language,profileInterests,profileInterests.interest&phone_no=${phone_no}`);
}

export const getUserDataByUsername = (username: string) => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/users?expand=userProfile,profileLanguages,profileLanguages.language,profileInterests,profileInterests.interest&username=${username}`);
}

export const getUserDataByEmail = (email: string) => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/users?expand=userProfile,profileLanguages,profileLanguages.language,profileInterests,profileInterests.interest&email=${email}`);
}

export const getUserDataByID = (id: number) => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/users?expand=userProfile,profileLanguages,profileLanguages.language,profileInterests,profileInterests.interest&id=${id}`);
}

export const updateUser = (id: number, newData: object) => {
  return axios.put(`${process.env.VUE_APP_API_HOST}/users/${id}`, newData);
};

export const postLogin = (credentials: object) => {
  return axios.post(`${process.env.VUE_APP_API_HOST}/login`, credentials);
};

// USER PROFILE

export const updateUserProfile = (id: number, newData: object) => {
  return axios.put(`${process.env.VUE_APP_API_HOST}/user-profiles/${id}`, newData);
};

export const getUsersFeed = (id: number, relationship_goals_id: number, dating_interest: string, age_preference: number, page: number = 0) => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/users?expand=userProfile&exclude_id=${id}&dating_interest=${dating_interest}&relationship_goals_id=${relationship_goals_id}&age_preference=${age_preference}&for_feed=true` + (page ? `&page=${page}` : ''));
};

// LANGUAGES

export const getSearchLanguages = (name: string = '') => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/languages?name=${name}`);
}

// INTERESTS

export const getSearchInterests = (name: string = '') => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/interests?name=${name}`);
}

// PROFILE INTERESTS

export const storeProfileInterests = (data: object) => {
  return axios.post(`${process.env.VUE_APP_API_HOST}/profile-interests`, data);
}

export const getProfileInterests = (id: number) => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/profile-interests?expand=interest&user_id=${id}`);
}

export const deleteProfileInterests = (id:number) => {
  return axios.delete(`${process.env.VUE_APP_API_HOST}/profile-interests/${id}`);
}

// PROFILE LANGUAGES

export const getProfileLanguages = (id: number) => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/profile-languages?expand=language&user_id=${id}`);
}

export const storeProfileLanguages = (data: object) => {
  return axios.post(`${process.env.VUE_APP_API_HOST}/profile-languages`, data);
}

export const deleteProfileLanguages = (id:number) => {
  return axios.delete(`${process.env.VUE_APP_API_HOST}/profile-languages/${id}`);
}

// CONVERSATIONS

export const getUserConversationList = (id:number) => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/conversations?expand=users.userProfile,messagesSent&users.id=${id}`);
  
}

export const getUserConversation = (slug: string) => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/conversations?expand=users.userProfile,messagesSent&slug=${slug}`);
}

// MESSAGES SENT

export const getChatMessagesSent= (slug: string, page: number = 0) => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/messages-sents?expand=conversation,sender.userProfile&conversation.slug=${slug}` + (page ? `&page=${page}` : ''));
}

// SUPER LIKE QUOTA

export const updateIncrementSuperLikeUsedQuota = (data: object) => {
  return axios.post(`${process.env.VUE_APP_API_HOST}/super-like-quota/increment-used-quota`, data);
}

export const storeDailySuperLikeQuota = (data: object) => {
  return axios.post(`${process.env.VUE_APP_API_HOST}/super-like-quotas`, data);
}


// USER SWIPES

export const storeUserSwipe = (data: object) => {
  return axios.post(`${process.env.VUE_APP_API_HOST}/user-swipes`, data);
}

export const getSuperLikers = (id: number) => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/user-swipes?expand=swiperUser,swiperUser.userProfile&swipee_user_id=${id}&swipe_description=Super Liked&exclude_matched=true`);
}

export const storeUserSwipeCheckDateMatch = (data: object) => {
  return axios.post(`${process.env.VUE_APP_API_HOST}/user-swipe/check-date-match`, data);
}

export const getHasUserSwipe = (swiper_id: number, swipee_id: number) => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/user-swipes?swipee_user_id=${swipee_id}&swiper_user_id=${swiper_id}`);
}

// CHAT SOCKET PORT
export const getChatSocketPort = () => {
  return axios.get(`${process.env.VUE_APP_API_HOST}/chat-socket/port`);
}


