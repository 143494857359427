<template>
  <div class="menubar-header">
    <!-- Header -->
		<header class="header header-fixed border-0">
			<div class="container">
				<div class="header-content">
					<div class="left-content">
						<a href="javascript:void(0);" class="menu-toggler" @click="toggleMenu">
							<i class="icon feather icon-grid"></i>
						</a>
					</div>
					<div class="mid-content header-logo">
						<a href="/feed">
							<img src="/assets/images/logo.png" alt="">
						</a>
					</div>
					<div class="right-content dz-meta">
						<a href="/filters" class="filter-icon">
							<i class="flaticon flaticon-settings-sliders"></i>
						</a>
					</div>
				</div>
			</div>
		</header>
	<!-- Header -->
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MenuBarHeader extends Vue {
  @Prop() private var!: string;

  toggleMenu() {
    this.$emit('toggle-menu');
  }

  mounted() {
    // Menubar Toggler ============
  //   var handleMenubar = function() {
  //       jQuery('.menu-toggler').on('click',function(){
	// 		jQuery('.sidebar').toggleClass('show');
	// 		jQuery('.menu-toggler').toggleClass('show');
	// 		jQuery('.dark-overlay').toggleClass('active');
	// 	});
	// 	jQuery('.dark-overlay').on('click',function(){
	// 		jQuery('.menu-toggler,.sidebar').removeClass('show');
	// 			jQuery(this).removeClass('active');
	// 	});
	// 	jQuery('.nav-color').on('click',function(){
	// 		jQuery('.dark-overlay').removeClass('active');
	// 	});
	// }

  // handleMenubar();
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
