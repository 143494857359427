<template>
  <div class="bg-white">
<div class="page-wrapper">
    
	<!-- Preloader -->
  <PreLoader></PreLoader>
    <div v-if="is_loading" class="spinner-overlay">
                  
      <div class="preloader">
        <!-- Preloader -->
          <div id="preloader" ref="preloader">
            <div class="loader">
              <div class="load-circle"><div></div><div></div></div>
            </div>
          </div>
        <!-- Preloader end-->
      </div>

    </div>
    <!-- Preloader end-->
	
	<!-- Header -->
		<TitledHeader title="Recommendation"></TitledHeader>
    <Toast :message="toastMessage" :visible.sync="isToastMessageVisible" />
	<!-- Header -->
	
	<!-- Page Content Start -->
	<div class="page-content space-top p-b40">
		<div class="container">
			<div class="detail-area">
				<div class="dz-media-card style-2">
					<div class="dz-media">
						<img :src="userImgSource" alt="" @load="imageLoaded">
					</div>
					<div class="dz-content">
						<div class="left-content">
							<h4 class="title">{{ nameAge }}</h4>
							<p class="mb-0"><i class="icon feather icon-map-pin"></i> {{ location }}</p>
						</div>
						<!-- <a href="javascript:void(0);" class="dz-icon"><i class="flaticon flaticon-star-1"></i></a> -->
					</div>
				</div>
				<div class="detail-bottom-area">
					<div class="about">
						<h6 class="title">Profile Description</h6>
            <div v-if="profile_description">
              <p class="para-text">
                {{ profile_description }}
              </p>					
              
            </div>
            <div v-else>
              <span> No Profile Description set</span>
            </div>
					</div>
					<div class="intrests mb-3">
						<h6 class="title">Interests</h6>
            <div v-if="listProfileInterests.length > 0">

              <ul class="dz-tag-list">
                <li v-for="interest in listProfileInterests" :key="interest.id"> 
                  <div class="dz-tag">
                    <i :class="['icon', 'feather', `icon-${interest.icon}`]" ></i>
                    <span> {{ interest.name }}</span>
                  </div>
                </li> 
  
                <!-- <li> 
                  <div class="dz-tag">
                    <i class="icon feather icon-camera"></i>
                    <span>Photography</span>
                  </div>
                </li>
                <li> 
                  <div class="dz-tag">
                    <i class="icon feather icon-music"></i>
                    <span>Music</span>
                  </div>
                </li>
                <li> 
                  <div class="dz-tag">
                    <i class="icon feather icon-book"></i>
                    <span>Study</span>
                  </div>
                </li>
                <li> 
                  <div class="dz-tag">
                    <i class="icon feather icon-film"></i>
                    <span>Movies</span>
                  </div>
                </li>
                <li> 
                  <div class="dz-tag">
                    <i class="icon feather icon-instagram"></i>
                    <span>Instagram</span>
                  </div>
                </li>
                <li> 
                  <div class="dz-tag">
                    <i class="icon feather icon-map-pin"></i>
                    <span>Travelling</span>
                  </div>
                </li> -->
              </ul>
            </div>
            <div v-else>
              <h7> No Interests set </h7>
            </div>
					</div>
					<div class="languages mb-3">
						<h6 class="title">Languages</h6>
            <div v-if="listProfileLanguages.length > 0">

              <ul class="dz-tag-list">
                <li v-for="language in listProfileLanguages" :key="language.id"> 
                  <div class="dz-tag">
                    <span>{{language.name}}</span>
                  </div>
                </li>
                <!-- <li> 
                  <div class="dz-tag">
                    <span>English</span>
                  </div>
                </li>
                <li> 
                  <div class="dz-tag">
                    <span>Amharic</span>
                  </div>
                </li>
                <li> 
                  <div class="dz-tag">
                    <span>German</span>
                  </div>
                </li> -->
              </ul>
            </div>
            <div v-else>
              <h7> No languages set </h7>
            </div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Page Content End -->
	<!-- Menubar -->
	<div class="footer fixed">
    <div v-if="(current_user_data.id !== user_id)" class="dz-icon-box">
      <div v-if="!has_acted" class="dz-icon-box">
        <a @click="handleAction('Left')" class="icon dz-flex-box dislike"><i class="flaticon flaticon-cross font-18"></i></a>
        <a @click="handleAction('Up')" class="icon dz-flex-box super-like"><i class="fa-solid fa-star"></i></a>
        <a @click="handleAction('Right')" class="icon dz-flex-box like"><i class="fa-solid fa-heart"></i></a>
      </div>
    </div>
	</div>
	<!-- Menubar -->
</div>  
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import TitledHeader from '@/components/TitledHeader.vue';
import PreLoader from './PreLoader.vue';
import Toast from '@/components/Toast.vue';
import { getUserDataByID, storeUserSwipeCheckDateMatch, getHasUserSwipe } from '@/api'; 

@Component({
components: {
  TitledHeader,
  PreLoader,
  Toast
},
})
export default class ProfileDetail extends Vue {
  private userData: object = {};
  private user_id: number = 0;
  private profile_description: string = '';
  // private userImgSource: string = '/assets/images/slider/def-pics/pic1.png';
  private userImgSource: string = '';
  private name: string = '';
  private age: number = 0;
  private location: string = '';
  private listProfileInterests: object[] = [];
  private listProfileLanguages: object[] = [];
  private nameAge: string = '';
  private apiHost: string = '';
  private current_user_data: object = {};
  private toastMessage: string = '';
  private isToastMessageVisible: boolean = false;
  private has_acted: boolean = false;
  private is_loading: boolean = true;
  private is_imageloaded: boolean = false;
  private is_fetching: boolean = true;

  data() {
    
    return {
      current_user_data: this.$store.state.userData,
      user_id: parseInt(this.$route.params.id),
      apiHost: process.env.VUE_APP_API_HOST,
      profile_description: 'This is my Profile', // this.profileDescription
      // profile_description: {JSON.stringify(this.userData) }// (this.userData as any).userProfile?.profile_description
      has_acted: true,
      is_loading: true,
      is_imageloaded: false,
      is_fetching: true
    }
  }

  mounted() {
    const id = parseInt(this.$route.params.id);
    this.user_id = id;
    this.fetchUserData(id);
    this.current_user_data = this.$store.state.userData;
    this.fetchUserSwipeHasActed((this.current_user_data as any).id, this.user_id);
  }

  imageLoaded() {
    this.is_imageloaded = true;
    if (!this.is_fetching && this.is_imageloaded) {
      this.is_loading = false;
    }
  }

  fetchUserData(id: number) {
    getUserDataByID(id)
      .then(response => {
        this.userData = response.data.items[0];
        const userData = this.userData;
        const userProfile  = (this.userData as any).userProfile;
        this.profile_description = userProfile.profile_description;
        this.userImgSource = userProfile.profile_pic ? `${this.apiHost}/api/get-image?location=${userProfile.profile_pic}` :  '/assets/images/slider/def-pics/pic1.png';
        this.name = userProfile.name;
        this.age = this.calculateAge(userProfile.birthdate);
        this.nameAge = this.name != '' && this.age != 0 ? `${this.name}, ${this.age}` : '';
        this.location = userProfile.location;
        this.listProfileInterests = (this.userData as any).profileInterests.map((item:any) => item.interest);
        // alert(JSON.stringify(this.listProfileInterests));
        // alert(JSON.stringify( (this.userData as any).profileInterests ));
        this.listProfileLanguages = (this.userData as any).profileLanguages.map((item:any) => item.language);

        console.log(JSON.stringify(this.userData));
        this.is_fetching = false;

      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      })
  }
  
  fetchUserSwipeHasActed(swiper_id: number, swipee_id: number) {
    getHasUserSwipe(swiper_id, swipee_id)
      .then(response => {

        if (response.data.items.length <= 0) {
          console.log(JSON.stringify(response.data));
          this.has_acted = false;
        }
        else {
          this.has_acted = true;
        }

      })
      .catch(error => {
        console.error('Error fetching user swipes:', error);
      })
  }

  handleAction(action: string) {
    let description = '';
    switch(action){
      case 'Up':
        description = 'Super Liked';
        break;
      case 'Left':
        description = 'Rejected';
        break;
      case 'Right':
        description = 'Interested';
        break;
      default:
        description = '';
    }

    const newData = {
      'swiper_user_id': (this.current_user_data as any).id,
      'swipee_user_id': this.user_id,
      'swipe_direction': action,
      'swipe_description': description
    }

    storeUserSwipeCheckDateMatch(newData)
    .then(response => {
      if (response.data) {
        const data = response.data;
        if (data.can_super_like != undefined) {
          if (data.can_super_like == false) {
            this.toastMessage = data.message;
            this.isToastMessageVisible = true;

            return;
          }
        }

        if (data.dateMatch && data.dateMatch.status === 'Matched'){
            
          this.toastMessage = "We found You a Match!";
          this.isToastMessageVisible = true;

          return;
        }

        this.has_acted = true;

      }
    })
    .catch(error => {
      console.error("Error saving user swipe: ", error);
    })
  }

  calculateAge(birthdate: string) {
    const today = new Date();
      const birthDate = new Date(birthdate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }


      return age; 
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
