<template>
  <div class="birthdate">
    <BirthDate></BirthDate>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import BirthDate from '@/components/BirthDate.vue';

@Component({
components: {
  BirthDate,
},
})
export default class FirstNameView extends Vue {}
</script>
