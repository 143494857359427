<template>
  <div class="">
    <div class="page-wrapper">
    
    <!-- Preloader -->
    <PreLoader></PreLoader>
      <!-- Preloader end-->
    
    <!-- Header -->
      <TitledHeader title="Explore"></TitledHeader>
    <!-- Header -->
    
    <!-- Page Content Start -->
    <div class="page-content space-top p-b60">
      <div class="container">
        <div class="row g-3">

          <div v-if="isLoading" class="spinner-overlay">
                
            <div class="preloader">
              <!-- Preloader -->
                <div id="preloader" ref="preloader">
                  <div class="loader">
                    <div class="load-circle"><div></div><div></div></div>
                  </div>
                </div>
              <!-- Preloader end-->
            </div>

          </div>


          <div class="col-md-6 col-12">
            <div class="dz-media-card style-3">
              <div class="dz-media">
                <img src="assets/images/explore/pic1.png" alt="" @load="imageLoaded" @error="imageError">
              </div>
              <div class="dz-content">
                <h3 class="title">Free Tonight</h3>
                <p>Down for something spontaneous</p>
                <a href="/lobby/Free_Tonight_Lobby_05172024" class="btn btn-light rounded-xl">JOIN NOW</a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="dz-media-card style-3">
              <div class="dz-media">
                <img src="assets/images/explore/pic2.png" alt=""  @load="imageLoaded" @error="imageError">
              </div>
              <div class="dz-content">
                <h3 class="title">Looking for Love</h3>
                <p>Sweep me off my feet</p>
                <a href="/lobby/Looking_For_Love_Lobby_05172024" class="btn btn-light rounded-xl">JOIN NOW</a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="dz-media-card style-3">
              <div class="dz-media">
                <img src="assets/images/explore/pic3.png" alt=""  @load="imageLoaded"  @error="imageError">
              </div>
              <div class="dz-content">
                <h3 class="title">Let's be Friends</h3>
                <p>Maybe even besties</p>
                <a href="/lobby/Lets_Be_Friends_Lobby_05172024" class="btn btn-light rounded-xl">JOIN NOW</a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="dz-media-card style-3">
              <div class="dz-media">
                <img src="assets/images/explore/pic4.png" alt=""  @load="imageLoaded"  @error="imageError">
              </div>
              <div class="dz-content">
                <h3 class="title">Coffee Date</h3>
                <p>Take me to your favorite cafe</p>
                <a href="/lobby/Coffee_Date_Lobby_05172024" class="btn btn-light rounded-xl">JOIN NOW</a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="dz-media-card style-3">
              <div class="dz-media">
                <img src="assets/images/explore/pic5.png" alt=""  @load="imageLoaded"  @error="imageError">
              </div>
              <div class="dz-content">
                <h3 class="title">Foodies</h3>
                <p>Embrace your inner foodie</p>
                <a href="/lobby/Foodies_Lobby_05172024" class="btn btn-light rounded-xl">JOIN NOW</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Content End -->
    <!-- Menubar -->
    <MenuBarFooter></MenuBarFooter>
    <!-- Menubar -->
  </div> 
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import MenuBarFooter from '@/components/MenuBarFooter.vue';
import PreLoader from '@/components/PreLoader.vue';
import MenuBarHeader from './MenuBarHeader.vue';
import TitledHeader from './TitledHeader.vue';

@Component({
components: {
  MenuBarFooter,
  PreLoader,
  TitledHeader,
},
})
export default class Explore extends Vue {
  private imagesLoaded: number = 0;
  private totalImages: number = 0;
  private isLoading: boolean = true;

  imageLoaded() {
      // Check if all images are loaded
      // const allImagesLoaded = this.userList.every(profile => (profile as any).userProfile.profile_pic);
      // if (allImagesLoaded) {
      //   this.loading = false; // Set loading state to false when all images are loaded
      // }

      this.imagesLoaded += 1;
      // alert(`images loaded: ${this.imagesLoaded} >= totalImages: ${this.totalImages}`);
      if (this.imagesLoaded >= this.totalImages) {
        this.isLoading = false;
      }
    }

    imageError() {
      this.imagesLoaded += 1;
      if (this.imagesLoaded >= this.totalImages) {
        this.isLoading = false;
      }
    }

    data() {
      return {
        totalImages: 5
      }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
