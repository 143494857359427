<template>
  <div class="edit-profile">
    <EditProfile></EditProfile>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import EditProfile from '@/components/EditProfile.vue';

@Component({
components: {
  EditProfile,
},
})
export default class EditProfileView extends Vue {}
</script>
