<template>
  <div class="profile">
    <Profile></Profile>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Profile from '@/components/Profile.vue'; // @ is an alias to /src

@Component({
  components: {
    Profile,
  },
})
export default class ProfileView extends Vue {}
</script>
