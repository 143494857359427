<template>
  <div class="menubar-footer">
    <!-- Menubar -->
    <div class="menubar-area footer-fixed">
      <div class="toolbar-inner menubar-nav">
        <a href="/feed" class="nav-link" :class="{ active: isActive('/feed') }">
          <i class="flaticon flaticon-dog-house"></i>
        </a>
        <a href="/explore" class="nav-link" :class="{ active: isActive('/explore') }">
          <i class="flaticon flaticon-search"></i>
        </a>
        <a href="/wishlist" class="nav-link" :class="{ active: isActive('/wishlist') }">
          <i class="flaticon flaticon-heart"></i>
        </a>
        <a href="/chatlist" class="nav-link" :class="{ active: isActive('/chatlist') }">
          <i class="flaticon flaticon-chat-1"></i>
        </a>
        <a href="/profile" class="nav-link" :class="{ active: isActive('/profile') }">
          <i class="flaticon flaticon-user"></i>
        </a>
      </div>
    </div>
    <!-- Menubar -->
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MenuBarFooter extends Vue {
  @Prop() private var!: string;
  private activeLink: string = '';

  data() {
    return {
      activeLink: 'feed'
    }
  }

  setActiveLink(link: string) {
    this.activeLink = link;
  }

  isActive(route: string) {
      return this.$route.path === route;
    }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
