<template>
  <div class="filter">
    <Filters></Filters>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Filters from '@/components/Filters.vue';

@Component({
components: {
  Filters,
},
})
export default class FilterView extends Vue {}
</script>
