<template>
  <div class="bg-white">
<div class="page-wrapper">
      <PreLoader></PreLoader>

    <!-- <div v-if="loading"> -->
      <!-- Page Content -->
      <div class="page-content">
      <div class="container">
        <div class="account-area">
          <BackButton></BackButton>
          <!-- <a href="javascript:void(0);" class="back-btn dz-flex-box">
            <i class="icon feather icon-chevron-left"></i>
          </a> -->

          
          <Toast :message="toastMessage" :visible.sync="isToastMessageVisible" />

          <div class="section-head ps-0">
            <h3>Enter your email</h3>
          </div>
          <div class="mb-2 input-group input-group-icon input-mini">
            <div class="input-group-text">
              <div class="input-icon">
                <i class="icon feather icon-mail"></i>
              </div>
            </div>
            <input type="text" class="form-control" v-model="email" id ="email" placeholder="Enter email">								
          </div>
        </div>
          </div>
      </div>
      <!-- Page Content End -->
    <ButtonFooter :onClick="fetchHasEmail" label="Next" :isFetching="isFetching"></ButtonFooter>
    <!-- <Button @click="next">Next</Button> -->
    <!-- </div> -->
</div>
</div>

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';
import ButtonFooter from './ButtonFooter.vue';
import BackButton from './BackButton.vue';
import Toast from './Toast.vue';
import axios from 'axios';
import { getUserDataByEmail } from '@/api';


@Component({
components: {
  ButtonFooter,
  PreLoader,
  BackButton,
  Toast
},
})

export default class Email extends Vue {

  private email: string = '';
  private loading: boolean = true;
  private toastMessage: string = '';
  private isToastMessageVisible: boolean = false;
  private userData: object = {};
  private isFetching: boolean = false;

  private handleNextClick() {

    


    if (!this.email) {

      this.toastMessage = 'Field cannot be left blank';
      this.isToastMessageVisible = true;
      return;
    }
      this.$store.commit('setUserData', { email: this.email });
      this.$router.push('/location');
  }

  fetchHasEmail() {

  if (!this.email) {

    this.toastMessage = 'Email cannot be empty';
    this.isToastMessageVisible = true;
    return;
  }

  this.isFetching = true;

  getUserDataByEmail(this.email)
    .then(response => {
      
      if (response.data.items && response.data.items.length > 0) {

        this.toastMessage = 'Email Already taken';
        this.isToastMessageVisible = true;
        this.isFetching = false;
        return;
      } 

        this.$store.commit('setUserData', { email: this.email});
        this.$router.push('/location');
      
          
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }

  data() {
    this.userData = this.$store.state.userData;
    const user_userData = this.$store.state.userData;
    return {
      loading : true,
      email: user_userData.email ?? ''
      
    }
  }

  mounted() {
    // If your data is static, you can set loading to false directly in the mounted hook
    this.loading = false;
  }
  

};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
