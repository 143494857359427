<template>
  <div class="chat">
    <Lobby></Lobby>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Lobby from '@/components/Lobby.vue';

@Component({
components: {
  Lobby,
},
})
export default class LobbyView extends Vue {}
</script>
