<template>
  <div class="loader">
    <!-- Preloader -->
      <div id="preloader" ref="preloader">
        <div class="loader">
          <div class="load-circle">
            <div v-for="index in 10" ></div>
            <div></div>
          </div>
        </div>
      </div>
    <!-- Preloader end-->

    <!-- <div class="loading-screen">
      <div class="loading-spinner"></div>
      <p>Loading...</p>
    </div> -->
  </div>
</template>




<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import jQuery from 'jquery';

@Component
export default class PreLoader extends Vue {
  // showPreloader: boolean = true;

  // beforeRouteEnter(to: any, from: any, next: any) {
  //   next((vm: PreLoader) => {
  //     // Using vm (this) to access the component's instance
  //     vm.showPreloader = true;
  //   });
  // }

  // beforeRouteLeave(to: any, from: any, next: any) {
  //   // Hide the preloader before leaving the route
  //   this.showPreloader = false;
  //   next();
  // }

  // handlePreloader() {
  //     // Access the element using $refs
    
  //   const preloaderElement = this.$refs.preloader;

  //   // Check if the element exists before manipulating it
  //   if (preloaderElement) {
  //     setTimeout(function() {
  //       jQuery(preloaderElement).fadeOut(150000);
  //     }, 150000);
  //   }
  // }

  // mounted() {
  //   this.handlePreloader();
  // }   
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
