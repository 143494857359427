<template>
  <div class="relationship-goal">
    <RelationshipGoal></RelationshipGoal>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import RelationshipGoal from '@/components/RelationshipGoal.vue'; // @ is an alias to /src

@Component({
  components: {
    RelationshipGoal,
  },
})
export default class RelationshipGoalView extends Vue {}
</script>
