<template>
  <div class="bg-white">

  
  <div class="menubar-header">
     <!-- Header -->
     <header class="header header-fixed bg-white">
        <div class="container">
          <div class="header-content">
            <div class="left-content">
              <BackButton></BackButton>
              <!-- <a href="javascript:void(0);" @click="handleGoBack" class="back-btn">
                <i class="icon feather icon-chevron-left"></i>
              </a> -->
              <h6 class="title">{{ title }}</h6>
            </div>
            <div class="mid-content">
            </div>
            <div class="right-content">
            </div>
          </div>
        </div>
      </header>
    <!-- Header -->
  </div>
</div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import BackButton from '@/components/BackButton.vue';

@Component({
components: {
  BackButton
},
})
export default class TitledHeader extends Vue {
  @Prop() private title!: string;
  
  handleGoBack() {
    window.history.go(-1);
    return false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
