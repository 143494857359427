<template>
  <div class="bg-white">
    <div class="page-wrapper">
      <Loader v-if="loading"></Loader>
      <div v-else>
        <PreLoader />

        <!-- Page Content -->
        <div class="page-content">
          <div class="container">
            <div class="account-area">
              <BackButton/>

              <Toast :message="toastMessage" :visible.sync="isToastMessageVisible" />

              <div class="section-head ps-0">
                <h3>Please Enter your Password</h3>
              </div>
              <div class="mb-3 input-group input-group-icon ">
                <div class="input-group-text">
                  <div class="input-icon">
                    <i class="icon feather icon-lock"></i>
                  </div>
                </div>

                <input v-if="showPassword" type="text" class="form-control" v-model="password" id ="password" placeholder="Enter password">		
                <input v-else type="password" class="form-control" v-model="password" id ="password" placeholder="Enter password">
                
                <div class="input-group-text" @click="toggleShow">
                  <span class="input-icon is-right">
                    <i class="icon feather" :class="{ 'icon-eye-off': showPassword, 'icon-eye': !showPassword }"></i>
                  </span>
                </div>
                    
              </div>

              <div class="mb-3 input-group input-group-icon ">
                <div class="input-group-text">
                  <div class="input-icon">
                    <i class="icon feather icon-lock"></i>
                  </div>
                </div>

                <input v-if="showConfirmPassword" type="text" class="form-control" v-model="confirmPassword" id ="confirmPassword" placeholder="Confirm password">		
                <input v-else type="password" class="form-control" v-model="confirmPassword" id ="confirmPassword" placeholder="Confirm password">
                
                <div class="input-group-text" @click="toggleShowConfirm">
                  <span class="input-icon is-right">
                    <i class="icon feather" :class="{ 'icon-eye-off': showConfirmPassword, 'icon-eye': !showConfirmPassword }"></i>
                  </span>
                </div>
                    
              </div>
              
            </div>
          </div>
        </div>
        <ButtonFooter :onClick="handleNextClick" label="Next"></ButtonFooter>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';
import ButtonFooter from './ButtonFooter.vue';
import BackButton from './BackButton.vue';
import Loader from './Loader.vue';
import Toast from './Toast.vue';
import { getUserDataByUsername } from '@/api';


@Component({
components: {
  ButtonFooter,
  PreLoader,
  BackButton, 
  Toast,
  Loader
},
})


export default class Password extends Vue {

  private firstName: string = '';
  private loading: boolean = true;
  private toastMessage: string = '';
  private isToastMessageVisible: boolean = false;
  private userData: object = {};
  private password: string = '';
  private confirmPassword: string = '';
  private showPassword: boolean = false;
  private showConfirmPassword: boolean = false;
  


  private handleNextClick() {

    if (!this.password) {

      this.toastMessage = 'Password cannot be left blank';
      this.isToastMessageVisible = true;
      return;
    }

    if (this.password !== this.confirmPassword) {
      this.toastMessage = 'Passwords don\'t match';
      this.isToastMessageVisible = true;
      return;
    }

      this.$store.commit('setUserData', { password_hash: this.password });
      this.$store.commit('setUserData', { confirm_password: this.confirmPassword });
      this.$router.push('/phonenumber');
  }

  data() {
    this.userData = this.$store.state.userData;
    const user_userData = this.$store.state.userData;
    return {
      loading : true,
      password: user_userData?.password_hash ?? '',
      confirmPassword: user_userData?.confirm_password ?? '',
      showPassword: false,
      showConfirmPassword: false
    }
  }

  mounted() {
    // If your data is static, you can set loading to false directly in the mounted hook
    this.loading = false;
  }

  toggleShow() {
      this.showPassword = !this.showPassword;
  }

  toggleShowConfirm() {
      this.showConfirmPassword = !this.showConfirmPassword;
  }
  

};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
