<template>
  <div class="">
      <div class="page-wrapper">
        
        <!-- Preloader -->
        <PreLoader></PreLoader>
        <div v-if="loading" class="spinner-overlay">
            
            <div class="preloader">
              <!-- Preloader -->
                <div id="preloader" ref="preloader">
                  <div class="loader">
                    <div class="load-circle"><div></div><div></div></div>
                  </div>
                </div>
              <!-- Preloader end-->
            </div>

          </div>
          <!-- Preloader end-->
        
        <!-- Header -->
        <TitledHeader title="Matches"></TitledHeader>
        <!-- Header -->
        
        <!-- Page Content Start -->
        <div class="page-content space-top p-b60">
        <div class="container">

          <!-- <div class="swiper chat-swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <a href="/chat" class="recent active">								
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic14.jpg" alt="">
                  </div>
                  <span>Alem</span>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="/chat" class="recent">	
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic2.jpg" alt="">
                  </div>
                  <span>Aster</span>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="/chat" class="recent active">	
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic3.jpg" alt="">
                  </div>
                  <span>Elisha</span>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="/chat" class="recent active">	
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic4.jpg" alt="">
                  </div>
                  <span>Hiwot</span>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="/chat" class="recent active">	
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic5.jpg" alt="">
                  </div>
                  <span>Stephon</span>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="/chat" class="recent">	
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic4.jpg" alt="">
                  </div>
                  <span>Selam</span>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="/chat" class="recent">	
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic5.jpg" alt="">
                  </div>
                  <span>Makeda</span>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="/chat" class="recent">	
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic5.jpg" alt="">
                  </div>
                  <span>Makeda</span>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="/chat" class="recent">	
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic5.jpg" alt="">
                  </div>
                  <span>Makeda</span>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="/chat" class="recent">	
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic5.jpg" alt="">
                  </div>
                  <span>Makeda</span>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="/chat" class="recent">	
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic5.jpg" alt="">
                  </div>
                  <span>Makeda</span>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="/chat" class="recent">	
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic5.jpg" alt="">
                  </div>
                  <span>Makeda</span>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="/chat" class="recent">	
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic5.jpg" alt="">
                  </div>
                  <span>Makeda</span>
                </a>
              </div>
              <div class="swiper-slide">
                <a href="/chat" class="recent">	
                  <div class="media media-60 rounded-circle">
                    <img src="assets/images/user/pic5.jpg" alt="">
                  </div>
                  <span>Makeda</span>
                </a>
              </div>
            </div>
          </div>
           -->
          <div class="title-bar">
            <h6 class="title">Chat List</h6>
          </div>
          <div v-if="conversationList.length > 0">

            <ul class="message-list">
              <li v-for="(conversation, index) in conversationList" :key="index" :class="{ 'active': isConversationActive(conversation.users) }">
                <a :href="`/chat/${conversation.slug}`">
                  <div class="media media-60">
                    <img :src="getConversationIcon(conversation)" alt="image">
                  </div>
                  <div class="media-content">
                    <div>
                      <h6 class="name">{{getConversationName(conversation)}}</h6>
                      <p class="last-msg"> {{ getMessageContent(conversation) }}</p>
                    </div>
                    <div class="right-content">
                      <span class="date"> {{ conversation.messagesSent ? getConversationMessageTimeElapsed( conversation.messagesSent.timestamp) : '' }} </span>
                      <!-- <span class="date"> {{  '2m agasdasdo' }} </span> -->
  
                      <div :class="(conversation.messagesSent) ? ['seen-btn', 'dz-flex-box'] : ''"> <!-- add class 'active for delivered or read status'-->
                      <i :class="(conversation.messagesSent) ? ['icon', 'feather', 'icon-check'] : ''"></i>
                      
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <!-- <li>
                <a href="/chat">
                  <div class="media media-60">
                    <img src="assets/images/user/pic6.jpg" alt="image">
                  </div>
                  <div class="media-content">
                    <div>
                      <h6 class="name">Yonas</h6>
                      <p class="last-msg">Would love to!</p>
                    </div>
                    <div class="right-content">
                      <span class="date">2m ago</span>
                      <div class="seen-btn active dz-flex-box">
                        <i class="icon feather icon-check"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li class="active">
                <a href="/chat">
                  <div class="media media-60">
                    <img src="assets/images/user/pic7.jpg" alt="image">
                  </div>
                  <div class="media-content">
                    <div>
                      <h6 class="name">Senait</h6>
                      <p class="last-msg">Is that because we like the...</p>
                    </div>
                    <div class="right-content">
                      <span class="date">4m ago</span>
                      <div class="seen-btn dz-flex-box">
                        <i class="icon feather icon-check"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="/chat">
                  <div class="media media-60">
                    <img src="assets/images/user/pic8.jpg" alt="image">
                  </div>
                  <div class="media-content">
                    <div>
                      <h6 class="name">Elias</h6>
                      <p class="last-msg">How do you know john?</p>
                    </div>
                    <div class="right-content">
                      <span class="date">8h ago</span>
                      <div class="seen-btn active dz-flex-box">
                        <i class="icon feather icon-check"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li class="active">
                <a href="/chat">
                  <div class="media media-60">
                    <img src="assets/images/user/pic9.jpg" alt="image">
                  </div>
                  <div class="media-content">
                    <div>
                      <h6 class="name">Mikaela</h6>
                      <p class="last-msg">Have you even been...</p>
                    </div>
                    <div class="right-content">
                      <span class="date">1d ago</span>
                      <div class="seen-btn dz-flex-box">
                        <i class="icon feather icon-check"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="/chat">
                  <div class="media media-60">
                    <img src="assets/images/user/pic10.jpg" alt="image">
                  </div>
                  <div class="media-content">
                    <div>
                      <h6 class="name">Abeba</h6>
                      <p class="last-msg">that so awesome!</p>
                    </div>
                    <div class="right-content">
                      <span class="date">3h ago</span>
                      <div class="seen-btn dz-flex-box">
                        <i class="icon feather icon-check"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="/chat">
                  <div class="media media-60">
                    <img src="assets/images/user/pic11.jpg" alt="image">
                  </div>
                  <div class="media-content">
                    <div>
                      <h6 class="name">Kaleb</h6>
                      <p class="last-msg">Is that because we like the...</p>
                    </div>
                    <div class="right-content">
                      <span class="date">5d ago</span>
                      <div class="seen-btn active dz-flex-box">
                        <i class="icon feather icon-check"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li class="active">
                <a href="/chat">
                  <div class="media media-60">
                    <img src="assets/images/user/pic12.jpg" alt="image">
                  </div>
                  <div class="media-content">
                    <div>
                      <h6 class="name">Chaltu</h6>
                      <p class="last-msg">Would love to!</p>
                    </div>
                    <div class="right-content">
                      <span class="date">2m ago</span>
                      <div class="seen-btn active dz-flex-box">
                        <i class="icon feather icon-check"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="/chat">
                  <div class="media media-60">
                    <img src="assets/images/user/pic13.jpg" alt="image">
                  </div>
                  <div class="media-content">
                    <div>
                      <h6 class="name">Bethlehem</h6>
                      <p class="last-msg">Would love to!</p>
                    </div>
                    <div class="right-content">
                      <span class="date">4m ago</span>
                      <div class="seen-btn dz-flex-box">
                        <i class="icon feather icon-check"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </li> -->
            </ul>
          </div>
          <div v-else>
            <span> Keep Swiping! No Matches Yet, But Your Match Could Be Just Around the Corner! </span>
          </div>
        </div>    
      </div>
      <!-- Page Content End -->

      <!-- Menubar -->
      <MenuBarFooter></MenuBarFooter>
      <!-- Menubar -->
    </div> 
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import MenuBarFooter from '@/components/MenuBarFooter.vue';
import PreLoader from '@/components/PreLoader.vue';
import TitledHeader from './TitledHeader.vue';
import { getUserConversationList } from '@/api';


@Component({
components: {
  MenuBarFooter,
  PreLoader,
  TitledHeader,
},
})
export default class Explore extends Vue {
  private userData: object = this.$store.state.userData;
  private conversationList: object[] = [];
  private activeTimeThreshold: number = 10;
  private user_id: number = 0;
  private apiHost = '';
  private socketHost = '';
  private chatSocket: any = null;
  private socketConnectionString: string = '';
  private loading: boolean = true;
  private currentPage: number = 1;
  private totalPages: number = 1;

  data() {
    return {
      user_id: this.user_id,
      apiHost: process.env.VUE_APP_API_HOST,
      chatSocket: null,
      // socketHost: process.env.VUE_APP_SOCKET_HOST,
      socketHost: this.$store.state.chatSocketHost,
      socketConnectionString: process.env.VUE_APP_NEW_SOCKET_CONNECTION_STRING,
      currentPage: 1,
      totalPages: 1,
      loading: true
    }
  }

  mounted() {
    const id = parseInt((this.userData as any).id);
    this.fetchUserConversationList(id);
    this.user_id = id;

    this.initChatSocket().then(() => {
      this.connectMessage();
    });
  }

  fetchUserConversationList(id: number) {
    getUserConversationList(id)
      .then(response => {
        this.loading = true;
        this.conversationList = response.data.items;

        if (this.conversationList.length < 1) {
          this.loading = false;
        }

        
      })
      .catch(error => {
        console.error('Error fetching conversation list: ', error);
      })
      .finally(() => {
        this.loading = false;
      })
  }

  connectMessage() {
    const socketRequestData : any = {
      event: "event_request",
      socket_type: "update_conversation_list",
      data: {
        user_id: this.user_id,
        content: this.socketConnectionString
      }
    };

    this.chatSocket.send(JSON.stringify(socketRequestData));
  }

  isConversationActive(users: object[]) {
    const currentTime = new Date();
    for (let i = 0; i < users.length; i++) {
      if (users[i] != (this.userData as any).id){
        const lastOnline = new Date((users[i] as any).last_online);
        const timeDifference = ((currentTime as any)  - (lastOnline as any)) / (1000 * 60); // Difference in minutes
        if (timeDifference <= this.activeTimeThreshold) {
          return true;
        }
      }
    }
    return false;
  }

  getConversationIcon(conversation: object) {
    const users = (conversation as any).users;
    if ((conversation as any).conversation_icon){
      return `${this.apiHost}/api/get-image?location=${(conversation as any).conversation_icon}`;
    }

    for (let i = 0; i < users.length; i++) {
      if (users[i].id != this.user_id){
        return `${this.apiHost}/api/get-image?location=${users[i].userProfile.profile_pic}`;
      }
    }
    return 'assets/images/user/def-pics/pic5.jpg';
  }


  getMessageContent(conversation: object) {
    const message = (conversation as any).messagesSent;

    // {{ conversation.messagesSent ? (conversation.messagesSent.sender_id == user_id ? `You: ${conversation.messagesSent.content}` : conversation.messagesSent.content) : 'No messages yet. Start Chatting.'}}

    let content = 'No messages yet. Start Chatting.';
    if (message) {
      if (message.content) {
        
        content = message.content;

        if (message.content.length > 50) {
          content = content.substring(0, 50) + '...';
        }
      }
      if (message.sender_id == this.user_id) {

        return `You: ${content}`;
      } else {
        return content;
      }
    }

    return content;

  }

  getConversationName(conversation: object) {
    const users = (conversation as any).users;
    if ((conversation as any).title != 'Conversation Title'){
      return (conversation as any).title;
    }

    for (let i = 0; i < users.length; i++) {
      if (users[i].id != this.user_id){
        return users[i].userProfile.name;
      }
    }
    return 'user';
  }

  getConversationMessageTimeElapsed(timestamp: Date) {
    const currentTime : any = new Date();
    const messageTime : any = new Date(timestamp);
    const timeDifference = (currentTime - messageTime) / 1000; // Difference in seconds

    if (timeDifference < 60) {
      return 'just now';
    } else if (timeDifference < 3600) {
      const minutes = Math.floor(timeDifference / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (timeDifference < 86400) {
      const hours = Math.floor(timeDifference / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (timeDifference < 86400) {
      const hours = Math.floor(timeDifference / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (timeDifference < 2592000) {
      const days = Math.floor(timeDifference / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (timeDifference < 31536000) {
      const months = Math.floor(timeDifference / 2592000);
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else {
      const years = Math.floor(timeDifference / 31536000);
      return `${years} year${years > 1 ? 's' : ''} ago`;
    }
  }

  initChatSocket() {
    return new Promise((resolve, reject) => {
      this.chatSocket = new WebSocket(this.socketHost);
      
      this.chatSocket.onopen = () => {
        console.log("[open] Connection established");
        resolve(undefined);
      }
      
      this.chatSocket.onmessage = (event: any) => {
      // console.log(`[message] Data received from server: ${event.data}`);
        console.log(`[message] Data received from server: ${event.data}`);

        const newConversationList = JSON.parse(event.data);
        
        if (newConversationList.data){
          this.conversationList = newConversationList.data.items;
    
        }
        // this.$nextTick(() => {
        //   window.scrollTo(0, document.body.scrollHeight);
        // });

      };

      this.chatSocket.onerror = function(error: any) {
        console.log(`[error] ${JSON.stringify(error)}`);
        reject(error);
      };

      this.chatSocket.onclose = () => {
        console.log('WebSocket connection closed');

      };
    });
  }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
