<template>
  <div class="bg-white">
    <div class="page-wrapper">
    
      <!-- Preloader -->
        <PreLoader></PreLoader>
      <!-- Preloader end-->
        <TitledHeader title="Settings"></TitledHeader>
      <!-- Header -->
      
      <!-- Page Content Start -->
      <div class="page-content space-top">
        <div class="container"> 
          <h6 class="title">Account Setting</h6>
          <div class="card style-3">
            <div class="card-header">
              <h6 class="title mb-0 font-14 font-w500">Phone Number</h6>
            </div>
            <div class="card-body">
              <a href="javascript:void(0);" class="setting-input" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom1" aria-controls="offcanvasBottom">
                <i class="icon dz-flex-box feather icon-phone-call"></i>
                <span>{{ phone_no }}</span>
              </a>
            </div>
          </div>
          <div class="card style-3">
            <div class="card-header">
              <h6 class="title mb-0 font-14 font-w500">Email Address</h6>
            </div>
            <div class="card-body">
              <a href="javascript:void(0);" class="setting-input" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom2" aria-controls="offcanvasBottom">
                <i class="icon dz-flex-box feather icon-mail"></i>
                <span> {{ email }}</span>
              </a>
            </div>
          </div>
          <h6 class="title">Discovery Setting</h6>
          <div class="card style-3">
            <div class="card-header">
              <h6 class="title mb-0 font-14 font-w500">Location</h6>
            </div>
            <div class="card-body">
              <a href="javascript:void(0);" class="setting-input" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom3" aria-controls="offcanvasBottom">
                <i class="icon dz-flex-box feather icon-map-pin"></i>
                <span> {{ location }}</span>
              </a>
            </div>
          </div>
          <!-- <h6 class="title">Other</h6>
          <div class="card style-3">
            <div class="card-header">
              <h6 class="title mb-0 font-14 font-w500">Maximum Distance</h6>
              <div class="title font-w600 font-16">
                <span class="example-val title slider-margin-value-min"></span>
                <span class="example-val title slider-margin-value-max"></span>
              </div>
            </div>
            <div class="card-body py-4">
              <div class="range-slider style-1 w-100">
                <div id="slider-tooltips3"></div>
              </div>
            </div>
          </div>
          <div class="card style-3">
            <div class="card-header">
              <h6 class="title mb-0 font-14 font-w500">Show Me</h6>
            </div>
            <div class="card-body">
              <a href="javascript:void(0);" class="setting-input" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom4" aria-controls="offcanvasBottom">
                <span>Women</span>
                <i class="icon feather dz-flex-box icon-chevron-right ms-auto me-0"></i>
              </a>
            </div>
          </div>
          <div class="card style-3 mb-0">
            <div class="card-header">
              <h6 class="title mb-0 font-14 font-w500">Age Range</h6>
              <div class="title font-w600 font-16">
                <span class="example-val title slider-margin-value-min"></span>
                <span class="example-val title slider-margin-value-max"></span>
              </div>
            </div>
            <div class="card-body py-4">
              <div class="range-slider style-1 w-100">
                <div id="slider-tooltips4"></div>
              </div>
            </div>
          </div> -->
        </div> 
      </div>
      <!-- Page Content End -->
      
      <!-- Phone Number OffCanvas -->
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom1">
        <button type="button" class="btn-close drage-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        <div class="offcanvas-header share-style m-0 pb-0">
          <h6 class="title">Phone Number</h6>
        </div>
            <div class="offcanvas-body overflow-visible">
          <form>
            <div class="input-group dz-select">
              <div class="input-group-text"> 
                <div>
                  <select class="form-control custom-image-select image-select">
                    
                    <option data-thumbnail="../assets/images/flags/ethiopia.svg">+251</option>
                    <option data-thumbnail="../assets/images/flags/australia.png">+61</option>
                    <option data-thumbnail="../assets/images/flags/india.png">+91</option>
                    <option data-thumbnail="../assets/images/flags/uae.png">+971</option>
                    <option data-thumbnail="../assets/images/flags/us.png">+1</option>
                  </select>
                </div>
              </div>
              <input type="text" class="form-control" v-model="phone_no">
            </div>
            <a href="javascript:void(0);" @click="handleSavePhone(user_id)" class="btn btn-gradient w-100 dz-flex-box btn-shadow rounded-xl" data-bs-dismiss="offcanvas" aria-label="Close">Save</a>
          </form>		
            </div>
        </div>
      <!-- Phone Number OffCanvas -->
      
      <!--  Email OffCanvas -->
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom2">
        <button type="button" class="btn-close drage-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        <div class="offcanvas-header share-style m-0 pb-0">
          <h6 class="title">Email Address</h6>
        </div>
            <div class="offcanvas-body">
          <form>
            <div class="input-group input-group-icon">
              <div class="input-group-text">
                <div class="input-icon">
                  <i class="fa-solid fa-envelope"></i>
                </div>
              </div>
              <input type="email" class="form-control" v-model="email">
            </div>
            <a href="javascript:void(0);" @click="handleSaveEmail(user_id)" class="btn btn-gradient w-100 dz-flex-box btn-shadow rounded-xl" data-bs-dismiss="offcanvas" aria-label="Close">Save</a>
          </form>
            </div>
        </div>
      <!--  Email OffCanvas -->
      
      <!--  Location OffCanvas -->
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom3">
          <button type="button" class="btn-close drage-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          <div class="offcanvas-header share-style m-0 pb-0">
            <h6 class="title">Location</h6>
          </div>
          <div class="offcanvas-body">
            <form>

              <div class="card style-3">
                <div class="card-header">
                  <h6 class="title mb-0 font-14 font-w500">City</h6>
                </div>
                <div class="card-body">
                  <a href="javascript:void(0);" class="setting-input" data-bs-toggle="offcanvas" data-bs-target="#offcanvasCities" aria-controls="offcanvasCities">
                    <span class="select-lang">{{ cityName ? cityName : 'Select City' }}</span>
                  </a>
                </div>
              </div>

              <div class="input-group input-group-icon">
                <div class="input-group-text">
                  <div class="input-icon">
                    <i class="icon feather icon-map-pin"></i>
                  </div>
                </div>
                <!-- <input type="email" class="form-control" value="123 Churchill Avenue, Bole, Addis Ababa, Ethiopia"> -->
                <input type="email" class="form-control" v-model="location_line">
              </div>
              <a href="javascript:void(0);" @click="handleSaveLocation(user_id)" class="btn btn-gradient w-100 dz-flex-box btn-shadow rounded-xl" data-bs-dismiss="offcanvas" aria-label="Close">Save</a>
            </form>
          </div>
        </div>
      <!--  Location OffCanvas -->

      <!-- Cities Offcanvas -->
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasCities" aria-modal="true" role="dialog">
          <!-- <button type="button" class="btn-close drage-close" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
          <button type="button" class="btn-close drage-close" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom3" aria-controls="offcanvasBottom" aria-label="Close"></button>
          <div class="offcanvas-header share-style m-0 pb-0">
            <h6 class="title">City</h6>
          </div>
          <div class="offcanvas-body">
            <div class="radio style-2">
              <label class="radio-label" v-for="cityEntry in cities" :key="cityEntry.id">
                <input type="radio" v-model="city" :value="cityEntry.id" :name="radio2"
                @click="getCity(cityEntry.city)"
                >
                <span class="checkmark">
                  <span class="text">{{ cityEntry.city }}</span>
                  <span class="check"></span>
                </span>
              </label>
            </div>
          </div>
        </div>
      <!-- Cities Offcanvas -->
      
      <!--  Location OffCanvas -->
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom4">
        <button type="button" class="btn-close drage-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        <div class="offcanvas-header share-style m-0 pb-0">
          <h6 class="title">Show Me</h6>
        </div>
            <div class="offcanvas-body">
          <div class="radio style-2">
            <label class="radio-label">
              <input type="radio" checked="checked" name="radio2">
              <span class="checkmark">						
                <span class="text">Women</span>
                <span class="check"></span>							
              </span>
            </label>
            <label class="radio-label">
              <input type="radio" name="radio2">
              <span class="checkmark">
                <span class="text">Men</span>
                <span class="check"></span>							
              </span>
            </label>
            <label class="radio-label">
              <input type="radio" name="radio2">
              <span class="checkmark">
                <span class="text">Other</span>	
                <span class="check"></span>							
              </span>
            </label>
          </div>
            </div>
        </div>
      <!--  Location OffCanvas -->
    </div>  
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import TitledHeader from '@/components/TitledHeader.vue';
import PreLoader from './PreLoader.vue';
import store from '@/store';
import { updateUser, updateUserProfile } from '@/api';

@Component({
components: {
  TitledHeader,
  PreLoader
},
})
export default class Settings extends Vue {
  private userData: object = this.$store.state.userData;
  private cityName: string = '';
  private city :number = 0;
  private cities: object[] = [];
  private location_line: string = '';
  private location: string = '';
  private email: string = '';
  private phone_no: string = '';
  private loading: boolean = true;
  private user_id: number = 0;


  data() {
    
    interface userData {
      id: number,
			username: string | null,
			email: string,
			phone_no: string,
			status: number,
			created_at: string ,
			updated_at: string,
			deleted_at: string | null,
			user_type_id: number,
			userProfile: userProfile 
    }

    interface userProfile {
      id: number,
      profile_pic: string,
      gender: string,
      location: string,
      location_city: number,
      birthdate: string,
      sexual_orientation_id: number | null,
      name: string,
      audio_intro: string | null,
      relationship_goals_id: number,
      dating_interest: string,
      age_preference: number
    }

    interface city {
      id: number,
      city: string,
      
    }

    const user_userData : userData | undefined = this.$store.state.userData;
    const cityList : city[] | undefined = this.cities as city[];

    return {
      apiHost: process.env.VUE_APP_API_HOST,
      user_id: user_userData?.id,
      phone_no: user_userData?.phone_no ?? '',
      email: user_userData?.email ?? '',
      location: user_userData?.userProfile?.location ?? '',
      location_line: user_userData?.userProfile?.location ? user_userData?.userProfile?.location.split(", ")[0]  : '',

      cities: this.cities,
      city: user_userData?.userProfile.location_city ?? 1,
      // cityName: cityList?.find(cityEntry => cityEntry.id === user_userData?.userProfile.location_city)
      cityName: user_userData?.userProfile?.location ? user_userData?.userProfile?.location.split(", ")[1]  : 'Select City',
      
    }
  }

  async mounted() {
    await this.loadCities();
    // console.log(this.cities); 
  }

  getCity(cityEntry: string)
  {
    console.log(JSON.stringify(cityEntry));
    this.cityName = cityEntry;
    
  }

  async loadCities() {

    try {
      const response = await fetch("/assets/json/ethiopia_cities.json");
      const data = await response.json();
      this.cities = data;
    } catch (error) {
      console.error("Error loading cities:", error);
    }
  }

  handleSaveEmail (id:number) {

    this.loading=true;

    const newData = {
      'email': this.email
    }

    this.putUpdateUser(id, newData);

    // updateUser(id, newData)
    //   .then(response => {
    //     if (response.data) {
    //       this.$store.commit('setUserData', response.data);
    //     }
    //     this.loading = false;
    //   })
    //   .catch(error => {
    //   console.error("Error updating changes: ", error);
    //     this.loading = false;
    //   });
  }

  handleSavePhone (id:number) {

    this.loading=true;

    const newData = {
      'phone_no': this.phone_no
    }

    this.putUpdateUser(id, newData);
    
  }

  handleSaveLocation (id:number) {
    this.loading=true;

    const newData = {
      'location_city': this.city,
      'location': `${this.location_line}, ${this.cityName}`
    }

    this.putUpdateUserProfile(id, newData);
    this.location = `${this.location_line}, ${this.cityName}`;
    
  }

  putUpdateUser(id:number, newData:object) {
    updateUser(id, newData)
      .then(response => {
        if (response.data) {
          this.$store.commit('setUserData', response.data);
        }
        this.loading = false;
      })
      .catch(error => {
      console.error("Error updating changes: ", error);
        this.loading = false;
      });
  }

  putUpdateUserProfile(id:number, newData:object) {
    updateUserProfile(id, newData)
      .then(response => {
        if (response.data) {
          this.$store.commit('setUserData', response.data);
        }
        this.loading = false;
      })
      .catch(error => {
      console.error("Error updating changes: ", error);
        this.loading = false;
      });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
