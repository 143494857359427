<template>
  <div class="bg-white">
    <div class="page-wrapper">
      <Loader v-if="loading"></Loader>
      <div v-else>
        <PreLoader />

        <!-- Page Content -->
        <div class="page-content">
          <div class="container">
            <div class="account-area">
              <BackButton/>

              <Toast :message="toastMessage" :visible.sync="isToastMessageVisible" />

              <div class="section-head ps-0">
                <h3>Please Enter your Username</h3>
              </div>
              <div class="mb-2 input-group input-group-icon input-mini">
                <div class="input-group-text">
                  <div class="input-icon">
                    <i class="icon feather icon-at-sign"></i>
                  </div>
                </div>
                <input type="text" class="form-control" v-model="userName" id ="userName" placeholder="Enter Username">								
              </div>
              
            
            </div>
          </div>
        </div>
        <ButtonFooter :onClick="fetchHasUsername" label="Next" :isFetching="isFetching"></ButtonFooter>

        <!-- <a @click="fetchHasUsername" class="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl">
          <div v-if="isFetching" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="mx-3">
            Login 
          </div>
        </a> -->

      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';
import ButtonFooter from './ButtonFooter.vue';
import BackButton from './BackButton.vue';
import Loader from './Loader.vue';
import Toast from './Toast.vue';
import { getUserDataByUsername } from '@/api';


@Component({
components: {
  ButtonFooter,
  PreLoader,
  BackButton, 
  Toast,
  Loader
},
})


export default class UserName extends Vue {

  private firstName: string = '';
  private loading: boolean = true;
  private toastMessage: string = '';
  private isToastMessageVisible: boolean = false;
  private userData: object = {};
  private userName: string = '';
  private countryCode: string = '';
  private isFetching: boolean = false;


  private handleNextClick() {
    if (!this.firstName) {

      this.toastMessage = 'Field cannot be left blank';
      this.isToastMessageVisible = true;
      return;
    }
      this.$store.commit('setUserData', { name: this.firstName });
      this.$router.push('/email');
  }

  fetchHasUsername() {

    if (!this.userName) {

      this.toastMessage = 'Username cannot be empty';
      this.isToastMessageVisible = true;
      return;
    }

    this.isFetching = true;

    getUserDataByUsername(this.userName)
      .then(response => {
        
        if (response.data.items && response.data.items.length > 0) {

          this.toastMessage = 'Username Already taken';
          this.isToastMessageVisible = true;
          this.isFetching = false;
          return;
        } 

          this.$store.commit('setUserData', { username: this.userName});
          this.$router.push('/password');
        
            
         
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  data() {
    this.userData = this.$store.state.userData;
    const user_userData = this.$store.state.userData;
    return {
      loading : true,
      userName: user_userData?.username ?? '',
    }
  }

  mounted() {
    // If your data is static, you can set loading to false directly in the mounted hook
    this.loading = false;
  }
  

};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
