<template>
  <div class="subscription">
    <Subscription></Subscription>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Subscription from '@/components/Subscription.vue'; // @ is an alias to /src

@Component({
  components: {
    Subscription,
  },
})
export default class SubscriptionView extends Vue {}
</script>
