<template>
  <div class="otp">
    <Otp></Otp>
    <!-- <HelloWorld msg="Welcome to Your Vue.js + TypeScript App"/> -->
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Otp from '@/components/Otp.vue'; // @ is an alias to /src

@Component({
  components: {
    Otp,
  },
})
export default class OtpView extends Vue {}
</script>
