<template>
  <div class="gender">
    <Gender></Gender>
  </div>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Gender from '@/components/Gender.vue';

@Component({
components: {
  Gender,
},
})
export default class GenderViw extends Vue {}
</script>
