<template>
  <div class="bg-white">
<div class="page-wrapper">
    
	<!-- Preloader -->
	<PreLoader></PreLoader>
    <!-- Preloader end-->
	
	<!-- Header -->
		<TitledHeader title="My Subscription"></TitledHeader>
	<!-- Header -->
	
	<!-- Page Content Start -->
	<div class="page-content space-top p-b60">
		<div class="container"> 
			<div class="dz-subscribe-area">
				<div class="subscribe-area-top">
					<div class="swiper subscription-swiper2">
						<div class="swiper-wrapper mb-3">
							<div class="swiper-slide" data-card="plus">
								<div class="subscribe-box plus">
									<h3 class="title">W3Dating App</h3>
									<div class="badge">PLUS</div>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="subscribe-box gold">
									<h3 class="title">W3Dating App</h3>
									<div class="badge">GOLD</div>
								</div>
							</div>
							<div class="swiper-slide">
								<div class="subscribe-box platinum">
									<h3 class="title">W3Dating App</h3>
									<div class="badge">PLATINUM</div>
								</div>
							</div>
						</div>
						<div class="swiper-btn">
							<div class="swiper-pagination style-1 flex-1"></div>
						</div>
					</div>
				</div>
				<div class="subscribe-content plus" id="plus">
					<ul class="pricing-data">
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Unlimited Likes</span>
						</li>
						<li class="list-false">
							<i class="icon feather icon-lock"></i>
							<span>See Who Likes You</span>
						</li>
						<li class="list-false">
							<i class="icon feather icon-lock"></i>
							<span>Priority Likes</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Unlimited Rewinds</span>
						</li>
						<li class="list-false">
							<i class="icon feather icon-lock"></i>
							<span>1 Free Boost per month</span>
						</li>
						<li class="list-false">
							<i class="icon feather icon-lock"></i>
							<span>5 Free Super Likes per week</span>
						</li>
						<li class="list-false">
							<i class="icon feather icon-lock"></i>
							<span>Message Before Matching</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Passport</span>
						</li>
						<li class="list-false">
							<i class="icon feather icon-lock"></i>
							<span>Top Picks</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Control Your Profile</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Control Who Sees You</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Control Who You See</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Hide Ads</span>
						</li>
					</ul>
					<div class="bottom-btn container bg-white text-center px-5">
						<a href="javascript:void(0);" class="btn btn-gradient dz-flex-box btn-shadow rounded-xl">Starting at <span class="ms-2"><i class="fa-solid fa-indian-rupee-sign"></i></span> 450</a>
					</div>
				</div>
				<div class="subscribe-content gold d-none" id="gold">
					<ul class="pricing-data">
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Unlimited Likes</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>See Who Likes You</span>
						</li>
						<li class="list-false">
							<i class="icon feather icon-lock"></i>
							<span>Priority Likes</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Unlimited Rewinds</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>1 Free Boost per month</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>5 Free Super Likes per week</span>
						</li>
						<li class="list-false">
							<i class="icon feather icon-lock"></i>
							<span>Message Before Matching</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Passport</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Top Picks</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Control Your Profile</span>
						</li> 
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Control Who Sees You</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Control Who You See</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Hide Ads</span>
						</li>
					</ul>
					<div class="bottom-btn container bg-white text-center px-5">
						<a href="javascript:void(0);" class="btn btn-gradient dz-flex-box btn-shadow rounded-xl">Starting at <span class="ms-2"><i class="fa-solid fa-indian-rupee-sign"></i></span> 650</a>
					</div>
				</div>
				<div class="subscribe-content platinum d-none" id="platinum">
					<ul class="pricing-data">
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Unlimited Likes</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>See Who Likes You</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Priority Likes</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Unlimited Rewinds</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>1 Free Boost per month</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>5 Free Super Likes per week</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Message Before Matching</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Passport</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Top Picks</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Control Your Profile</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Control Who Sees You</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Control Who You See</span>
						</li>
						<li class="list-true">
							<i class="icon feather icon-check"></i>
							<span>Hide Ads</span>
						</li>
					</ul>
					<div class="bottom-btn container bg-white text-center px-5">
						<a href="javascript:void(0);" class="btn btn-gradient dz-flex-box btn-shadow rounded-xl">Starting at <span class="ms-2"><i class="fa-solid fa-indian-rupee-sign"></i></span> 999</a>
					</div>
				</div>
			</div>
		</div> 
	</div>
	<!-- Page Content End -->
</div>  
</div>
  

</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PreLoader from '@/components/PreLoader.vue';
import TitledHeader from './TitledHeader.vue';

@Component({
components: {
  PreLoader,
  TitledHeader,
},
})
export default class Subscription extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
