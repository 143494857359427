<template>
  <div class="button-footer">
    <div class="footer fixed bg-white">
      <div class="container">
        <!-- <a href="your-gender.html" class="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl">Next</a> -->
        
        <!-- <a @click="handleClick" class="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl">{{ label }}</a> -->
        <a @click="handleClick" class="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl">
          <div v-if="isFetching" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="mx-3">
            {{ label }} 
          </div>
        </a>
        
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ButtonFooter extends Vue {
  @Prop() private next!: string;
  @Prop() private label!: string;
  @Prop() private isFetching!: boolean;
  @Prop({ type: Function, required: false })
  private onClick!: Function;

  private handleClick() {
      this.onClick();

  }


  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
