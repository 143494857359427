<template>
  <div class="overflow-hidden header-large">
<div class="page-wrapper">
    
	<!-- Preloader -->
	<!-- <div id="preloader">
		<div class="loader">
			<div class="load-circle"><div></div><div></div></div>
		</div>
	</div> -->
    <!-- Preloader end-->
    <!-- <Loader v-if="loading" /> -->
    <!-- <div v-else> -->
      

    <PreLoader></PreLoader>
    <div v-if="isLoading" class="spinner-overlay">
                
      <div class="preloader">
        <!-- Preloader -->
          <div id="preloader" ref="preloader">
            <div class="loader">
              <div class="load-circle"><div></div><div></div></div>
            </div>
          </div>
        <!-- Preloader end-->
      </div>

    </div>

    <!-- <div v-if="loading" class="loading-spinner">
      Loading...
    </div> -->
<!-- 
    <div v-if="loading" class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div v-else> -->

	
	<!-- Header -->
		<!-- <header class="header header-fixed border-0">
			<div class="container">
				<div class="header-content">
					<div class="left-content">
						<a href="javascript:void(0);" class="menu-toggler">
							<i class="icon feather icon-grid"></i>
						</a>
					</div>
					<div class="mid-content header-logo">
						<a href="home.html">
							<img src="/assets/images/logo.png" alt="">
						</a>
					</div>
					<div class="right-content dz-meta">
						<a href="/filters" class="filter-icon">
							<i class="flaticon flaticon-settings-sliders"></i>
						</a>
					</div>
				</div>
			</div>
		</header> -->
	<!-- Header -->
    <MenuBarHeader @toggle-menu="toggleMenu"></MenuBarHeader>
    <Toast :message="toastMessage" :visible.sync="isToastMessageVisible" />
	
	<!-- Sidebar -->
    <!-- <div class="dark-overlay"></div>
	<div class="sidebar">
		<div class="inner-sidebar">
			<a href="profile.html" class="author-box">
				<div class="dz-media">
					<img src="/assets/images/user/pic1.jpg" alt="author-image">
				</div>
				<div class="dz-info">
					<h5 class="name">Abel</h5>
					<span>abel@gmail.com</span>
				</div>
			</a>
			<ul class="nav navbar-nav">	
				<li>
					<a class="nav-link active" href="home.html">
						<span class="dz-icon">
							<i class="icon feather icon-home"></i>
						</span>
						<span>Home</span>
					</a>
				</li>
				<li>
					<a class="nav-link" href="../package.html">
						<span class="dz-icon">
							<i class="icon feather icon-heart"></i>
						</span>
						<span>W3Dating Package</span>
					</a>
				</li>
				<li>
					<a class="nav-link" href="../package-list.html">
						<span class="dz-icon">
							<i class="icon feather icon-list"></i>
						</span>
						<span>Package List</span>
					</a>
				</li>
				<li>
					<a class="nav-link" href="../index.html">
						<span class="dz-icon">
							<i class="icon feather icon-wind"></i>
						</span>
						<span>Welcome</span>
					</a>
				</li>
				<li>
					<a class="nav-link" href="../components/components.html">
						<span class="dz-icon">
							<i class="icon feather icon-grid"></i>
						</span>
						<span>Components</span>
					</a>
				</li>
				<li>
					<a class="nav-link" href="setting.html">
						<span class="dz-icon">
							<i class="icon feather icon-settings"></i>
						</span>
						<span>Settings</span>
					</a>
				</li>
				<li>
					<a class="nav-link" href="profile.html">
						<span class="dz-icon">
							<i class="icon feather icon-user"></i>
						</span>
						<span>Profile</span>
					</a>
				</li>
				<li>
					<a class="nav-link" href="onboarding.html">
						<span class="dz-icon">
							<i class="icon feather icon-log-out"></i>
						</span>
						<span>Logout</span>
					</a>
				</li>
			</ul>
			<div class="sidebar-bottom">
				<ul class="app-setting">
					<li>
						<div class="mode">
							<span class="dz-icon">                        
								<i class="icon feather icon-moon"></i>
							</span>					
							<span>Dark Mode</span>
							<div class="custom-switch">
								<input type="checkbox" class="switch-input theme-btn" id="toggle-dark-menu">
								<label class="custom-switch-label" for="toggle-dark-menu"></label>
							</div>					
						</div>
					</li>
				</ul>
				<div class="app-info">
					<h6 class="name">W3Dating - Dating App</h6>
					<span class="ver-info">App Version 1.1</span>
				</div>
			</div>
		</div>
	</div> -->
    <!-- Sidebar End -->
    <Sidebar  ref="sidebar"></Sidebar>
	
	<!-- Page Content Start -->
	<!-- <div class="page-content space-top p-b65">
		<div class="container fixed-full-area">
			<div class="dzSwipe_card-cont dz-gallery-slider">
				<div class="dzSwipe_card">
					<div class="dz-media">
						<img src="/assets/images/slider/pic1.png" alt="">
					</div>
					<div class="dz-content">
						<div class="left-content">
							<span class="badge badge-primary d-inline-flex gap-1 mb-2"><i class="icon feather icon-map-pin"></i>Nearby</span>
							<h4 class="title"><a href="/profile-detail">Aida , 24</a></h4>
							<p class="mb-0"><i class="icon feather icon-map-pin"></i> 3 miles away</p>
						</div>
						<a href="javascript:void(0);" class="dz-icon dz-sp-like"><i class="flaticon flaticon-star-1"></i></a>
					</div>
					<div class="dzSwipe_card__option dzReject">
						<i class="fa-solid fa-xmark"></i>
					</div>
					<div class="dzSwipe_card__option dzLike">
						<i class="fa-solid fa-check"></i>
					</div>
					<div class="dzSwipe_card__option dzSuperlike">
						<h5 class="title mb-0">Super Like</h5>
					</div>
					<div class="dzSwipe_card__drag"></div>
				</div>
				
				<div class="dzSwipe_card">
					<div class="dz-media">
						<img src="/assets/images/slider/pic3.png" alt="">
					</div>
					<div class="dz-content">
						<div class="left-content">
							<span class="badge badge-primary d-inline-flex gap-1 mb-2"><i class="icon feather icon-map-pin"></i>Nearby</span>
							<h4 class="title"><a href="/profile-detail">Lily , 21</a></h4>
							<p class="mb-0"><i class="icon feather icon-map-pin"></i> 5 miles away</p>
						</div>
						<a href="javascript:void(0);" class="dz-icon dz-sp-like"><i class="flaticon flaticon-star-1"></i></a>
					</div>
					<div class="dzSwipe_card__option dzReject">
						<i class="fa-solid fa-xmark"></i>
					</div>
					<div class="dzSwipe_card__option dzLike">
						<i class="fa-solid fa-check"></i>
					</div>
					<div class="dzSwipe_card__option dzSuperlike">
						<h5 class="title mb-0">Super Like</h5>
					</div>
					<div class="dzSwipe_card__drag"></div>
				</div>
				
				<div class="dzSwipe_card">
					<div class="dz-media">
						<img src="/assets/images/slider/pic4.png" alt="">
					</div>
					<div class="dz-content">
						<div class="left-content">
							<h4 class="title"><a href="/profile-detail">Gelila , 22</a></h4>
							<p class="mb-0"><i class="icon feather icon-map-pin"></i> 2 miles away</p>
						</div>
						<a href="javascript:void(0);" class="dz-icon dz-sp-like"><i class="flaticon flaticon-star-1"></i></a>
					</div>
					<div class="dzSwipe_card__option dzReject">
						<i class="fa-solid fa-xmark"></i>
					</div>
					<div class="dzSwipe_card__option dzLike">
						<i class="fa-solid fa-check"></i>
					</div>
					<div class="dzSwipe_card__option dzSuperlike">
						<h5 class="title mb-0">Super Like</h5>
					</div>
					<div class="dzSwipe_card__drag"></div>
				</div>
				
				<div class="dzSwipe_card">
					<div class="dz-media">
						<img src="/assets/images/slider/pic5.png" alt="">
					</div>
					<div class="dz-content">
						<div class="left-content">
							<h4 class="title"><a href="/profile-detail">Fana , 25</a></h4>
							<ul class="intrest">
								<li><span class="badge">Photography</span></li>
								<li><span class="badge">Street Food</span></li>
								<li><span class="badge">Foodie Tour</span></li>
							</ul>
						</div>
						<a href="javascript:void(0);" class="dz-icon dz-sp-like"><i class="flaticon flaticon-star-1"></i></a>
					</div>
					<div class="dzSwipe_card__option dzReject">
						<i class="fa-solid fa-xmark"></i>
					</div>
					<div class="dzSwipe_card__option dzLike">
						<i class="fa-solid fa-check"></i>
					</div>
					<div class="dzSwipe_card__option dzSuperlike">
						<h5 class="title mb-0">Super Like</h5>
					</div>
					<div class="dzSwipe_card__drag"></div>
				</div>
				
				<div class="dzSwipe_card">
					<div class="dz-media">
						<img src="/assets/images/slider/pic2.png" alt="">
					</div>
					<div class="dz-content">
						<div class="left-content">
							<span class="badge badge-primary mb-2">New here</span>
							<h4 class="title"><a href="/profile-detail">Eden , 22</a></h4>
							<ul class="intrest">
								<li><span class="badge intrest">Climbing</span></li>
								<li><span class="badge intrest">Skincare</span></li>
								<li><span class="badge intrest">Dancing</span></li>
								<li><span class="badge intrest">Gymnastics</span></li>
							</ul>							
						</div>
						<a href="javascript:void(0);" class="dz-icon dz-sp-like"><i class="flaticon flaticon-star-1"></i></a>
					</div>
					<div class="dzSwipe_card__option dzReject">
						<i class="fa-solid fa-xmark"></i>
					</div>
					<div class="dzSwipe_card__option dzLike">
						<i class="fa-solid fa-check"></i>
					</div>
					<div class="dzSwipe_card__option dzSuperlike">
						<h5 class="title mb-0">Super Like</h5>
					</div>
					<div class="dzSwipe_card__drag"></div>
				</div>
			</div>
		</div>
	</div> -->
  <div class="page-content space-top p-b65">
    <div class="container fixed-full-area">
      <div class="dzSwipe_card-cont dz-gallery-slider">
        
        <div v-for="(profile, index) in userList" :key="index" class="dzSwipe_card" :class="{ below: profile.below ?? false}"
            @mousedown="startSwipe($event, profile, index)"
            @touchstart="startSwipe($event, profile, index)"
            @mousemove="moveSwipe"
            @touchmove="moveSwipe"
            @mouseup="endSwipe()"
            @touchend="endSwipe()"
        > 
        
        <!-- <div v-for="profile in userList" :key="profile.id" class="dzSwipe_card" @mousedown="startSwipe" @mousemove="moveSwipe" @mouseup="endSwipe" @touchstart="startSwipe" @touchmove="moveSwipe" @touchend="endSwipe"> -->
            
          <div class="dz-media">
            <div class="image_container">

              <div v-if="isLoading" class="spinner-overlay">
                
                <div class="preloader">
                  <!-- Preloader -->
                    <div id="preloader" ref="preloader">
                      <div class="loader">
                        <div class="load-circle"><div></div><div></div></div>
                      </div>
                    </div>
                  <!-- Preloader end-->
                </div>

                <!-- <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div> -->
              </div>
              
            </div>
            

            <img :src="profile.userProfile && profile.userProfile.profile_pic ?  apiHost + '/api/get-image?location=' + profile.userProfile.profile_pic : '/assets/images/slider/def-pics/pic1.png'" :alt="profile.userProfile && profile.userProfile.name ? profile.userProfile.name : 'User'"
              @load="imageLoaded">
          </div>
          <div class="dz-content">
            <div class="left-content">
              <span v-if="isUserNearby(profile.userProfile.location_city)"  class="badge badge-primary d-inline-flex gap-1 mb-2"><i class="icon feather icon-map-pin"></i>Nearby</span>
              <h4 class="title"><a :href="`/profile-detail/${profile.id}`">{{ profile.userProfile ? profile.userProfile.name : 'User' }}, {{ profile.userProfile && profile.userProfile.birthdate ? calculateAge(profile.userProfile.birthdate) : '' }}</a></h4>

              <!-- <h4 class="title"><a :href="'/profile-detail/' + profile.userProfile && profile.userProfile.name ? profile.userProfile.name : 'User'">{{ profile.userProfile && profile.userProfile.name ? profile.userProfile.name : 'User' }}, {{ profile.age ? profile.age : '66' }}</a></h4> -->
              <p class="mb-0"><i class="icon feather icon-map-pin"></i> {{ profile.userProfile ? profile.userProfile.location : '' }} </p>
              <!-- <ul class="intrest">
								<li><span class="badge intrest">Climbing</span></li>
								<li><span class="badge intrest">Skincare</span></li>
								<li><span class="badge intrest">Dancing</span></li>
								<li><span class="badge intrest">Gymnastics</span></li>
							</ul>		 -->
            </div>
            <a @click="handleSuperLike(profile, index)" class="dz-icon dz-sp-like"><i class="flaticon flaticon-star-1"></i></a>
          </div>
          <div class="dzSwipe_card__option dzReject">
            <i class="fa-solid fa-xmark"></i>
          </div>
          <div class="dzSwipe_card__option dzLike">
            <i class="fa-solid fa-check"></i>
          </div>
          <div class="dzSwipe_card__option dzSuperlike" :style="{ opacity: profile.superLiked ? '1' : '' }">
            <h5 class="title mb-0">Super Like</h5>
          </div>
          <div class="dzSwipe_card__drag"></div>
        </div>
        
      </div>
    </div>
  </div>

<!-- </div>  -->
	<!-- Page Content End -->
    <MenuBarFooter></MenuBarFooter>
    </div>
  </div>
  <!-- </div> -->
</template>


<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MenuBarFooter from '@/components/MenuBarFooter.vue';
import MenuBarHeader from '@/components/MenuBarHeader.vue';
import Sidebar from '@/components/Sidebar.vue';
import PreLoader from '@/components/PreLoader.vue';
import Loader from '@/components/Loader.vue';
import Toast from '@/components/Toast.vue';
import $ from 'jquery';
import axios from 'axios';
import store from '@/store';
import { getUsersFeed, storeUserSwipe, updateIncrementSuperLikeUsedQuota, storeDailySuperLikeQuota, storeUserSwipeCheckDateMatch } from '@/api';

@Component({
components: {
  MenuBarFooter,
  MenuBarHeader,
  Sidebar,
  PreLoader,
  Loader,
  Toast
},
})
export default class Feed extends Vue {
  private userList: object [] = [];
  private loading: boolean = true;
  private imgSrc: string = '';
  private userData: object = store.state.userData;
  private user_id: number = 0;
  private relationship_goals_id: number = 0;
  private dating_interest: string ='';
  private age_preference: number = 0;
  // private isSwiping: boolean = false;
  // private startX: number = 0;
  // private startY: number = 0;
  // private deltaX: number = 0;
  // private deltaY: number = 0;
  private action: string = '';
  private startSwipe: any | undefined = undefined;
  private moveSwipe: any | undefined = undefined;
  private endSwipe: any | undefined = undefined;
  private profileCounter: number = 0;
  private profileNum: number = 0;
  private canSuperLike: boolean | null = null;
  private toastMessage: string = '';
  private isToastMessageVisible: boolean = false;
  private totalImages: number = 0;
  private imagesLoaded: number = 0; 
  private isLoading: boolean = true;

  private remainingUserList: object[] = [];

  private currentPage: number = 1;
  

  
  data() {

    interface userData {
      id: number,
			username: string | null,
			email: string,
			phone_no: string,
			status: number,
			created_at: string ,
			updated_at: string,
			deleted_at: string | null,
			user_type_id: number,
			userProfile: userProfile 
    }

    interface userProfile {
      id: number,
      profile_pic: string,
      gender: string,
      location: string,
      location_city: number,
      birthdate: string,
      sexual_orientation_id: number | null,
      name: string,
      audio_intro: string | null,
      relationship_goals_id: number,
      dating_interest: string,
      age_preference: number
    }

    const user_userData : userData | undefined = this.$store.state.userData;
    
    return {
      userList: [],
      loading: true,
      apiHost: process.env.VUE_APP_API_HOST,

      user_id: user_userData?.id,
      relationship_goals_id: user_userData?.userProfile.relationship_goals_id,
      dating_interest: user_userData?.userProfile.dating_interest,
      age_preference: user_userData?.userProfile.age_preference,
      profileCounter: 0,
      profileNum: 0,
      canSuperLike: null

      // // TinderSwiper
      // isSwiping: false,
      // startX: 0,
      // startY: 0,
      // deltaX: 0,
      // deltaY: 0
      // TinderSwiper


    }
  }

  private toggleMenu(): void {
    // Access the toggleVisibility method of the Sidebar component using refs
    const sidebar = this.$refs.sidebar as Sidebar;
    if (sidebar) {
      sidebar.toggleVisibility();
    }
  }

  fetchFeed(page: number = 1) {
    // alert(JSON.stringify( this.$store.state.userData));
    // Make an Axios GET request to your API endpoint
    this.loading=true;
    

    // axios.get(process.env.VUE_APP_API_HOST + '/users?expand=userProfile&exclude_id=' + this.$store.state.userData.id)
    getUsersFeed(this.user_id, this.relationship_goals_id, this.dating_interest, this.age_preference, page)
      .then(response => {
        // Update the 'relationshipGoals' array with the response data
        // this.$store.commit('setRelationshipGoals', response.data.items)
        const responseData = response.data.items;
        console.clear();
        console.log(response.data._meta);
        // this.userList = responseData.map((items: object []) => ({
        //   ...items,
        //   superLiked: false,
        //   below: false

        // }));
        this.remainingUserList = [
          ...this.remainingUserList,
          ...responseData.map((item: any) => ({
            ...item,
            superLiked: false,
            below: false
          }))
        ];

        this.userList = [
          ...this.userList,
          ...responseData.map((item: any) => ({
            ...item,
            superLiked: false,
            below: false
          }))
        ];
        


        this.profileNum = this.userList.length;
        this.totalImages = this.userList.length;
        this.imagesLoaded = 0; // Reset the loaded images count
      

        console.log(JSON.stringify(response.data));
        
        // this.initDatingKitSwiper();
        if (this.userList.length < 1) {
          this.isLoading = false;
        }
        this.userList.forEach((profile, index) => {
          this.initCardSwipe();
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  
  calculateAge(birthdate: string) {
    const today = new Date();
      const birthDate = new Date(birthdate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }


      return age; 
  }

  imageLoaded() {
      // Check if all images are loaded
      // const allImagesLoaded = this.userList.every(profile => (profile as any).userProfile.profile_pic);
      // if (allImagesLoaded) {
      //   this.loading = false; // Set loading state to false when all images are loaded
      // }

      this.imagesLoaded += 1;
      // alert(`images loaded: ${this.imagesLoaded} >= totalImages: ${this.totalImages}`);
      if (this.imagesLoaded >= this.totalImages) {
        this.isLoading = false;
      }
    }

  imageError() {
    this.imagesLoaded += 1;
    if (this.imagesLoaded >= this.totalImages) {
      this.isLoading = false;
    }
  }

  isUserNearby(potentialsLocationCity: number)
  {
    return potentialsLocationCity === this.$store.state.userData.userProfile.location_city
  }

  @Watch('remainingUserList')
  onRemainingUserListChange(newList: object[]) {
    // alert(newList.length);
    if (newList.length < 5 && !this.isLoading) {
      this.currentPage += 1;
      this.fetchFeed(this.currentPage);
    }
  }

  // @Watch('imagesLoaded')
  // onImagesLoadedChange(newVal: number) {
  //   this.imagesLoaded = newVal;
  //   console.log(this.imagesLoaded);
  //   this.loading = true;
  //   if (this.imagesLoaded >= this.totalImages) {
  //     this.loading = false;
  //   }
  // }
  
  mounted() {
    
    this.fetchFeed();
   
  //  const data = {	
  //     "username": "chilliwack_admin",
	//     "password": "chilliwack_admin_password"
  //   };
  //   axios.create({
  //     baseURL: 'https://api.chilliwack.itexxiahosting.com',
  //     withCredentials: true, // Include cookies if your API requires authentication
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json'
  //     }
  //   }).
  //   post('/login', data)
  //     .then(response => {
  //       console.clear();
  //       console.log(response.data);
  //     })
  //     .catch(error => {
  //         console.error(error);
  //         if (error.response) {
  //           // Server responded with a status other than 200 range
  //           console.error(`Error: ${error.response.data.message || 'Login failed.'}`);
  //         } else if (error.request) {
  //           // Request was made but no response was received
  //           console.error('No response from server. Please try again later.');
  //         } else {
  //           // Something happened in setting up the request that triggered an Error
  //           console.error('Error in request setup: ' + error.message);
  //         }
  //       });
  
  }

  // TinderSwiper Code

  initDatingKitSwiper() {
    this.$nextTick(() => {
      // Use this.$el to access the component's root element
      const cards = this.$el.querySelectorAll('.dzSwipe_card');

      cards.forEach(card => {
        // Call initCardSwipe for each card
        // this.initCardSwipe(card);
      });
    });
  }

  initCardSwipe() {
    let action = this.action;
    let description = '';
    // Add swipe functionality to each card
    // You may need to adjust this according to your requirements
    // Example: add event listeners for swipe actions
    let startX = 0;
    let startY = 0;
    let currentProfileId : number | null = null;
    let diffX = 0;
    let diffY = 0;
    let this_index = 0;
    let this_profile : object = {};


    const startSwipe = (event: any, profile: any, index: number) => {
      this_index = index;
      this_profile = profile;
      currentProfileId = (profile as any).id;
      if (event.type === 'touchstart') {
        startX = event.touches[0].clientX;
        startY = event.touches[0].clientY;
      } else {
        startX = event.clientX;
        startY = event.clientY;
      }

      document.addEventListener('mousemove', moveSwipe);
      document.addEventListener('touchmove', moveSwipe);
      document.addEventListener('mouseup', endSwipe);
      document.addEventListener('touchend', endSwipe);
    }

    const moveSwipe = (event: any) => {
      event.preventDefault();

      let endX, endY;

      if (event.type === 'touchmove') {
        endX = event.touches[0].clientX;
        endY = event.touches[0].clientY;
      } else {
        endX = event.clientX;
        endY = event.clientY;
      }


      
      diffX = endX - startX;
      diffY = endY - startY;

      
      // if (startX == 0) {
      //   diffX = 0;
      // }
      // if (startY == 0) {
      //   diffY = 0;
      // }
      
      // console.clear();
      console.log(`startX ${startX}; endX ${endX}; diffX ${diffX}`);
      

      // Check if the absolute difference in X is greater than the absolute difference in Y
      if (Math.abs(diffX) > Math.abs(diffY)) {
        // If the absolute difference in X is greater, it's a horizontal swipe
        // if (diffX > 0) {
        //   // Right swipe
        //   console.log('Right swipe');
        //   action = 'Right';
        // } else {
        //   // Left swipe
        //   console.log('Left swipe');
        //   action = 'Left';
        // }
        if (diffX > 0) {
          // Right swipe
          console.log('Right swipe');
          action = 'Right';
          description = 'Interested';
        } else {
          // Left swipe
          console.log('Left swipe');
          action = 'Left';
          description = 'Rejected';
        }
      } else {
        // If the absolute difference in Y is greater, it's a vertical swipe
        if (diffY > 0) {
          // Down swipe
          console.log('Down swipe');
          action = 'Down';
          description = 'Down';
        } else {
          // Up swipe
          console.log('Up swipe');
          action = 'Up';
          description = 'Super Liked';
        }
      }
    }

    const endSwipe = () => {
      let threshold = 80;
      
      console.clear();
      console.log(`startX ${startX}; diffX ${diffX}`);

      console.log(`******** Swiped ${action}`);

      
      document.removeEventListener('mousemove', moveSwipe);
      document.removeEventListener('touchmove', moveSwipe);
      document.removeEventListener('mouseup', endSwipe);
      document.removeEventListener('touchend', endSwipe);

      const newData = {
        'swiper_user_id': this.user_id,
        'swipee_user_id': currentProfileId,
        'swipe_direction': action,
        'swipe_description': description
      };

      if (diffX != startX){

        if ((action == 'Left' || action == 'Right' ) && Math.abs(diffX) >= threshold){
          // const newData = {
          //   'swiper_user_id': this.user_id,
          //   'swipee_user_id': currentProfileId,
          //   'swipe_direction': action,
          //   'swipe_description': description
          // };
  
          // this.postUserSwipe(newData);

          this.postUserSwipeCheckDateMatch(currentProfileId as number, this_index, action, description);
          this.removeFromRemainingUserList(currentProfileId);
        }
      }

      if (diffY != startY){

        // if (this.canSuperLike != null && this.canSuperLike == false){
          
        //     (this.userList[this_index] as any).below=false;

        //     alert(`Quota Limit reached ${this_index}`);
        //     // this.cardsRectified();
            
        //     const element = $(`.dzSwipe_card:eq(${this_index})`);
        //     // const elementSL = element.find('.dzSwipe_card__option.dzSuperlike');
        //     element.removeClass('below').css('display', 'block');
        //     // elementSL.css('opacity', 0);
        //     // elementSL.css('display', '');
        //     element.removeClass("inactive");
        //     element.addClass("reset");
            
        //     setTimeout(function() {
        //         element.attr("style", "").removeClass("reset")
        //         .find(".dzSwipe_card__option").attr("style", "");
        //       }, 300);

        //     this.$nextTick(() => {
        //     });
        // }

        if (action == 'Down' && Math.abs(diffY) >= threshold) {
          this.removeFromRemainingUserList(currentProfileId);

        }

        if (action == 'Up' && Math.abs(diffY) >= threshold) {
          this.postUserSwipeCheckDateMatch(currentProfileId as number, this_index, action, description);
          this.removeFromRemainingUserList(currentProfileId);

          // this.putIncrementSuperLikeUsedQuota(currentProfileId as number, this_index);


          // const newData = {
          //   'swiper_user_id': this.user_id,
          //   'swipee_user_id': currentProfileId,
          //   'swipe_direction': action,
          //   'swipe_description': description
          // };
  
          // this.postUserSwipe(newData);
          // this.superLike(currentProfileId);
          // alert(this.canSuperLike);

    // if (this.canSuperLike == null || this.canSuperLike){
    //   this.putIncrementSuperLikeUsedQuota(currentProfileId as number, this_index);
    // } else {
    //   alert("Quota Limit Reached");
    //   this.$nextTick(() => {
    //     this.$nextTick(() => {
    //       // const element = $(`.dzSwipe_card:eq(${this_index})`);
    //       const element = $(`.dzSwipe_card.not(not(".inactive").eq(${this_index})`);
    //       // const elementSL = element.find('.dzSwipe_card__option.dzSuperlike');
    //       element.removeClass('below').css('display', 'block');
    //       // elementSL.css('opacity', 0);
    //       // elementSL.css('display', '');
    //       element.removeClass("inactive");
    //       element.addClass("reset");
    //       setTimeout(function() {
    //         element.attr("style", "").removeClass("reset")
    //         .find(".dzSwipe_card__option").attr("style", "");
    //       }, 300);
    //     });
    //   });
            
            // (this.userList[this_index] as any).below=false;

            // alert(`Quota Limit reached ${this_index}`);
            // // this.cardsRectified();
            
            // const element = $(`.dzSwipe_card:eq(${this_index})`);
            // // const elementSL = element.find('.dzSwipe_card__option.dzSuperlike');
            // element.removeClass('below').css('display', 'block');
            // // elementSL.css('opacity', 0);
            // // elementSL.css('display', '');
            // element.removeClass("inactive");
            // element.addClass("reset");
            
            // setTimeout(function() {
            //     element.attr("style", "").removeClass("reset")
            //     .find(".dzSwipe_card__option").attr("style", "");
            //   }, 300);

            // this.$nextTick(() => {
            // });
          // }
        }
      }

      // Implement any cleanup logic here
      startX = 0;
      startY = 0;
      diffX = 0;
      diffY = 0;
    };

    
    // card.addEventListener('mousedown', startSwipe);
    // card.addEventListener('touchstart', startSwipe);
    this.startSwipe = startSwipe;
    this.moveSwipe = moveSwipe;
    this.endSwipe = endSwipe;

    this.action = action;
    
  }

  removeFromRemainingUserList(userToRemove: any) {
    this.remainingUserList = this.remainingUserList.filter(user => (user as any).id !== userToRemove);
  }

  postUserSwipe(newData: object){
    storeUserSwipe(newData)
      .then(response => {
        if (response.data) {
          console.clear();
          console.log("Swipe Saved");
        }
      })
      .catch(error => {
        console.error("Error saving user swipe: ", error);
      })
  }

  postUserSwipeCheckDateMatch(swipee_id: number, index: number, action: string, description: string){
    const newData = {
      'swiper_user_id': this.user_id,
      'swipee_user_id': swipee_id,
      'swipe_direction': action,
      'swipe_description': description
    };
    storeUserSwipeCheckDateMatch(newData)
    .then(response => {
        if (response.data) {
          const data = response.data;
          
          // Swiped Up and Limit Reached
          if (data.can_super_like != undefined){
            if (data.can_super_like == false) {

              this.toastMessage = data.message;
              this.isToastMessageVisible = true;
  
              this.$nextTick(() => {
                const element = $(`.dzSwipe_card:eq(${index})`);
                // const elementSL = element.find('.dzSwipe_card__option.dzSuperlike');
                element.removeClass('below').css('display', 'block');
                // elementSL.css('opacity', 0);
                // elementSL.css('display', '');
                element.removeClass("inactive");
                element.addClass("reset");
                setTimeout(function() {
                  element.attr("style", "").removeClass("reset")
                  .find(".dzSwipe_card__option").attr("style", "");
                }, 300);
              });

              return;
            }

          } 

          // Date Matched
          if (data.dateMatch && data.dateMatch.status === 'Matched'){
            
            this.toastMessage = "We found You a Match!";
            this.isToastMessageVisible = true;

            return;
          }


          console.clear();
          console.log("Swipe Saved");
        }
      })
      .catch(error => {
        console.error("Error saving user swipe: ", error);
      })
  }


  putIncrementSuperLikeUsedQuota(swipee_id: number, index: number){

    const newData = {
      "user_id": this.user_id
    }; 

    updateIncrementSuperLikeUsedQuota(newData)
      .then(response => {
        console.clear();
        console.log(JSON.stringify(response.data));
        if(response.data){
          this.canSuperLike=response.data.can_super_like;
          if (response.data.can_super_like){
            this.superLike(swipee_id);
          } else {

            // alert("Quota Limit Reached");
            this.toastMessage = response.data.message;
            this.isToastMessageVisible = true;

            this.$nextTick(() => {
              const element = $(`.dzSwipe_card:eq(${index})`);
              // const elementSL = element.find('.dzSwipe_card__option.dzSuperlike');
              element.removeClass('below').css('display', 'block');
              // elementSL.css('opacity', 0);
              // elementSL.css('display', '');
              element.removeClass("inactive");
              element.addClass("reset");
              setTimeout(function() {
                element.attr("style", "").removeClass("reset")
                .find(".dzSwipe_card__option").attr("style", "");
              }, 300);
            });
          }
          
        }
      })
  }
  

  handleSuperLike(profile: object, index: number) {
    this.postUserSwipeCheckDateMatch((profile as any).id, index, 'Up', 'Super Liked');
    // this.superLike((profile as any).id);
    
    (this.userList[index] as any).superLiked = true;
    (profile as any).superLiked = true;

    // (profile as any).below = true;

    // setTimeout(() => {
    //   this.cardsRectified();
    //   setTimeout(() => {
        
    //     const nextIndex = index;
    //     alert(JSON.stringify(nextIndex));

    //     (this.userList[index - 1] as any).below = false;
    //     (this.userList[index - 1] as any).superLiked = false;
    //   }, 500); // Simulating the reset after 500ms
    // }, 300);

    // Using jQuery's slideUp function
    const elementB = $(`.dzSwipe_card:eq(${index})`);
    const elementSL = elementB.find('.dzSwipe_card__option.dzSuperlike');
    
    elementSL.css('opacity', '1');
    elementB.slideUp(300, () => {
      this.cardsRectified();
      setTimeout(() => {
        elementB.addClass('below').css('display', '');
        elementSL.css('opacity', '');
      }, 500);
      });
    

  }

  cardsRectified() {
    this.profileCounter++;
    if (this.profileCounter === this.profileNum) {
      this.profileCounter = 0;
      this.userList.forEach(profile => (profile as any).below = false);
    }
  }

  superLike(currentProfileId: number | null){
    const newData = {
        'swiper_user_id': this.user_id,
        'swipee_user_id': currentProfileId,
        'swipe_direction': 'Up',
        'swipe_description': 'Super Liked'
      };

    this.postUserSwipe(newData);
  }

  // startSwipe(event: any) {
  //   // alert('startswipe');
  //     // this.isSwiping = true;
  //     // this.startX = event.type.includes('touch') ? event.touches[0].clientX : event.clientX;
  //     // this.startY = event.type.includes('touch') ? event.touches[0].clientY : event.clientY;
  //   this.isSwiping = true;
  //   // Check if the event is a touch event
  //   if (event.type.includes('touch')) {
  //     // TypeScript knows event is a TouchEvent, cast it to access touch-specific properties
  //     const touchEvent = event as TouchEvent;
  //     this.startX = touchEvent.touches[0].clientX;
  //     this.startY = touchEvent.touches[0].clientY;
  //   } else {
  //     // TypeScript knows event is a MouseEvent, no need to cast
  //     this.startX = event.clientX;
  //     this.startY = event.clientY;
  //   }
  // }
  // moveSwipe(event: any) {
  //   // alert('moveswipe');
  //   if (!this.isSwiping) return;
  //   const x = event.type.includes('touch') ? event.touches[0].clientX : event.clientX;
  //   const y = event.type.includes('touch') ? event.touches[0].clientY : event.clientY;
  //   this.deltaX = x - this.startX;
  //   this.deltaY = y - this.startY;
  //   // Apply transformation based on deltaX and deltaY
  //   // Example: this.$refs.swipeCard.style.transform = `translate(${this.deltaX}px, ${this.deltaY}px)`;
  // }

  // endSwipe() {
  //   // alert('endswipe');
  //   this.isSwiping = false;
  //   // Logic to determine if swipe was sufficient for an action
  //   if (Math.abs(this.deltaX) > 80) { // Example threshold
  //     // Perform action based on swipe direction
  //     console.log('Swipe action performed');
  //   }
  //   // Reset state
  //   this.deltaX = 0;
  //   this.deltaY = 0;
  //   // Reset transformation
  //   // Example: this.$refs.swipeCard.style.transform = '';
  // }
  

  // TindeSwiper Code


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//  .image_container {
//   position: relative;
//   display: inline-block;
//  }

//  .image_container img {
//   display: block;
//   width: 100%;
//   height: auto;
// }

// .spinner-overlay {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: rgba(255, 255, 255, 0.7); /* Optional: To slightly dim the image */
// }
</style>
