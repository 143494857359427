<template>
  <div class="bg-white">
<div class="page-wrapper">
	<!-- Preloader -->
	<!-- <div id="preloader">
		<div class="loader">
			<div class="load-circle"><div></div><div></div></div>
		</div>
	</div> -->
    <!-- Preloader end-->
    <PreLoader></PreLoader>

    <!-- Page Content -->
    <div class="page-content">
		<div class="container">
			<div class="account-area">
        <BackButton></BackButton>
				<!-- <a href="javascript:void(0);" class="back-btn dz-flex-box">
					<i class="icon feather icon-chevron-left"></i>
				</a> -->
				<div class="section-head ps-0">
					<h3>Enter your code</h3>
				</div>
				<form action="submit">
					<div id="otp" class="digit-group input-mini">
						<input class="form-control" type="text" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1">
						<input class="form-control" type="text" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2">
						<input class="form-control" type="text" id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3">
						<input class="form-control" type="text" id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4">
					</div>                
				</form>
			</div>
        </div>
    </div>
    <!-- Page Content End -->
	<!-- Footer -->
    <!-- <div class="footer fixed bg-white">
		<div class="container">
			<a href="first-name.html" class="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl">Next</a>
		</div>
	</div> -->
	<!-- Footer -->
  <ButtonFooter :onClick="handleNextClick" next="/firstname" label="Next"></ButtonFooter>

</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';
import ButtonFooter from './ButtonFooter.vue';
import RouteLocationRaw from "vue-router";
import BackButton from './BackButton.vue';


@Component({
components: {
  ButtonFooter,
  PreLoader,
  BackButton
},
})
export default class Otp extends Vue {
  private handleNextClick() {
      this.$store.commit('setIsOTPVerified');
      // alert(JSON.stringify(this.$store.state));

      this.$router.push('/feed');

      // const routeParams: RouteLocationRaw = {
      //   path: '/feed',
      //   query: { id: '123', time: Date.now().toString() },
      // };

      // this.$router.push({ path: '/feed', query: { id: '123', time: Date.now().toString } });

  }
 

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
