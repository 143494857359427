<template>
  <div class="header-large">
<div class="page-wrapper">
    
	<!-- Preloader -->
	<PreLoader></PreLoader>
  <div v-if="is_loading" class="spinner-overlay">
    
    <div class="preloader">
      <!-- Preloader -->
        <div id="preloader" ref="preloader">
          <div class="loader">
            <div class="load-circle"><div></div><div></div></div>
          </div>
        </div>
      <!-- Preloader end-->
    </div>

  </div>
    <!-- Preloader end-->
	
	<!-- Header -->
		<header class="header header-fixed">
			<div class="container">
				<div class="header-content">
					<div class="left-content">
            <BackButton></BackButton>
						<!-- <a href="javascript:void(0);" class="back-btn">
							<i class="icon feather icon-chevron-left"></i>
						</a> -->
						<h6 class="title">Profile</h6>
					</div>
					<div class="mid-content header-logo">
					</div>
					<div class="right-content dz-meta">
						<a href="/filters" class="filter-icon">
							<i class="flaticon flaticon-settings-sliders"></i>
						</a>
					</div>
				</div>
			</div>
		</header>
	<!-- Header -->
	
	<!-- Page Content Start -->
	<div class="page-content space-top p-b60">
		<div class="container pt-0"> 
			<div class="profile-area">
				<div class="main-profile">
					<div class="about-profile">
						<a href="/settings" class="setting dz-icon">
							<i class="flaticon flaticon-setting"></i>
						</a>
            <a :href="'/profile-pic'">
						  <div class="media rounded-circle">
							<!-- <img src="assets/images/user/pic1.jpg" alt="profile-image"> -->
                <img :src="userData.userProfile && userData.userProfile.profile_pic ?  apiHost + '/api/get-image?location=' + userData.userProfile.profile_pic : '/assets/images/user/def-pics/pic1.jpg'" alt="profile-image" @load="imageLoaded">

                <svg class="radial-progress m-b20" data-percentage="100" viewBox="0 0 80 80">
                  <!-- <circle class="incomplete" cx="40" cy="40" r="35"></circle> -->
                  <circle class="complete" cx="40" cy="40" r="35" style="stroke-dashoffset: 0"></circle>
                </svg>
                <!-- <div class="data-fill"><span>40%</span></div> -->
              </div>
            </a>
						<a href="/edit-profile" class="edit-profile dz-icon">
							<i class="flaticon flaticon-pencil-2"></i>
						</a>
					</div>
					<div class="profile-detail">
						<!-- <h5 class="name">Abel, 20</h5> -->
            <h5 class="name"> <a :href="`/profile-detail/${userData.id}`"> {{ userData.userProfile.name ? userData.userProfile.name : 'User'  }} {{ userData.userProfile.birthdate ? ', ' + calculateAge(userData.userProfile.birthdate) : '' }} </a></h5>
						<p class="mb-0"><i class="icon feather icon-map-pin me-1"></i> {{ userData.userProfile.location ? userData.userProfile.location : 'Ethiopia'  }} </p>
					</div>
				</div>
				<div class="row g-2 mb-5">
					<div class="col-4">
						<div class="card style-2">
							<div class="card-body">
								<a href="javascript:void(0);" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">	
									<div class="card-icon">
										<i class="flaticon flaticon-star-1"></i>
									</div>
									<div class="card-content">
										<p>0 Super Likes</p>
									</div>
									<i class="icon feather icon-plus"></i>
								</a>
							</div>
						</div>
					</div>
					<div class="col-4">
						<div class="card style-2">
							<div class="card-body">
								<div class="card-icon">
									<i class="flaticon flaticon-shuttle"></i>
								</div>
								<div class="card-content">
									<p>My Boosts</p>
								</div>
								<i class="icon feather icon-plus"></i>
							</div>
						</div>
					</div>
					<div class="col-4">
						<div class="card style-2">
							<div class="card-body">
								<a href="/subscription">
									<div class="card-icon">
										<i class="flaticon flaticon-bell"></i>
									</div>
									<div class="card-content">
										<p>Subscriptions</p>
									</div>
									<i class="icon feather icon-plus"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="swiper subscription-swiper">
					<div class="swiper-wrapper mb-3">
						<div class="swiper-slide">
							<div class="dz-content">
								<h5 class="title">Get Shay Buna Plus</h5>
								<p>Get Unlimited Likes and More!</p>
								<a href="/subscription" class="btn rounded-xl">Get Shay Buna Plus</a>
							</div>
						</div>
						<div class="swiper-slide">
							<div class="dz-content">
								<h5 class="title">Get Shay Buna Gold</h5>
								<p>Get Unlimited Likes, Boosts and More!</p>
								<a href="/subscription" class="btn rounded-xl">Get Shay Buna Gold</a>
							</div>
						</div>
						<div class="swiper-slide">
							<div class="dz-content">
								<h5 class="title">Get Shay Buna Platinum</h5>
								<p>Get Unlimited Likes, Boosts, Subscriptions and More!</p>
								<a href="/subscription" class="btn rounded-xl">Get Shay Buna Platinum</a>
							</div>
						</div>
					</div>
					<div class="swiper-btn">
						<div class="swiper-pagination style-1 flex-1"></div>
					</div>
				</div>
			</div>
		</div> 
	</div>
	<!-- Page Content End -->

	<!-- Menubar -->
	<MenuBarFooter></MenuBarFooter>
	<!-- Menubar -->
</div>  
  </div>
</template>


<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import MenuBarFooter from '@/components/MenuBarFooter.vue';
import PreLoader from '@/components/PreLoader.vue';
import BackButton from '@/components/BackButton.vue';
import { mapState } from 'vuex';
import BirthDate from './BirthDate.vue';

@Component({
components: {
  MenuBarFooter,
  PreLoader,
  BackButton
},
})
export default class Profile extends Vue {
  private userData: object = this.$store.state.userData;
  private is_loading: boolean = true;

  // computed() {
  //   ..mapState({
  //     userData: state
  //   })
  // }

  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      is_loading: true
    }
  }
  // mounted() {
  //   alert(JSON.stringify(this.$store.state));
  // }

  calculateAge(birthdate: string) {
    const today = new Date();
      const birthDate = new Date(birthdate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }


      return age; 
  }

  imageLoaded() {
    this.is_loading = false;
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
