import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import store from '@/store';
import HomeView from '../views/HomeView.vue'
import OnboardingView from '@/components/Onboarding.vue'
import LoginView from '@/views/LoginView.vue'
import OtpView from '@/views/OtpView.vue'
import FirstNameView from '@/views/FirstNameView.vue'
import BirthDateView from '@/views/BirthDateView.vue'
import GenderView from '@/views/GenderView.vue'
import DatingInterestView from '@/views/DatingInterestView.vue'
import RelationshipGoalView from '@/views/RelationshipGoalView.vue'
import AddPicsView from '@/views/AddPicsView.vue'
import FeedView from '@/views/FeedView.vue'
import ExploreView from '@/views/ExploreView.vue'
import WishListView from '@/views/WishListView.vue'
import ChatListView from '@/views/ChatListView.vue'
import ProfileView from '@/views/ProfileView.vue'
import FilterView from '@/views/FilterView.vue'
import EditProfileView from '@/views/EditProfileView.vue'
import SubscriptionView from '@/views/SubscriptionView.vue'
import ProfileDetailView from '@/views/ProfileDetailView.vue'
import ChatView from '@/views/ChatView.vue'
import LobbyView from '@/views/LobbyView.vue'
import ErrorView from '@/views/ErrorView.vue'
import EmailView from '@/views/EmailView.vue'
import LocationView from '@/views/LocationView.vue'
import ProfilePicView from '@/views/ProfilePicView.vue'
import SettingsView from '@/views/SettingsView.vue'
import PhoneNumberView from '@/views/PhoneNumberView.vue';
import UserNameView from '@/views/UserNameView.vue';
import PasswordView from '@/views/PasswordView.vue';





Vue.use(VueRouter)

const authenticationGuard = (to: Route, from: Route, next: Function) => {
  // Example condition: Redirect if user is not authenticated
  const isAuthenticated = store.state.userData && store.state.userData.id && Object.keys(store.state.userData).length > 0 ;
  if (!isAuthenticated) {
    // Redirect to the Home page or any other page
    next('/login');
  } else {
    // Proceed to the RestrictedPage
    next();
  }
}

const loginCheck = (to: Route, from: Route, next: Function) => {
  // Example condition: Redirect if user is not authenticated
  const isAuthenticated = store.state.userData && store.state.userData.id && Object.keys(store.state.userData).length > 0;
  if (!isAuthenticated) {
    // Redirect to the Home page or any other page
    next();
  } else {
    
    // Proceed to the RestrictedPage
    next('/feed');
  }
}



const accessOTPView = (to: Route, from: Route, next: Function) => {
  // Example condition: Redirect if user is not authenticated
  const isAuthenticated = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.phone_no;
  console.log(isAuthenticated);
  
  const isOTPVerified = store.state.isOTPVerified;
  console.log(isOTPVerified);
  if (isAuthenticated && isOTPVerified == false) {
    // Redirect to the Home page or any other page
    next();
  } else {
    // Proceed to the RestrictedPage
    // alert(isOTPVerified);
    next('/login');
  }
}

const accessPasswordView = (to: Route, from: Route, next: Function) => {
  const isAuthenticated = store.state.userData && Object.keys(store.state.userData).length > 0  && store.state.userData.id;
  const hasUserName = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.username;

  if (!isAuthenticated && hasUserName) {
    next();
  } else {
    next('/login');
  }
}

const accessPhoneNumberView = (to: Route, from: Route, next: Function) => {
  const isAuthenticated = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.id;
  const hasPassword = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.password_hash;

  if (!isAuthenticated && hasPassword) {
    next();
  } else {
    next('/login');
  }
}

const accessFirstnameView = (to: Route, from: Route, next: Function) => {
  // Example condition: Redirect if user is not authenticated
  const isAuthenticated = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.id;
  const hasPhoneNo = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.phone_no;
  if (!isAuthenticated && hasPhoneNo) {
    // Redirect to the Home page or any other page
    next();
  } else {
    // Proceed to the RestrictedPage
    next('/login');
  }
}

const accessEmailView = (to: Route, from: Route, next: Function) => {
  // Example condition: Redirect if user is not authenticated
  const isAuthenticated = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.id;
  
  const hasFirstName = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.name;
  if (!isAuthenticated && hasFirstName) {
    // Redirect to the Home page or any other page
    next();
  } else {
    // Proceed to the RestrictedPage
    next('/login');
  }
}

const accessLocationView = (to: Route, from: Route, next: Function) => {
  const isAuthenticated = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.id;
  const hasEmail = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.email;
  if (!isAuthenticated && hasEmail) {
    next();
  } else {
    next('/login');
  }

}

const accessBirthDateView = (to: Route, from: Route, next: Function) => {
  // Example condition: Redirect if user is not authenticated
  const isAuthenticated = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.id;
  console.log(store.state.userData.id);
  
  const hasLocation = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.location && store.state.userData.location_city;
  if (!isAuthenticated && hasLocation) {
    // Redirect to the Home page or any other page
    next();
  } else {
    // Proceed to the RestrictedPage
    next('/login');
  }
}

const accessGenderView = (to: Route, from: Route, next: Function) => {
  // Example condition: Redirect if user is not authenticated
  const isAuthenticated = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.id;
  
  const hasBirthDate = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.birthdate;
  if (!isAuthenticated && hasBirthDate) {
    // Redirect to the Home page or any other page
    next();
  } else {
    // Proceed to the RestrictedPage
    next('/login');
  }
}

const accessDatingInterest = (to: Route, from: Route, next: Function) => {
  // Example condition: Redirect if user is not authenticated
  const isAuthenticated = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.id;
  
  const hasGender = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.gender;
  if (!isAuthenticated && hasGender) {
    // Redirect to the Home page or any other page
    next();
  } else {
    // Proceed to the RestrictedPage
    next('/login');
  }
}

const accessRelationshipGoals = (to: Route, from: Route, next: Function) => {
  // Example condition: Redirect if user is not authenticated
  const isAuthenticated = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.id;
  
  const hasDatingInterest = store.state.userData && Object.keys(store.state.userData).length > 0 && store.state.userData.dating_interest;
  if (!isAuthenticated && hasDatingInterest) {
    // Redirect to the Home page or any other page
    next();
  } else {
    // Proceed to the RestrictedPage
    next('/login');
  }
}


const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: OnboardingView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: loginCheck,
  },
  {
    path: '/username',
    name: 'username',
    component: UserNameView,
    beforeEnter: loginCheck,
  },
  {
    path: '/password',
    name: 'password',
    component: PasswordView,
    beforeEnter: accessPasswordView,
  },
  {
    path: '/phonenumber',
    name: 'phonenumber',
    component: PhoneNumberView,
    beforeEnter: accessPhoneNumberView,
  },
  {
    path: '/otp',
    name: 'otp',
    component: OtpView,
    beforeEnter: accessOTPView,
  },
  {
    path: '/firstname',
    name: 'firstname',
    component: FirstNameView,
    beforeEnter: accessFirstnameView
  },
  {
    path: '/email',
    name: 'email',
    component: EmailView,
    beforeEnter: accessEmailView,
  },
  {
    path: '/location',
    name: 'location',
    component: LocationView,
    beforeEnter: accessLocationView,
  },
  {
    path: '/birthdate',
    name: 'birthdate',
    component: BirthDateView,
    beforeEnter: accessBirthDateView,
  },
  {
    path: '/gender',
    name: 'gender',
    component: GenderView,
    beforeEnter: accessGenderView,
  },
  {
    path: '/dating-interest',
    name: 'dating-interest',
    component: DatingInterestView,
    beforeEnter: accessDatingInterest,
  },
  {
    path: '/relationship-goal',
    name: 'relationship-goal',
    component: RelationshipGoalView,
    beforeEnter: accessRelationshipGoals,
  },
  {
    path: '/add-pics',
    name: 'add-pics',
    component: AddPicsView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/profile-pic',
    name: 'profile-pic',
    component: ProfilePicView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/feed',
    name: 'feed',
    component: FeedView,
    props: (route) => ({ 
      id: route.query.id!!,
      time: route.query.time!!,
    }),
    beforeEnter: authenticationGuard,
  },
  {
    path: '/explore',
    name: 'explore',
    component: ExploreView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/wishlist',
    name: 'wishlist',
    component: WishListView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/chatlist',
    name: 'chatlist',
    component: ChatListView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/filters',
    name: 'filters',
    component: FilterView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/edit-profile',
    name: 'edit-profile',
    component: EditProfileView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: SubscriptionView,
    beforeEnter: authenticationGuard,
  },
  // {
  //   path: '/profile-detail',
  //   name: 'profile-detail',
  //   component: ProfileDetailView,
  //   beforeEnter: authenticationGuard,
  // },
  {
    path: '/profile-detail/:id',
    name: 'profile-detail',
    component: ProfileDetailView,
    props: true,
  },
  {
    path: '/chat/:slug',
    name: 'chat',
    component: ChatView,
    props: true,
    // beforeEnter: authenticationGuard,
  },
  {
    path: '/lobby/:slug',
    name: 'lobby',
    component: LobbyView,
    props: true,
    // beforeEnter: authenticationGuard,
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorView,
    beforeEnter: authenticationGuard,
  },
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
