<template>
  <div class="username">
    <UserName></UserName>
  </div>
</template>


<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserName from '@/components/UserName.vue';

@Component({
components: {
  UserName,
},
})
export default class UserNameView extends Vue {
  
}
</script>
